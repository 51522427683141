/**
 *
 * AddLocation container class
 *
 */
// Packages
import React from "react";
import { message } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Components
import AddLocationCard from "../../components/AddLocationCard";

// HOCS
import withUserHOC from "../../hocs/withUserHOC";

// Actions
import { getCompanies } from "../../ducks/firm/action";
import { addLocation, getSpecificLocation, updateLocation } from "../../ducks/location/action";

/* eslint-disable react/prefer-stateless-function */
class AddLocation extends React.Component {

  state = {
    companyId: '',
  };

  async componentDidMount() {
    try {
      const { client, getCompanies, match: {params}, getSpecificLocation } = this.props;
      await getCompanies(client);
      if(params.id) {
        await getSpecificLocation(client, params.id);
        this.setState({ companyId: this.props.location.company_id });
      }
    } catch (err) {
      message.error(err.message);
    }
  };

  reduxHandler = async (values) => {
    try {
      const { client, addLocation, history } = this.props;
      await addLocation(client, values);
      message.success("Location added successfully!");
      history.push('/locations');
    } catch (err) {
      message.error(err.message);
    }
  };

  
  updateReduxHandler = async (values) => {
    try {
      const { client, match: { params }, updateLocation, history } = this.props;
      await updateLocation(client, params.id, values, this.state.companyId);
      message.success("Location updated successfully!");
      history.push('/locations');
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    return (
      <AddLocationCard
        {...this.props}
        reduxHandler={this.reduxHandler.bind(this)}
        updateReduxHandler={this.updateReduxHandler.bind(this)}
      />
    );
  }
}

AddLocation.propTypes = {};

const mapStateToProps = ({ firm, location }) => ({
  companies: firm.companies,
  location: location.location,
});

const mapDispatchToProps = dispatch => ({
  getCompanies: (client) => dispatch(getCompanies(client)),
  addLocation: (client, values) => dispatch(addLocation(client, values)),
  getSpecificLocation: (client, locationId) => dispatch(getSpecificLocation(client, locationId)),
  updateLocation: (client, locationId, values, companyId) => 
    dispatch(updateLocation(client, locationId, values, companyId)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withUserHOC(withConnect(AddLocation));
