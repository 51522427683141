/**
 *
 * UserView component class
 *
 */
// Packages
import React from "react";
import { Row, Col, Button, Select, Input, Icon, Tooltip, Popconfirm, Divider, message } from "antd";
import { Table } from 'reactstrap';
import _ from "lodash";
import moment from 'moment-timezone';

// Componemts
import EmptyData from "../EmptyData";

// styles
import "./user-view.css";

// Utils -> Roles
import { ROLES } from "../../utils/roles";

// Utils -> Regex
import { ALPHA_NUMERIC_REGIX } from "../../utils/regex";

const { Option } = Select;
const { Column } = Table;

/* eslint-disable react/prefer-stateless-function */
class UserView extends React.Component {

  state = {
    roles: [],
    firmId: '',
    role: '',
    keyword: '',
    columnHeadings: ['Email', 'Companies', 'Roles', 'Last Login', 'Actions'],
    columnKeys: ['email', 'companies', 'roles', 'last_login'],
  };

  handleSelect = (rowId, companyId) => {
    this.setState({ [`${rowId}-1`]: companyId });
    this.props.changeUserCompanyHandler(rowId, companyId);

  };

  handleRoleSelect = (rowId, role) => {
    this.setState({ [`${rowId}-2`]: role });
    this.props.changeUserRoleHandler(rowId, role);
  };

  handleKeyUp = (e, key) => {

    const { firmId, role } = this.state;

    if (e.keyCode === 13) {
      if (e.target.value.match(ALPHA_NUMERIC_REGIX.regex)) {
        this.setState({ keyword: e.target.value });
        this.props.searchHandlerUser(firmId, role, e.target.value);
      } else {
        message.error(ALPHA_NUMERIC_REGIX.regexMessage);
      }
    }
    if (e.target.value.length === 0) {
      if (e.target.value.match(ALPHA_NUMERIC_REGIX.regex)) {
        this.props.searchHandlerUser(firmId, role, e.target.value);
      } else {
        message.error(ALPHA_NUMERIC_REGIX.regexMessage);
      }
    }
  };

handleCompanySelect = (value, key) => {
  const { role, keyword } = this.state;
  this.setState({ firmId: value });
  this.props.searchHandlerUser(value, role, keyword);
};

handleGatewaySelect = (value, key) => {
  const { firmId, keyword } = this.state;
  this.setState({ role: value });
  this.props.searchHandlerUser(firmId, value, keyword);
};

render() {
  const { companies, users, currentUser } = this.props;
  const { columnHeadings, columnKeys } = this.state;

  return (
    <Row id="UserView">
      <Row className="users-container">
        <Col lg={20} md={16} xs={12}>
          <h2>Users </h2>
        </Col>
        <Col lg={4} md={8} xs={12}>
          {currentUser && (currentUser.role === ROLES.SUPER_ADMIN ||
            currentUser.role === ROLES.ADMIN) && (
              <Tooltip placement="bottom" title={'Add User to Company'}>
                <Button
                  className="add-company-button"
                  onClick={() => this.props.history.push('/users/add-user')}
                >
                  <Icon type="plus-circle" />
                </Button>
              </Tooltip>
            )}
        </Col>
      </Row>
      <Divider />
      <Row className="company-container" gutter={25}>
        <Col lg={7} md={7} xs={24}>
          <Select
            defaultValue="All"
            className="drop-down-company"
            onSelect={(value) => this.handleCompanySelect(value, 'firm')}
          >
            <Option key="all" value="">All</Option>
            {companies && companies.map((company, key) => (
              <Option key={key} value={company.id}>{company.name}</Option>
            ))}
          </Select>
        </Col>
        <Col lg={7} md={7} xs={24}>
          <Select
            defaultValue="All"
            className="drop-down-company"
            onSelect={(value) => this.handleGatewaySelect(value, 'gateway')}
          >
            <Option value="">All</Option>
            <Option value="Admin">Admins</Option>
            <Option value="User">Users</Option>
            <Option value="Driver">Drivers</Option>
            <Option value="Api">Api</Option>
          </Select>
        </Col>
        <Col lg={10} md={10} xs={24} className="filter-holder">
          <Input
            className="device-input"
            addonBefore={<Icon type="filter" />}
            placeholder="Filter"
            onKeyUp={(e) => this.handleKeyUp(e, 'email')}
          />
        </Col>
      </Row>
      <Row className="users-table">
        {users && users.length === 0 ? (
          <EmptyData />
        ) : (
            <Table responsive>
              <thead>
                <tr>
                  {_.map(columnHeadings, (heading, i) => (
                    <th key={i}>{heading}</th>
                  ))
                  }
                </tr>
              </thead>
              {users &&
                <tbody>
                  {users.length > 0 && users.map(record => (
                    <tr key={record.id}>
                      {
                        columnKeys.map(key => {
                          const role = record && record.role && record.role;
                          const comp = companies && companies.find(c => c.id && c.id === record.company_id ? c : null);
                          return (
                            <td>{
                              key === "companies" && localStorage.getItem('userRole') === ROLES.SUPER_ADMIN ? (
                                <Select
                                  style={{ width: 200 }}
                                  value={this.state && this.state[`${record.id}-1`] ? this.state[`${record.id}-1`] : comp ? comp.id : "N/A"}
                                  onSelect={(value) => this.handleSelect(record.id, value)}
                                >
                                  {companies && companies.map((company, key) => (
                                    <Option key={key} value={company.id}>{company.name}</Option>
                                  ))}
                                </Select>
                              )
                                :
                                key == "companies" && localStorage.getItem('userRole') === ROLES.ADMIN ? (
                                  record.company && record.company.name
                                )
                                  :
                                  key === "roles" ? (
                                    <Select
                                      disabled={currentUser.id === record.id ? true : false}
                                      style={{ width: 200 }}
                                      value={this.state && this.state[`${record.id}-2`] ? this.state[`${record.id}-2`] : role ? role : null}
                                      onSelect={(value) => this.handleRoleSelect(record.id, value)}
                                    >
                                      <Option value="Admin">Admin</Option>
                                      <Option value="User">User</Option>
                                      <Option value="Driver">Driver</Option>
                                    </Select>
                                  ) :

                                  key === "last_login" ?
                                    record.last_login ? moment(record.last_login).tz("Europe/Berlin").format("DD-MM-YYYY HH:mm:ss") : "N/A"
                                  :
                                    record[key]
                            }</td>
                          )
                        })
                      }
                      <td>
                        <Tooltip placement="bottom" title={"Edit User"}>
                          <Icon
                            type="edit"
                            onClick={() => this.props.editUserHandler(record.id)}
                          />
                        </Tooltip>
                        <Divider type="vertical" />
                        <Tooltip placement="bottom" title={"Delete User"}>
                          <Popconfirm
                            title="Are you sure delete this user?"
                            onConfirm={() => this.props.deleteUserHandler(record.id)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Icon
                              type="delete"
                            />
                          </Popconfirm>
                        </Tooltip>
                      </td>
                    </tr>
                  ))}
                </tbody>
              }
            </Table>
          )}
      </Row>
    </Row>
  );
}
}

UserView.propTypes = {};

export default UserView;
