/**
 *
 * EmptyData component class
 *
 */

import React from "react";
import { Row, Card } from "antd";
import PropTypes from "prop-types";

// styles
import "./empty-data.css";

/* eslint-disable react/prefer-stateless-function */
class EmptyData extends React.Component {
  render() {
    return (
      <Row id="EmptyData">
        <Card>
          <p className="no-records-text">No Records Found!</p>
        </Card>
      </Row>
    );
  }
}

EmptyData.propTypes = {};

export default EmptyData;
