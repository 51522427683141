/*
 *
 * location actionTypes
 *
 */

export const ADD_LOCATION_ACTION = "ADD_LOCATION_ACTION";
export const GET_LOCATIONS_ACTION = "GET_LOCATIONS_ACTION";
export const DELETE_LOCATION_ACTION = "DELETE_LOCATION_ACTION";
export const GET_SPECIFIC_LOCATION_ACTION = "GET_SPECIFIC_LOCATION_ACTION";
export const UPDATE_LOCATION_ACTION = "UPDATE_LOCATION_ACTION";
export const GET_LOCATIONS_AGAINT_SPECIFIC_COMPANY_ACTION = "GET_LOCATIONS_AGAINT_SPECIFIC_COMPANY_ACTION" 

