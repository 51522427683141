import gql from "graphql-tag";

const firm = `
  id
  name
  address
  city
  state
  postal_code
  country
  created_by
  createdAt
  updatedAt
`;

export const ADD_COMPANY = gql`
  mutation addCompany(
    $userId: Int!
    $name: String!
    $streetAddress: String!
    $password: String
    $state: String!
    $city: String!
    $country: String!
    $zip: String!
  ) {
    addCompany(
      userId: $userId
      name: $name
      streetAddress: $streetAddress
      password: $password
      state: $state
      city: $city
      country: $country
      zip: $zip
  ) {
    success
    message
  } 
 }
`;

export const GET_COMPANIES = gql`
  query getCompanies($userId: Int) {
    getCompanies(userId: $userId) {
      ${firm}
    }
  } 
`;

export const GET_FILTERED_COMPANY_RESULTS = gql`
  query getFilteredCompanyResults (
    $value: String
    $key: String
    $userId: Int
  ) {
    getFilteredCompanyResults (
      value: $value
      key: $key
      userId: $userId
    ) {
      ${firm}
    }
  } 
`;

export const REMOVE_COMPANY = gql`
  mutation removeCompany ($companyId: Int!) {
    removeCompany (companyId: $companyId) {
      ${firm}
    }
  } 
`;

export const GET_SPECIFIC_COMPANY = gql`
  query getSpecificCompany ($companyId: Int!) {
    getSpecificCompany (companyId: $companyId) {
      success
      message
      firm {
        ${firm}
      }
    }
  } 
`;

export const UPDATE_COMPANY = gql`
  mutation updateCompany(
    $companyId: Int!
    $name: String!
    $streetAddress: String!
    $state: String!
    $city: String!
    $country: String!
    $zip: String!
    $password: String
  ) {
    updateCompany(
      companyId: $companyId
      name: $name
      streetAddress: $streetAddress
      state: $state
      city: $city
      country: $country
      zip: $zip
      password: $password
  ) {
    success
    message
  } 
 }
`;

export const ADD_USER_TO_COMPANY = gql`
  mutation addUserToCompany(
    $email: String!
    $role: String!
    $companyId: Int!
  ) {
    addUserToCompany(
      email: $email
      role: $role
      companyId: $companyId
  ) {
    success
    message
  } 
 }
`;

export const CHANGE_USER_COMPANY = gql`
  mutation changeUserCompany(
    $userId: Int!
    $companyId: Int!
  ) {
    changeUserCompany(
      userId: $userId
      companyId: $companyId
    ) {
      ${firm}
    } 
 }
`;

export const CHANGE_GATEWAY_COMPANY = gql`
  mutation changeGatewayCompany(
    $gatewayId: Int!
    $companyId: Int!
  ) {
    changeGatewayCompany(
      gatewayId: $gatewayId
      companyId: $companyId
    ) {
      ${firm}
    } 
 }
`;

