/*
 *
 * deliveryTask actions
 *
 */
// Action Types
import { 
  CREATE_DELIVERY_TASK_ACTION,
  GET_DELIVERY_BOXES_ACTION,
  GET_DELIVERY_TASKS_ACTION,
  DELETE_DELIVERY_BOX_ACTION,
  DELETE_DELIVERY_TASK_ACTION,
  EDIT_DELIVERY_TASK_ACTION,
  UPDATE_DELIVERY_TASK_ACTION
} from "./actionTypes";

// Queries
import {
  CREATE_DELIVERY_TASK,
  GET_DELIVERY_BOXES,
  GET_DELIVERY_TASKS,
  DELETE_DELIVERY_BOX,
  DELETE_DELIVERY_TASK,
  EDIT_DELIVERY_TASK,
  UPDATE_DELIVERY_TASK,
} from "../../queries/deliveryTask";

export const deliveryTask = (client, values) => ({
  type: CREATE_DELIVERY_TASK_ACTION,
  async payload() {
    try {
      const result = await client.mutate({
        mutation: CREATE_DELIVERY_TASK,
        variables: {
          orderId: values.order_id,
          pickupName: values.pickup_name,
          pickupAddress: values.pickup_address,
          pickup: values.pickup_time,
          receiverName: values.receiver_name,
          receiverAddress: values.receiver_address,
          receiveTime: values.receiver_time,
          boxIds: values.box_id,
          boxDescription: values.box_description,
          monitorBoxStatus: values.monitor_box,
          sensors: values.sensor,
          limitMin: values.limit_min,
          limitMax: values.limit_max,
          deliveredBy: values.delivered_by
        },
        fetchPolicy: "no-cache",
      });

      return result.data.deliveryTask;


    } catch (err) {
      return Promise.reject(err);
    }
  }
});


export const getDeliveryBoxes = (client) => ({
  type: GET_DELIVERY_BOXES_ACTION,
  async payload() {
    try {
      const result = await client.query({
        query: GET_DELIVERY_BOXES,
        fetchPolicy: "no-cache",
      });

      return result.data.getDeliveryBoxes;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const getDeliveryTasks = (client) => ({
  type: GET_DELIVERY_TASKS_ACTION,
  async payload() {
    try {
      const result = await client.query({
        query: GET_DELIVERY_TASKS,
        fetchPolicy: "no-cache",
      });

      return result.data.getDeliveryTasks;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});


export const deleteDeliveryBox = (client, id) => ({
  type: DELETE_DELIVERY_BOX_ACTION,
  async payload() {
    try {
      const result = await client.mutate({
        mutation: DELETE_DELIVERY_BOX,
        variables: {
          id: id,
        },
        fetchPolicy: "no-cache",
      });

      return result.data.deleteDeliveryBox;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});


export const deleteDeliveryTask = (client, id) => ({
  type: DELETE_DELIVERY_TASK_ACTION,
  async payload() {
    try {
      const result = await client.mutate({
        mutation: DELETE_DELIVERY_TASK,
        variables: {
          id: id,
        },
        fetchPolicy: "no-cache",
      });

      return result.data.deleteDeliveryTask;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const editDeliveryTask = (client, id) => ({
  type: EDIT_DELIVERY_TASK_ACTION,
  async payload() {
    try {
      const result = await client.query({
        query: EDIT_DELIVERY_TASK,
        variables: {
          id: id,
        },
        fetchPolicy: "no-cache",
      });

      return result.data.editDeliveryTask;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});


export const updateDeliveryTask = (client, values, id, boxPrimaryIds) => ({
  type: UPDATE_DELIVERY_TASK_ACTION,
  async payload() {
    try {
      const result = await client.mutate({
        mutation: UPDATE_DELIVERY_TASK,
        variables: {
          boxPrimaryIds: boxPrimaryIds,
          id: id,
          orderId: values.order_id,
          pickupName: values.pickup_name,
          pickupAddress: values.pickup_address,
          pickup: values.pickup_time,
          receiverName: values.receiver_name,
          receiverAddress: values.receiver_address,
          receiveTime: values.receiver_time,
          boxIds: values.box_id,
          boxDescription: values.box_description,
          monitorBoxStatus: values.monitor_box,
          sensors: values.sensor,
          limitMin: values.limit_min,
          limitMax: values.limit_max,
          deliveredBy: values.delivered_by
        },
        fetchPolicy: "no-cache",
      });

      return result.data.updateDeliveryTask;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

