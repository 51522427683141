/*
 *
 * sensor actionTypes
 *
 */

export const GET_SENSORS_COUNT_ACTION = "GET_SENSORS_COUNT_ACTION";
export const GET_SENSORS_BY_PAGE_ACTION = "GET_SENSORS_BY_PAGE_ACTION";
export const GET_SENSORS_ACTION = "GET_SENSORS_ACTION";
export const GET_SENSOR_ACTION = "GET_SENSOR_ACTION";
export const CHANGE_SENSOR_COMPANY_ACTION = "CHANGE_SENSOR_COMPANY_ACTION";
export const GET_SENSOR_LOGS_ACTION = "GET_SENSOR_LOGS_ACTION";
export const GET_FILTERED_SENSORS_ACTION = "GET_FILTERED_SENSORS_ACTION";
export const UPDATE_SENSOR_INFO_ACTION = "UPDATE_SENSOR_INFO_ACTION";
export const GET_ACTIVE_SENSORS_ACTION = "GET_ACTIVE_SENSORS_ACTION";
export const COLUMN_BASED_SENSOR_SORTING_ACTION = "COLUMN_BASED_SENSOR_SORTING_ACTION";


