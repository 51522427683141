/*
 *
 * gateway actionTypes
 *
 */

export const GET_GAYEWAYS_ACTION = "GET_GAYEWAYS_ACTION";
export const DELETE_GATEWAY_ACTION = "DELETE_GATEWAY_ACTION";
export const GET_FILTERED_GATEWAYS_ACTION = "GET_FILTERED_GATEWAYS_ACTION";
export const RESET_GATWAY_PASSWORD_ACTION = "RESET_GATWAY_PASSWORD_ACTION";
export const GET_SPECIFIC_GATEWAY_ACTION = "GET_SPECIFIC_GATEWAY_ACTION";
export const UPDATE_GATEWAY_ACTION = "UPDATE_GATEWAY_ACTION";
export const CHANGE_GATEWAY_ROLE_ACTION = "CHANGE_GATEWAY_ROLE_ACTION";
export const GET_GATEWAYS_FILTERED_ACTION = "GET_GATEWAYS_FILTERED_ACTION";
export const MUTE_GATEWAY_ACTION = "MUTE_GATEWAY_ACTION";
export const COLUMN_BASED_GATEWAY_SORTING_ACTION = "COLUMN_BASED_GATEWAY_SORTING_ACTION";