/*
 *
 * device actions
 *
 */
// Actions
import {
  GET_DEVICES_ACTION,
  UPDATE_DEVICE_INFO_ACTION,
  GET_DEVICES_INFO_ACTION,
  GET_DEVICE_FILTERED_RESULTS_ACTION,
  GET_SELECT_DEVICES_FILTERED_RESULTS_ACTION
} from "./actionTypes";

// Queries
import {
  GET_DEVICES,
  UPDATE_DEVICE_INFO,
  GET_DEVICES_INFO, 
  GET_DEVICE_FILTERED_RESULTS,
  GET_SELECT_DEVICES_FILTERED_RESULTS
} from "../../queries/device";

export const getDevices = (client) => ({
  type: GET_DEVICES_ACTION,
  async payload() {
    try {
      const result = await client.query({
        query: GET_DEVICES,
        fetchPolicy: "no-cache",
      });

      return result.data.getDevices;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const updateDeviceInfo = (client, deviceId, textarea) => ({
  type: UPDATE_DEVICE_INFO_ACTION,
  async payload() {
    try {
      const result = await client.mutate({
        mutation: UPDATE_DEVICE_INFO,
        variables: {
          deviceId: deviceId,
          textarea: textarea,
        },
        fetchPolicy: "no-cache",
      });

      return result.data.updateDeviceInfo;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const getDevicesInfo = (client) => ({
  type: GET_DEVICES_INFO_ACTION,
  async payload() {
    try {
      const result = await client.query({
        query: GET_DEVICES_INFO,
        fetchPolicy: "no-cache",
      });

      return result.data.getDevicesInfo;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});


export const getFilteredDevicesResults = (client, key, value) => ({
  type: GET_DEVICE_FILTERED_RESULTS_ACTION,
  async payload() {
    try {

      const result = await client.query({
        query: GET_DEVICE_FILTERED_RESULTS,
        variables: {
          key: key,
          value: value,
        },
        fetchPolicy: "no-cache",
      });

      return result.data.getFilteredDevicesResults;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});


export const getSelectFilteredDevicesResults = (client, key) => ({
  type: GET_SELECT_DEVICES_FILTERED_RESULTS_ACTION,
  async payload() {
    try {

      const result = await client.query({
        query: GET_SELECT_DEVICES_FILTERED_RESULTS,
        variables: {
          key: key,
        },
        fetchPolicy: "no-cache",
      });

      return result.data.getSelectFilteredDevicesResults;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});
