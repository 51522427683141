/**
 *
 * ResetPasswordView component class
 *
 */

import React from "react";
import { Row, Icon, Button, Form, Input, Card } from 'antd';
import PropTypes from "prop-types";

// styles
import "./reset-password-view.css";

/* eslint-disable react/prefer-stateless-function */
class ResetPasswordView extends React.Component {

  state = {
    confirmDirty: false,
  };

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('Passwords not matched yet!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirmPassword'], { force: true });
    }
    callback();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.reduxHandler(values);
        this.props.form.resetFields();
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Row id="ResetPasswordView">
        <Row>
          <h1 className="welcome-text recovery-text ">Reset Password</h1>
          <Card className="form-outer-holder">
            <Form onSubmit={this.handleSubmit} className="login-form">
              <Form.Item>
                {getFieldDecorator('password', {
                  rules: [{ required: true, message: 'Password is required!' }, { validator: this.validateToNextPassword }],
                })(
                  <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="New password" />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('confirmPassword', {
                  rules: [{ required: true, message: 'Confirm password is required!' }, { validator: this.compareToFirstPassword }],
                })(
                  <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Confirm new password" onBlur={this.handleConfirmBlur} />
                )}
              </Form.Item>
              <Row type="flex" justify="center" align="middle">
                <Form.Item>
                  <Button type="primary" htmlType="submit" className="login-form-button">
                    Submit
              </Button>
                </Form.Item>
              </Row>
            </Form>
          </Card>
        </Row>
      </Row>
    );
  }
}

ResetPasswordView.propTypes = {};

export default Form.create()(ResetPasswordView);
