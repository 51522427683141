/*
 *
 * monitoring reducer
 *
 */

import {
  GET_MONITORINGS_ACTION,
  START_MONITORING_ACTION,
  GET_MONITORING_ACTION,
  GET_MONITORINGS_COUNT_ACTION,
  START_SCHEDULED_MONITORING_ACTION,
  END_MONITORING_ACTION,
  UPDATE_MONITORING_WAREN_ACTION,
  GET_FILTERED_MONITORINGS_ACTION,
  GET_MONITORINGS_FILTERED_RESULTS_ACTION,
  GET_SELECT_MONITORINGS_FILTERED_RESULTS_ACTION,
  UPDATE_MONITORING_INFO_ACTION,
  UPDATE_MONITORING_DATES_ACTION,
  COLUMN_BASED_MONITORING_SORTING_ACTION
} from "./actionTypes";

export const initialState = {
  getMonitorings: [],
  getMonitoring: {},
  firstStepper: {},
  monitoringsCount: 0,
};

const monitoringReducer = (state = initialState, { type, payload }) => {
  switch (type) {

    case "SET_FIRST_STEPPER_VALUES_ACTION":
      return { ...state, firstStepper: { ...payload } };

    case COLUMN_BASED_MONITORING_SORTING_ACTION:
      return { ...state, getMonitorings: payload };

    case `${GET_MONITORINGS_COUNT_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${GET_MONITORINGS_COUNT_ACTION}_FULFILLED`:
      return { loading: false, ...state, monitoringsCount: payload.count };
    case `${GET_MONITORINGS_COUNT_ACTION}_REJECTED`:
      return { loading: false, ...state, monitoringsCount: payload.count };

    case `${GET_MONITORINGS_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${GET_MONITORINGS_ACTION}_FULFILLED`:
      return { loading: false, ...state, getMonitorings: payload };
    case `${GET_MONITORINGS_ACTION}_REJECTED`:
      return { loading: false, ...state, getMonitorings: payload };

    case `${GET_FILTERED_MONITORINGS_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${GET_FILTERED_MONITORINGS_ACTION}_FULFILLED`:
      return { loading: false, ...state, getMonitorings: payload.monitorings, monitoringsCount: payload.count };
    case `${GET_FILTERED_MONITORINGS_ACTION}_REJECTED`:
      return { loading: false, ...state, getMonitorings: payload.monitorings, monitoringsCount: payload.count };

    case `${GET_MONITORING_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${GET_MONITORING_ACTION}_FULFILLED`:
      return { loading: false, ...state, getMonitoring: payload };
    case `${GET_MONITORING_ACTION}_REJECTED`:
      return { loading: false, ...state, getMonitoring: payload };

    case `${UPDATE_MONITORING_INFO_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${UPDATE_MONITORING_INFO_ACTION}_FULFILLED`:
      return { loading: false, ...state, getMonitoring: payload };
    case `${UPDATE_MONITORING_INFO_ACTION}_REJECTED`:
      return { loading: false, ...state, getMonitoring: payload };

    case `${UPDATE_MONITORING_DATES_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${UPDATE_MONITORING_DATES_ACTION}_FULFILLED`:
      return { loading: false, ...state, getMonitoring: payload };
    case `${UPDATE_MONITORING_DATES_ACTION}_REJECTED`:
      return { loading: false, ...state, getMonitoring: payload };

    case `${UPDATE_MONITORING_WAREN_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${UPDATE_MONITORING_WAREN_ACTION}_FULFILLED`:
      return { loading: false, ...state, getMonitoring: payload };
    case `${UPDATE_MONITORING_WAREN_ACTION}_REJECTED`:
      return { loading: false, ...state, getMonitoring: payload };

    case `${START_SCHEDULED_MONITORING_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${START_SCHEDULED_MONITORING_ACTION}_FULFILLED`:
      return { loading: false, ...state, getMonitoring: payload };
    case `${START_SCHEDULED_MONITORING_ACTION}_REJECTED`:
      return { loading: false, ...state, getMonitoring: payload };

    case `${START_MONITORING_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${START_MONITORING_ACTION}_FULFILLED`:
      return { loading: false, ...state, startMonitoring: payload };
    case `${START_MONITORING_ACTION}_REJECTED`:
      return { loading: false, ...state, startMonitoring: payload };

    case `${GET_MONITORINGS_FILTERED_RESULTS_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${GET_MONITORINGS_FILTERED_RESULTS_ACTION}_FULFILLED`:
      return { loading: false, ...state, getMonitorings: payload };
    case `${GET_MONITORINGS_FILTERED_RESULTS_ACTION}_REJECTED`:
      return { loading: false, ...state, getMonitorings: payload };

    case `${GET_SELECT_MONITORINGS_FILTERED_RESULTS_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${GET_SELECT_MONITORINGS_FILTERED_RESULTS_ACTION}_FULFILLED`:
      return { loading: false, ...state, getMonitorings: payload };
    case `${GET_SELECT_MONITORINGS_FILTERED_RESULTS_ACTION}_REJECTED`:
      return { loading: false, ...state, getMonitorings: payload };

    case `${END_MONITORING_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${END_MONITORING_ACTION}_FULFILLED`:
      return { loading: false, ...state, endMonitoringResponse: payload };
    case `${END_MONITORING_ACTION}_REJECTED`:
      return { loading: false, ...state, endMonitoringResponse: payload };

    default:
      return state;
  }
};

export default monitoringReducer;
