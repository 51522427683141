/*
 *
 * firm actions
 *
 */
// Actions
import {
  ADD_COMPANY_ACTION,
  GET_COMPANIES_ACTION,
  REMOVE_COMPANY_ACTION,
  GET_SPECIFIC_COMPANY_ACTION,
  UPDATE_COMPANY_ACTION,
  ADD_USER_TO_COMPANY_ACTION,
  CHANGE_USER_COMPANY_ACTION,
  GET_FILTERED_COMPANY_RESULTS_ACTION,
  CHANGE_GATEWAY_COMPANY_ACTION
} from "./actionTypes";

// Queries

import {
  ADD_COMPANY,
  GET_COMPANIES,
  REMOVE_COMPANY,
  GET_SPECIFIC_COMPANY,
  UPDATE_COMPANY,
  ADD_USER_TO_COMPANY,
  CHANGE_USER_COMPANY,
  GET_FILTERED_COMPANY_RESULTS,
  CHANGE_GATEWAY_COMPANY
} from "../../queries/firm";

export const addCompany = (client, userId, values) => ({
  type: ADD_COMPANY_ACTION,
  async payload() {
    try {
      const result = await client.mutate({
        mutation: ADD_COMPANY,
        variables: {
          userId: userId,
          name: values.name,
          streetAddress: values.address,
          password: values.password ? values.password : null,
          state: values.state,
          city: values.city,
          country: values.country,
          zip: values.postal_code,
        },
        fetchPolicy: "no-cache",
      });

      const res = result.data.addCompany;
      return res.success ? res : Promise.reject(res);

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const getCompanies = (client, data) => ({
  type: GET_COMPANIES_ACTION,
  async payload() {
    try {
      const result = await client.query({
        query: GET_COMPANIES,
        variables: data,
        fetchPolicy: "no-cache",
      });

      return result.data.getCompanies;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const getFilteredCompanyResults = (client, value, key, userId) => ({
  type: GET_FILTERED_COMPANY_RESULTS_ACTION,
  async payload() {
    try {
      const result = await client.query({
        query: GET_FILTERED_COMPANY_RESULTS,
        variables: {
          value: value,
          key: key,
          userId
        },
        fetchPolicy: "no-cache",
      });

      return result.data.getFilteredCompanyResults;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});


export const removeCompany = (client, companyId) => ({
  type: REMOVE_COMPANY_ACTION,
  async payload() {
    try {
      const result = await client.mutate({
        mutation: REMOVE_COMPANY,
        variables: {
          companyId: companyId
        },
        fetchPolicy: "no-cache",
      });

      return result.data.removeCompany;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const getSpecificCompany = (client, companyId) => ({
  type: GET_SPECIFIC_COMPANY_ACTION,
  async payload() {
    try {
      const result = await client.query({
        query: GET_SPECIFIC_COMPANY,
        variables: {
          companyId: companyId
        },
        fetchPolicy: "no-cache",
      });

      return result.data.getSpecificCompany;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const updateCompany = (client, values, companyId) => ({
  type: UPDATE_COMPANY_ACTION,
  async payload() {
    try {
      const result = await client.mutate({
        mutation: UPDATE_COMPANY,
        variables: {
          companyId: companyId,
          name: values.name,
          streetAddress: values.address,
          state: values.state,
          city: values.city,
          country: values.country,
          zip: values.postal_code,
          password: values.password
        },
        fetchPolicy: "no-cache",
      });

      return result.data.updateCompany;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const addUserToCompany = (client, values, roleAndCompany) => ({
  type: ADD_USER_TO_COMPANY_ACTION,
  async payload() {
    try {
      const result = await client.mutate({
        mutation: ADD_USER_TO_COMPANY,
        variables: {
          email: values.email,
          role: values.role,
          companyId: values.companyName,
        },
        fetchPolicy: "no-cache",
      });

      const res = result.data.addUserToCompany;
      return res.success ? res : Promise.reject(res);

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const changeUserCompany = (client, userId, companyId) => ({
  type: CHANGE_USER_COMPANY_ACTION,
  async payload() {
    try {
      const result = await client.mutate({
        mutation: CHANGE_USER_COMPANY,
        variables: {
          userId: userId,
          companyId: companyId
        },
        fetchPolicy: "no-cache",
      });

      return result.data.changeUserCompany;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const changeGatewayCompany = (client, gatewayId, companyId) => ({
  type: CHANGE_GATEWAY_COMPANY_ACTION,
  async payload() {
    try {
      const result = await client.mutate({
        mutation: CHANGE_GATEWAY_COMPANY,
        variables: {
          gatewayId: gatewayId,
          companyId: companyId
        },
        fetchPolicy: "no-cache",
      });

      return result.data.changeGatewayCompany;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});