
import gql from "graphql-tag";

const task = `
  id
  order_id
  pickup_name
  pickup_address
  pickup_time
  receiver_name
  receiver_address
  receiver_time
  delivered_by
  created_at
`;

const box = `
  id  
  box_id
  box_description
  monitorbox_status
  limit_min
  limit_max
`;

const taskAndBox = `
  id
  order_id
  pickup_name
  pickup_address
  pickup_time
  receiver_name
  receiver_address
  receiver_time
  delivered_by
  ids
  box_id
  box_description
  monitorbox_status
  sensor_id
  limit_min
  limit_max
`;


export const CREATE_DELIVERY_TASK = gql`
  mutation deliveryTask(
    $orderId: String!
    $pickupName: String!
    $pickupAddress: String!
    $pickup: String!
    $receiverName: String!
    $receiverAddress: String!
    $receiveTime: String!
    $boxIds: [String]
    $boxDescription: [String]
    $monitorBoxStatus: [String]
    $sensors: [String]
    $limitMin: [Int]
    $limitMax: [Int]
    $deliveredBy: String
  ) {
    deliveryTask(
      orderId: $orderId
      pickupName: $pickupName
      pickupAddress: $pickupAddress
      pickup: $pickup
      receiverName: $receiverName
      receiverAddress: $receiverAddress
      receiveTime: $receiveTime
      boxIds: $boxIds
      boxDescription: $boxDescription
      monitorBoxStatus: $monitorBoxStatus
      sensors: $sensors
      limitMin: $limitMin
      limitMax: $limitMax
      deliveredBy: $deliveredBy
  ) {
    ${box}
  } 
 }
`;

export const GET_DELIVERY_BOXES = gql`
  query getDeliveryBoxes {
    getDeliveryBoxes {
      ${box}
    }
  } 
`;

export const GET_DELIVERY_TASKS = gql`
  query getDeliveryTasks {
    getDeliveryTasks {
      ${task}
    }
  } 
`;

export const DELETE_DELIVERY_BOX = gql`
  mutation deleteDeliveryBox(
    $id: Int
  ) {
    deleteDeliveryBox(
      id: $id
    ) {
      ${box}
    }
  } 
`;

export const DELETE_DELIVERY_TASK = gql`
  mutation deleteDeliveryTask(
    $id: Int
  ) {
    deleteDeliveryTask(
      id: $id
    ) {
      ${task}
    }
  } 
`;


export const EDIT_DELIVERY_TASK = gql`
  query editDeliveryTask(
    $id: Int
  ) {
    editDeliveryTask(
      id: $id
    ) {
      success
      message
      task {
        ${taskAndBox}
      }
    }
  } 
`;

export const UPDATE_DELIVERY_TASK = gql`
  mutation updateDeliveryTask(
    $boxPrimaryIds: [Int]
    $id: Int!
    $orderId: String!
    $pickupName: String!
    $pickupAddress: String!
    $pickup: String!
    $receiverName: String!
    $receiverAddress: String!
    $receiveTime: String!
    $boxIds: [String]
    $boxDescription: [String]
    $monitorBoxStatus: [String]
    $sensors: [String]
    $limitMin: [Int]
    $limitMax: [Int]
    $deliveredBy: String
  ) {
    updateDeliveryTask(
      boxPrimaryIds: $boxPrimaryIds
      id: $id
      orderId: $orderId
      pickupName: $pickupName
      pickupAddress: $pickupAddress
      pickup: $pickup
      receiverName: $receiverName
      receiverAddress: $receiverAddress
      receiveTime: $receiveTime
      boxIds: $boxIds
      boxDescription: $boxDescription
      monitorBoxStatus: $monitorBoxStatus
      sensors: $sensors
      limitMin: $limitMin
      limitMax: $limitMax
      deliveredBy: $deliveredBy
  ) {
    success
    message
  } 
 }
`;