/**
 *
 * AddCompanyCard component class
 *
 */
// Packages
import React from "react";
import { Row, Card, Form, Icon, Button, Input, Divider, Select } from "antd";
import PropTypes from "prop-types";

// styles
import "./add-company-card.css";

// utils -> regex
import { POSTAL_CODE_REGEX } from "../../utils/regex";

// utils -> countries
import { COUNTRIES } from "../../utils/countries";

const { Option } = Select;

/* eslint-disable react/prefer-stateless-function */
class AddCompanyCard extends React.Component {

  componentDidMount() {
    const { company, match: { params } } = this.props;
    if (params.id) {
      this.setValues(company);
    }
  };

  componentWillReceiveProps(props) {
    this.setCompany(props);
  }

  setCompany = (props) => {
    const { company, match: { params } } = props;
    if (params.id && props.company !== this.props.company) {
      this.setValues(company);
    }
  }

  setValues = (company) => {
    const { form } = this.props;
    form.setFieldsValue(company);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (this.props.match.params.id) {
          const companyId = this.props.match.params.id;
          this.props.updateReduxHandler(values, companyId);
        } else {
          this.props.reduxHandler(values);
        }
        this.props.form.resetFields();
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Row id="AddCompanyCard">
        <Card>
          <h2>{this.props.match.params.id ? "Edit Company" : "Add Company"}</h2>
          <Divider />
          <Form onSubmit={this.handleSubmit} className="login-form">
            <Form.Item>
              {getFieldDecorator('name', {
                rules: [{ required: true, message: 'Company name is required!' }],
              })(
                <Input prefix={<Icon type="table" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Name" />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('address', {
                rules: [{ required: true, message: 'Address is required!' }],
              })(
                <Input prefix={<Icon type="environment" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Address" />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('password', {
              })(
                <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Password" />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('city', {
                rules: [{ required: true, message: 'City is required!' }],
              })(
                <Input prefix={<Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="City" />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('state')(
                <Input prefix={<Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="State" />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('postal_code', {
                rules: [
                  { required: true, message: 'Postal Code is required!', },
                  { pattern: new RegExp(POSTAL_CODE_REGEX.regex), message: POSTAL_CODE_REGEX.regexMessage }
                ],
              })(
                <Input prefix={<Icon type="tags" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Postal Code" />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('country', {
                rules: [{ required: true, message: 'Country is required!' }],
              })(
                <Select placeholder="Select Country" className="company-dropdown" showSearch={true}>
                  {COUNTRIES && COUNTRIES.map((country) => (
                    <Option value={country}>{country}</Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="add-form-button">
                {this.props.match.params.id ? 'Update Company' : 'Add Company'}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Row>
    );
  }
}

AddCompanyCard.propTypes = {};

export default Form.create()(AddCompanyCard);
