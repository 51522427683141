/**
 *
 * User container class
 *
 */

// Packages
import React from "react";
import { message } from "antd";
import { connect } from "react-redux";

// Components
import UserView from "../../components/UserView";

// HOCS
import withUserHOC from "../../hocs/withUserHOC";

// Actions
import { getCompanies, changeUserCompany } from "../../ducks/firm/action";
import { getUsers, changeUserRole, getFilteredUsers, deleteUser } from "../../ducks/user/action";

/* eslint-disable react/prefer-stateless-function */
class User extends React.Component {

  async componentDidMount() {
    try {
      const { client, getUsers, getCompanies, currentUser } = this.props;
      const userId = currentUser && currentUser.id;
      const role = currentUser && currentUser.role;
      if (!currentUser) return;
      await getUsers(client, userId, role);
      await getCompanies(client);
    } catch (err) {
      message.error(err.message);
    }
  };

  deleteUserHandler = async (userId, companyId) => {
    try {
      const { client, deleteUser, currentUser } = this.props;
      const currentUserId = currentUser && currentUser.id;
      const crrUserRole = currentUser && currentUser.role;
      await deleteUser(client, userId, currentUserId, crrUserRole);
      await getCompanies(client);
    } catch (err) {
      message.error(err.message);
    }
  };

  editUserHandler = (userId) => {
    this.props.history.push(`/users/${userId}/edit`);
  };

  changeUserRoleHandler = async (userId, role) => {
    const { client, changeUserRole, currentUser } = this.props;
    const currentUserId = localStorage.getItem('userId');
    const crrUserRole = currentUser && currentUser.role;
    await changeUserRole(client, userId, role, currentUserId, crrUserRole);
    message.success('User role updated successfully!');
  };

  changeUserCompanyHandler = async (userId, companyId) => {
    const { client, changeUserCompany } = this.props;
    await changeUserCompany(client, userId, companyId);
    message.success('User company updated successfully!');
  };

  searchHandlerUser = async (firmId, role, keyword) => {
    try {
      const { client, getFilteredUsers, currentUser } = this.props;
      const userId = localStorage.getItem('userId');
      const crrUserRole = currentUser && currentUser.role;

      if(!firmId) {
        firmId = null;
      }
      if(!role) {
        role = null;
      }
      if(!keyword) {
        keyword = null;
      }

      await getFilteredUsers(client, userId, firmId, role, keyword, crrUserRole);

    } catch (err) {
      message.error(err);
    }
  };

  render() {
    return (
      <UserView
        deleteUserHandler={this.deleteUserHandler.bind(this)}
        editUserHandler={this.editUserHandler.bind(this)}
        changeUserRoleHandler={this.changeUserRoleHandler.bind(this)}
        changeUserCompanyHandler={this.changeUserCompanyHandler.bind(this)}
        searchHandlerUser={this.searchHandlerUser.bind(this)}
        {...this.props}
      />
    );
  }
}

User.propTypes = {};

const mapStateToProps = ({ user, firm }) => ({
  users: user.users,
  currentUser: user.currentUser,
  companies: firm.companies,
});

const mapDispatchToProps = dispatch => ({
  getUsers: (client, userId, role) => dispatch(getUsers(client, userId, role)),
  getCompanies: (client) => dispatch(getCompanies(client)),
  deleteUser: (client, userId, currentUserId, crrUserRole) =>
    dispatch(deleteUser(client, userId, currentUserId, crrUserRole)),
  changeUserRole: (client, userId, role, currentUserId, crrUserRole) =>
    dispatch(changeUserRole(client, userId, role, currentUserId, crrUserRole)),
  changeUserCompany: (client, userId, companyId) =>
    dispatch(changeUserCompany(client, userId, companyId)),
  getFilteredUsers: (client, userId, firmId, role, keyword, crrUserRole) =>
    dispatch(getFilteredUsers(client, userId, firmId, role, keyword, crrUserRole)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withUserHOC(withConnect(User));
