/*
 *
 * user actions
 *
 */
// Action Types
import {
  LOGIN_USER_ACTION,
  GET_USERS_ACTION,
  FORGOT_USER_PASSWORD_ACTION,
  RESET_USER_PASSWORD_ACTION,
  CHANGE_USER_PASSWORD_ACTION,
  CHANGE_USER_ROLE_ACTION,
  GET_USERS_FILTERED_RESULT_ACTION,
  DELETE_USER_ACTION,
  GET_SPECIFIC_USER_ACTION,
  UPDATE_USER_ACTION,
  LOGOUT_USER
} from "./actionTypes";

// Queries
import {
  LOGIN_USER,
  GET_USERS,
  FORGOT_USER_PASSWORD,
  RESET_USER_PASSWORD,
  CHANGE_USER_PASSWORD,
  CHANGE_USER_ROLE,
  GET_USERS_FILTERED_RESULT,
  DELETE_USER,
  GET_SPECIFIC_USER,
  UPDATE_USER
} from "../../queries/user";

export const userLogin = (client, values) => ({
  type: LOGIN_USER_ACTION,
  async payload() {
    try {
      const result = await client.query({
        query: LOGIN_USER,
        variables: values,
        fetchPolicy: "no-cache",
      });

      const res = result.data.userLogin;

      return res.success ? res : Promise.reject(res);

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const getUsers = (client, userId, role) => ({
  type: GET_USERS_ACTION,
  async payload() {
    try {
      const result = await client.query({
        query: GET_USERS,
        variables: {
          userId: userId,
          role: role
        },
        fetchPolicy: "no-cache",
      });

      return result.data.getUsers;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const forgotUserPassword = (client, values) => ({
  type: FORGOT_USER_PASSWORD_ACTION,
  async payload() {
    try {
      const result = await client.query({
        query: FORGOT_USER_PASSWORD,
        variables: {
          email: values.email,
        },
        fetchPolicy: "no-cache",
      });

      const res = result.data.forgotUserPassword;

      return res.success ? res : Promise.reject(res);

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const resetUserPassword = (client, token, values) => ({
  type: RESET_USER_PASSWORD_ACTION,
  async payload() {
    try {
      const result = await client.mutate({
        mutation: RESET_USER_PASSWORD,
        variables: {
          token: token,
          password: values.password,
        },
        fetchPolicy: "no-cache",
      });

      const res = result.data.resetUserPassword;

      return res.success ? res : Promise.reject(res);

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const changeUserPassword = (client, userId, values) => ({
  type: CHANGE_USER_PASSWORD_ACTION,
  async payload() {
    try {
      const result = await client.mutate({
        mutation: CHANGE_USER_PASSWORD,
        variables: {
          userId,
          currentPassword: values.currentPassword,
          newPassword: values.newPassword,
        },
        fetchPolicy: "no-cache",
      });

      const res = result.data.changeUserPassword;

      return res.success ? res : Promise.reject(res);

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const changeUserRole = (client, userId, role, currentUserId, crrUserRole) => ({
  type: CHANGE_USER_ROLE_ACTION,
  async payload() {
    try {
      const result = await client.mutate({
        mutation: CHANGE_USER_ROLE,
        variables: {
          userId: userId,
          role: role,
          currentUserId: currentUserId,
          crrUserRole: crrUserRole
        },
        fetchPolicy: "no-cache",
      });

      return result.data.changeUserRole;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const getFilteredUsers = (client, userId, firmId, role, keyword, crrUserRole) => ({
  type: GET_USERS_FILTERED_RESULT_ACTION,
  async payload() {
    try {
      const result = await client.query({
        query: GET_USERS_FILTERED_RESULT,
        variables: {
          userId: userId,
          firmId: firmId,
          role: role,
          keyword: keyword,
          crrUserRole: crrUserRole
        },
        fetchPolicy: "no-cache",
      });

      return result.data.getFilteredUsers;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const deleteUser = (client, userId, currentUserId, crrUserRole) => ({
  type: DELETE_USER_ACTION,
  async payload() {
    try {
      const result = await client.mutate({
        mutation: DELETE_USER,
        variables: {
          userId: userId,
          currentUserId: currentUserId,
          crrUserRole: crrUserRole
        },
        fetchPolicy: "no-cache",
      });

      return result.data.deleteUser;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const getSpecificUser = (client, userId, currentUser = false) => ({
  type: GET_SPECIFIC_USER_ACTION,
  async payload() {
    try {
      const result = await client.query({
        query: GET_SPECIFIC_USER,
        variables: {
          userId: userId,
        },
        fetchPolicy: "no-cache",
      });

      const res = result.data.getSpecificUser;

      if (currentUser) {
        return { message: res.message, currentUser: res.user }
      } else {
        return { message: res.message, user: res.user }
      }

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const updateUser = (client, userId, values, companyId) => ({
  type: UPDATE_USER_ACTION,
  async payload() {
    try {
      const result = await client.mutate({
        mutation: UPDATE_USER,
        variables: {
          userId: userId,
          email: values.email,
          role: values.role,
          companyId: typeof(values.companyName) === 'string' ? companyId : values.companyName,
        },
        fetchPolicy: "no-cache",
      });

      return result.data.updateUser;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const logout = () => {
  localStorage.clear();
  window.location = `/`;
  return {
    type: LOGOUT_USER,
  };
};
