/*
 *
 * gateway actions
 *
 */

// Action Types
import {
  GET_GAYEWAYS_ACTION,
  DELETE_GATEWAY_ACTION,
  GET_FILTERED_GATEWAYS_ACTION,
  RESET_GATWAY_PASSWORD_ACTION,
  CHANGE_GATEWAY_ROLE_ACTION,
  GET_SPECIFIC_GATEWAY_ACTION,
  UPDATE_GATEWAY_ACTION,
  MUTE_GATEWAY_ACTION,
  COLUMN_BASED_GATEWAY_SORTING_ACTION
} from "./actionTypes";

// Queries
import {
  GET_GAYEWAYS,
  DELETE_GATEWAY,
  GET_FILTERED_GATEWAYS,
  RESET_GATWAY_PASSWORD,
  CHANGE_GATEWAY_ROLE,
  GET_SPECIFIC_GATEWAY,
  UPDATE_GATEWAY,
  MUTE_GATEWAY
} from "../../queries/gateway";

export const columnBasedGatewaySorting = (sortedArr) => ({
  type: COLUMN_BASED_GATEWAY_SORTING_ACTION,
  payload: { gateways: sortedArr, count: 0 },
});

export const getGateways = (client, data) => ({
  type: GET_GAYEWAYS_ACTION,
  async payload() {
    try {
      const result = await client.query({
        query: GET_GAYEWAYS,
        variables: data,
        fetchPolicy: "no-cache",
      });

      return result.data.getGateways;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const getSpecificGateway = (client, gatewayId) => ({
  type: GET_SPECIFIC_GATEWAY_ACTION,
  async payload() {
    try {
      const result = await client.query({
        query: GET_SPECIFIC_GATEWAY,
        variables: {
          gatewayId: gatewayId,
        },
        fetchPolicy: "no-cache",
      });

      return result.data.getSpecificGateway;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const updateGateway = (client, gatewayId, gateway) => ({
  type: UPDATE_GATEWAY_ACTION,
  async payload() {
    try {
      const result = await client.mutate({
        mutation: UPDATE_GATEWAY,
        variables: {
          gatewayId: gatewayId,
          company_id: gateway.company_id,
          location_id: gateway.location_id,
          location_details: gateway.location_details,
          position_details: gateway.position_details,
          position_photo: gateway.position_photo,
        },
        fetchPolicy: "no-cache",
      });

      return result.data.updateGateway;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const deleteGateway = (client, gatewayId, pageNo) => ({
  type: DELETE_GATEWAY_ACTION,
  async payload() {
    try {
      const result = await client.mutate({
        mutation: DELETE_GATEWAY,
        variables: {
          gatewayId: gatewayId,
          pageNo: pageNo,
        },
        fetchPolicy: "no-cache",
      });

      return result.data.deleteGateway;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const getFilteredGateways = (client, keyword, userId) => ({
  type: GET_FILTERED_GATEWAYS_ACTION,
  async payload() {
    try {
      const result = await client.query({
        query: GET_FILTERED_GATEWAYS,
        variables: {
          keyword,
          userId
        },
        fetchPolicy: "no-cache",
      });

      return result.data.getFilteredGateways;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const resetGatewayPassword = (client, gatewayId, values) => ({
  type: RESET_GATWAY_PASSWORD_ACTION,
  async payload() {
    try {
      const result = await client.mutate({
        mutation: RESET_GATWAY_PASSWORD,
        variables: {
          gatewayId: gatewayId,
          password: values.password,
        },
        fetchPolicy: "no-cache",
      });

      return result.data.resetGatewayPassword;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});


export const changeGatewayRole = (client, userId, role) => ({
  type: CHANGE_GATEWAY_ROLE_ACTION,
  async payload() {
    try {
      const result = await client.mutate({
        mutation: CHANGE_GATEWAY_ROLE,
        variables: {
          userId: userId,
          role: role
        },
        fetchPolicy: "no-cache",
      });

      return result.data.changeGatewayRole;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const muteGateway = (client, gatewayId, isMuted, pageNo) => ({
  type: MUTE_GATEWAY_ACTION,
  async payload() {
    try {
      const result = await client.mutate({
        mutation: MUTE_GATEWAY,
        variables: {
          gatewayId: gatewayId,
          isMuted: isMuted,
          pageNo: pageNo
        },
        fetchPolicy: "no-cache",
      });

      return result.data.muteGateway;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});
