/**
 *
 * DashboardMonitorings container class
 *
 */

// Packages
import React from "react";
import { message } from "antd";
import { connect } from "react-redux";

// Components
import DashboardMonitoringsView from "../../components/DashboardMonitoringsView";

// HOCS 
import withUserHOC from "../../hocs/withUserHOC";

// Actions 
import { getMonitorings, getMonitoringsCount, getFilteredMonitoringsResults, columnBasedMonitoringSorting } from "../../ducks/monitoring/action";
import { saveFilters, saveColumns } from "../../ducks/filter/action";

/* eslint-disable react/prefer-stateless-function */
class DashboardMonitorings extends React.Component {

  componentWillMount() {
    this.fetchMonitorings(this.props);
  };
  
  componentDidMount() {
    this.fetchMonitorings(this.props);
  };

  componentWillReceiveProps(props) {
    if (props.currentUser !== this.props.currentUser) {
      this.fetchMonitorings(props);
    }
  }

  componentWillUnmount() {
    this.props.saveFilters('monitoring', undefined);
  }

  fetchMonitorings = async (props) => {
    try {
      const { client, getMonitorings, getMonitoringsCount, getFilteredMonitoringsResults, savedFilters } = props;
      if (savedFilters && savedFilters.values) {
        const keyword = savedFilters.values.keyword ? savedFilters.values.keyword : '';
        const isActive = savedFilters.values.isActive ? savedFilters.values.isActive : 'null';
        const isCritical = savedFilters.values.isCritical ? savedFilters.values.isCritical : 'null';
        const assetType = savedFilters.values.assetType ? savedFilters.values.assetType : '';

        if (keyword || isActive || isCritical || assetType) {
          await getFilteredMonitoringsResults(client, keyword, isActive, isCritical, assetType);
        }
      } else {
        await getMonitoringsCount(client, { userId: parseInt(localStorage.userId) });
        await getMonitorings(client, { userId: localStorage.userId, pageNo: 1 });
      }

    } catch (err) {
      message.error(err.message);
    }
  }

  saveColumnsHandler = (columnsHeadings, columnsKeys) => {
    const { saveColumns } = this.props;
    saveColumns('monitorings', columnsHeadings, columnsKeys);
  };

  columnSortingHandler = (sortedArr) => {
    const { columnBasedMonitoringSorting } = this.props;
    columnBasedMonitoringSorting(sortedArr);
  };

  searchHandler = async (keyword, isActive, isCritical, assetType) => {
    try {
      const { client, getFilteredMonitoringsResults, saveFilters } = this.props;
  
      const values = {
        keyword: keyword,
        isActive: isActive,
        isCritical: isCritical,
        assetType: assetType
      };

      await getFilteredMonitoringsResults(client, keyword, isActive, isCritical, assetType, localStorage.userId);
      saveFilters('monitoring', values);
    } catch (err) {
      message.error(err);
    }
  };

  reduxPageHandler = async (pageNo) => {
    try {
      await this.props.getMonitorings(this.props.client, pageNo);
    } catch (err) {
      message.error(err);
    }
  };

  render() {
    return (
      <DashboardMonitoringsView
        {...this.props}
        reduxPageHandler={this.reduxPageHandler.bind(this)}
        searchHandler={this.searchHandler.bind(this)}
        saveColumnsHandler={this.saveColumnsHandler.bind(this)}
        columnSortingHandler={this.columnSortingHandler.bind(this)}
      />
    );
  }
}

DashboardMonitorings.propTypes = {};

const mapStateToProps = ({ monitoring, filter }) => ({
  monitorings: monitoring.getMonitorings,
  monitoringsCount: monitoring.monitoringsCount,
  savedFilters: filter.savedFilters,
  headings: filter.monitoringHeadings,
  keys: filter.monitoringKeys
});

const mapDispatchToProps = dispatch => ({
  getMonitoringsCount: (client, data) => dispatch(getMonitoringsCount(client, data)),
  getMonitorings: (client, data) => dispatch(getMonitorings(client, data)),
  getFilteredMonitoringsResults: (client, keyword, isActive, isCritical, assetType, userId) =>
    dispatch(getFilteredMonitoringsResults(client, keyword, isActive, isCritical, assetType, userId)),
  saveFilters: (key, values) => dispatch(saveFilters(key, values)),
  saveColumns: (key, columnsHeadings, columnsKeys) => 
    dispatch(saveColumns(key, columnsHeadings, columnsKeys)),
  columnBasedMonitoringSorting: (sortedArr) => dispatch(columnBasedMonitoringSorting(sortedArr)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withUserHOC(withConnect(DashboardMonitorings));
