import gql from "graphql-tag";

const product = `
  id
  location
  best_before
  monitoring
  qr_code
  manufacturer
  created_at
`;

export const GET_RRODUCTS = gql`
  query getProducts {
    getProducts {
      ${product}
    }
  }
`;
