/**
 *
 * GatewayShowPage container class
 *
 */
// Packages
import React from "react";
import { message } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Components
import GatewayShowPageView from "../../components/GatewayShowPageView";

// HOCS
import withUserHOC from "../../hocs/withUserHOC";
import withFileUploader from "../../hocs/withFileUploader";

// Actions
import { getSpecificGateway, updateGateway } from "../../ducks/gateway/action";
import { getCompanies } from "../../ducks/firm/action";
import { getLocationsAgainstCompany } from "../../ducks/location/action";

/* eslint-disable react/prefer-stateless-function */
class GatewayShowPage extends React.Component {

  state = {
    companyId: null,
    locationId: null,
  };

  async componentDidMount() {
    try {
      const { client, getCompanies, match: {params}, getSpecificGateway, getLocationsAgainstCompany } = this.props;
      await getSpecificGateway(client, params.id);
      this.setState({ companyId: this.props.gateway.company_id, locationId: this.props.gateway.locaton_id });
      await getCompanies(client);
      await getLocationsAgainstCompany(client, this.props.gateway.company_id);
    } catch (err) {
      message.error(err.message);
    }
  };

  imageUploader = (file) => {
    this.props.uploadImage(file);
  };

  changeCompany = async (companyId) => {
    const { client, getLocationsAgainstCompany } = this.props;
    await getLocationsAgainstCompany(client, companyId);
  };

  updateGatewayHandler = async (key, value) => {
    try {
      const { client, updateGateway, match: { params: {id } } } = this.props;
      await updateGateway(client, id, key, value);
    } catch (err) {
      message.error(err.message);
    }
  };

  reduxHandler = async (values) => {
    const { uploading, match: { params }, uploadedFile, history } = this.props;
    const { companyId, locationId } = this.state;

    if(!uploading) {

      const gateway = {
        company_id: typeof(values.company) === 'string' ? companyId : values.company,
        location_id: typeof(values.location) === 'string' ? locationId : values.location,
        location_details: values.location_details,
        position_details: values.positon_details,
        position_photo: !uploading && uploadedFile && uploadedFile.url ? uploadedFile.url : this.props.gateway.position_photo,
      };

      const { client, updateGateway } = this.props;
      await updateGateway(client, params.id, gateway);
      message.success("Gateway updated successfully!");
      history.push('/gateways');
    }
  };

  render() {
    return (
      <GatewayShowPageView
        {...this.props}
        imageUploader={this.imageUploader.bind(this)}
        uploadFile={this.props.uploadFile}
        reduxHandler={this.reduxHandler.bind(this)}
        changeCompany={this.changeCompany.bind(this)}
        updateGatewayHandler={this.updateGatewayHandler.bind(this)}
      />
    );
  }
}

GatewayShowPage.propTypes = {};

const mapStateToProps = ({ gateway, firm, location }) => ({
  gateway: gateway.gateway,
  companies: firm.companies,
  locations: location.locations,
});

const mapDispatchToProps = dispatch => ({
  getSpecificGateway: (client, gatewayId) => dispatch(getSpecificGateway(client, gatewayId)),
  getCompanies: (client) => dispatch(getCompanies(client)),
  getLocationsAgainstCompany: (client, companyId) =>
    dispatch(getLocationsAgainstCompany(client, companyId)),
  // updateGateway: (client, gatewayId, key, value) => 
    // dispatch(updateGateway(client, gatewayId, key, value)),
  updateGateway: (client, gatewayId, gateway) => 
    dispatch(updateGateway(client, gatewayId, gateway)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withUserHOC(withFileUploader(withConnect(GatewayShowPage)));
