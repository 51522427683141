/*
 *
 * sensor actions
 *
 */

// Action Types
import { 
  GET_SENSORS_COUNT_ACTION,
  GET_SENSORS_ACTION,
  GET_SENSOR_ACTION,
  GET_FILTERED_SENSORS_ACTION,
  UPDATE_SENSOR_INFO_ACTION,
  GET_ACTIVE_SENSORS_ACTION,
  COLUMN_BASED_SENSOR_SORTING_ACTION,
  GET_SENSOR_LOGS_ACTION,
  CHANGE_SENSOR_COMPANY_ACTION
} from "./actionTypes";

// Queries
import {
  GET_SENSORS_COUNT,
  GET_SENSORS,
  GET_SENSOR,
  GET_FILTERED_SENSORS,
  UPDATE_SENSOR_INFO,
  GET_ACTIVE_SENSORS,
  GET_SENSOR_LOGS,
  CHANGE_SENSOR_COMPANY
} from "../../queries/sensor";

export const columnBasedSensorSorting = (sortedArr) => ({
  type: COLUMN_BASED_SENSOR_SORTING_ACTION,
  payload: sortedArr,
});

export const getSensorsCount = (client, data) => ({
  type: GET_SENSORS_COUNT_ACTION,
  async payload() {
    try {
      const result = await client.query({
        query: GET_SENSORS_COUNT,
        variables: data,
        fetchPolicy: "no-cache",
      });

      return result.data.getSensorsCount;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const getSensors = (client, data) => ({
  type: GET_SENSORS_ACTION,
  async payload() {
    try {
      const result = await client.query({
        query: GET_SENSORS,
        variables: data,
        fetchPolicy: "no-cache",
      });

      return result.data.getSensors;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const changeSensorCompany = (client, data) => ({
  type: CHANGE_SENSOR_COMPANY_ACTION,
  async payload() {
    try {
      const result = await client.mutate({
        mutation: CHANGE_SENSOR_COMPANY,
        variables: data,
        fetchPolicy: "no-cache",
      });

      const { success, message, sensor } = result.data.changeSensorCompany;
      return success ? sensor : Promise.reject({ success, message });

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const getSensorLogs = (client, data) => ({
  type: GET_SENSOR_LOGS_ACTION,
  async payload() {
    try {
      const result = await client.query({
        query: GET_SENSOR_LOGS,
        variables: data,
        fetchPolicy: "no-cache",
      });

      return result.data.getSensorLogs;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const getActiveSensors = (client, userId) => ({
  type: GET_ACTIVE_SENSORS_ACTION,
  async payload() {
    try {

      const result = await client.query({
        query: GET_ACTIVE_SENSORS,
        variables: { userId },
        fetchPolicy: "no-cache",
      });

      return result.data.getActiveSensors;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const getSensor = (client, id) => ({
  type: GET_SENSOR_ACTION,
  async payload() {
    try {

      const result = await client.query({
        query: GET_SENSOR,
        variables: {
          id: id,
        },
        fetchPolicy: "no-cache",
      });

      return result.data.getSensor;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const getFilteredSensorsResults = (client, key, keyword, mode, userId) => ({
  type: GET_FILTERED_SENSORS_ACTION,
  async payload() {
    try {
      const result = await client.query({
        query: GET_FILTERED_SENSORS,
        variables: {
          key: key,
          keyword: keyword,
          mode: mode,
          userId
        },
        fetchPolicy: "no-cache",
      });

      return result.data.getFilteredSensorsResults;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const updateSensorInfo = (client, sensorId, key, value) => ({
  type: UPDATE_SENSOR_INFO_ACTION,
  async payload() {
    try {

      const result = await client.mutate({
        mutation: UPDATE_SENSOR_INFO,
        variables: {
          sensorId: sensorId,
          key: key,
          value: value
        },
        fetchPolicy: "no-cache",
      });

      return result.data.updateSensorInfo;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});


