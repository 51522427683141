/*
 *
 * firm reducer
 *
 */

import {
  ADD_COMPANY_ACTION,
  GET_COMPANIES_ACTION,
  REMOVE_COMPANY_ACTION,
  GET_SPECIFIC_COMPANY_ACTION,
  UPDATE_COMPANY_ACTION,
  ADD_USER_TO_COMPANY_ACTION,
  CHANGE_USER_COMPANY_ACTION,
  GET_FILTERED_COMPANY_RESULTS_ACTION,
  CHANGE_GATEWAY_COMPANY_ACTION
} from "./actionTypes";

export const initialState = {
  companies: []
};

const firmReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case `${ADD_COMPANY_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${ADD_COMPANY_ACTION}_FULFILLED`:
      return { loading: false, ...state, addCompanyResponse: payload };
    case `${ADD_COMPANY_ACTION}_REJECTED`:
      return { loading: false, ...state, addCompanyResponse: payload };

    case `${GET_COMPANIES_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${GET_COMPANIES_ACTION}_FULFILLED`:
      return { loading: false, ...state, companies: payload };
    case `${GET_COMPANIES_ACTION}_REJECTED`:
      return { loading: false, ...state, companies: payload };

    case `${GET_FILTERED_COMPANY_RESULTS_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${GET_FILTERED_COMPANY_RESULTS_ACTION}_FULFILLED`:
      return { loading: false, ...state, companies: payload };
    case `${GET_FILTERED_COMPANY_RESULTS_ACTION}_REJECTED`:
      return { loading: false, ...state, companies: payload };

    case `${REMOVE_COMPANY_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${REMOVE_COMPANY_ACTION}_FULFILLED`:
      return { loading: false, ...state, companies: payload };
    case `${REMOVE_COMPANY_ACTION}_REJECTED`:
      return { loading: false, ...state, companies: payload };

    case `${GET_SPECIFIC_COMPANY_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${GET_SPECIFIC_COMPANY_ACTION}_FULFILLED`:
      return { loading: false, ...state, specificCompany: payload };
    case `${GET_SPECIFIC_COMPANY_ACTION}_REJECTED`:
      return { loading: false, ...state, specificCompany: payload };

    case `${UPDATE_COMPANY_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${UPDATE_COMPANY_ACTION}_FULFILLED`:
      return { loading: false, ...state, updateCompanyResponse: payload };
    case `${UPDATE_COMPANY_ACTION}_REJECTED`:
      return { loading: false, ...state, updateCompanyResponse: payload };

    case `${ADD_USER_TO_COMPANY_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${ADD_USER_TO_COMPANY_ACTION}_FULFILLED`:
      return { loading: false, ...state, addUserResponse: payload };
    case `${ADD_USER_TO_COMPANY_ACTION}_REJECTED`:
      return { loading: false, ...state, addUserResponse: payload };

    case `${CHANGE_USER_COMPANY_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${CHANGE_USER_COMPANY_ACTION}_FULFILLED`:
      return { loading: false, ...state, companies: payload };
    case `${CHANGE_USER_COMPANY_ACTION}_REJECTED`:
      return { loading: false, ...state, companies: payload };

    case `${CHANGE_GATEWAY_COMPANY_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${CHANGE_GATEWAY_COMPANY_ACTION}_FULFILLED`:
      return { loading: false, ...state, companies: payload };
    case `${CHANGE_GATEWAY_COMPANY_ACTION}_REJECTED`:
      return { loading: false, ...state, companies: payload };

    default:
      return state;
  }
};

export default firmReducer;
