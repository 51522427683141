import moment from 'moment-timezone';
var pdfMake = require("pdfmake/build/pdfmake");
var pdfFonts = require("pdfmake/build/vfs_fonts");

export const OVERVIEW_PDF_REPORT = (monitoring, sensorLogs) => {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  let content = [];

  const monitoringDetailsHeading = { text: 'Temperature Monitoring Documentation', fontSize: 16, bold: true, marginBottom: 10 };
  const monitoringDetails = {
    table: {
      body: [
        [
          { text: 'Monitoring Name', fontSize: 11, bold: true },
          { text: monitoring.name ? monitoring.name : 'N/A', fontSize: 11 }
        ],
        [
          { text: 'Monitoring Details', fontSize: 11, bold: true },
          { text: monitoring.comment ? monitoring.comment : 'N/A', fontSize: 11 }
        ],
        [
          { text: 'Sensor Location', fontSize: 11, bold: true },
          { text: monitoring.position ? monitoring.position : 'N/A', fontSize: 11 }
        ],
        [
          { text: 'Started', fontSize: 11, bold: true },
          { text: `${monitoring.started} by: ${monitoring.email}`, fontSize: 11 }
        ],
        [
          { text: 'Ended', fontSize: 11, bold: true },
          { text: monitoring.ended ? monitoring.ended : 'N/A', fontSize: 11 }
        ],
        [
          { text: 'Limit Minimum', fontSize: 11, bold: true },
          { text: `${monitoring.min} °C`, fontSize: 11 }
        ],
        [
          { text: 'Limit Maximum', fontSize: 11, bold: true },
          { text: `${monitoring.max} °C`, fontSize: 11 }
        ],
        [
          { text: 'Critical', fontSize: 14, bold: true },
          { text: monitoring.is_crtical ? 'Critical' : 'OK', fontSize: 14 }
        ],
      ]
    },
  }

  content.push(monitoringDetailsHeading);
  content.push(monitoringDetails);

  const criticalLogsHeading = { text: 'Critical Logs', fontSize: 13, bold: true, marginTop: 20, marginBottom: 10 };
  const criticalLogs = {
    table: {
      body: [
        [
          { text: 'Date', fontSize: 11 },
          { text: 'Temperature', fontSize: 11, bold: true },
          { text: 'Status', fontSize: 11 }
        ]
      ]
    },
  }

  content.push(criticalLogsHeading);
  content.push(criticalLogs);

  let docDefinition = {
    content,
  }

  const date = new Date();
  pdfMake.createPdf(docDefinition).download(`tsenso-monitoring_${monitoring.name}_${moment(date).tz("Europe/Berlin").format('YYYY-MM-DD-HHmm')}_minute-avg.pdf`);

}; 