/**
 *
 * Company container class
 *
 */
// packages
import React from "react";
import { message } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Components
import CompanyView from "../../components/CompanyView";

// HOCS
import withUserHOC from "../../hocs/withUserHOC";

// Actions
import { getCompanies, removeCompany, getFilteredCompanyResults } from "../../ducks/firm/action";

/* eslint-disable react/prefer-stateless-function */
class Company extends React.Component {

  async componentDidMount() {
    try {
      const { client, getCompanies } = this.props;
      await getCompanies(client, { userId: localStorage.userId });
    } catch (err) {
      message.error(err.message);
    }
  };

  deleteCompany = async (companyId) => {
    try {
      const { client, removeCompany } = this.props;
      await removeCompany(client, companyId);
    } catch (err) {
      message.error(err.message);
    }
  };

  editCompany = async (companyId) => {
    this.props.history.push(`/companies/${companyId}/edit`) 
  };

  searchHandler = async (value, key, userId) => {
    try {
      const { client, getFilteredCompanyResults } = this.props;
      await getFilteredCompanyResults(client, value, key, userId);
    } catch (err) {
      message.error(err);
    }
  };

  render() {
    return (
      <CompanyView
        {...this.props}
        deleteCompany={this.deleteCompany.bind(this)}
        editCompany={this.editCompany.bind(this)}
        searchHandler={this.searchHandler.bind(this)}
      />
    );
  }
}

Company.propTypes = {};

const mapStateToProps = ({ firm, user }) => ({
  companies: firm.companies,
  currentUser: user.currentUser,
});

const mapDispatchToProps = dispatch => ({
  getCompanies: (client, data) => dispatch(getCompanies(client, data)),
  removeCompany: (client, companyId) => dispatch(removeCompany(client, companyId)),
  getFilteredCompanyResults: (client, value, key, userId) => 
    dispatch(getFilteredCompanyResults(client, value, key, userId)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withUserHOC(withConnect(Company));
