/**
 *
 * MonitoringWaren container class
 *
 */

// Packages
import React from "react";
import { message } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Components
import MonitoringWarenView from "../../components/MonitoringWarenView";

// HOCS
import withUserHOC from "../../hocs/withUserHOC";

// Actions
import {
  getMonitoring,
  updateMonitoringWaren,
  endMonitoring,
  startScheduledMonitoring,
  updateMonitoringInfo,
  updateMonitoringDates,
} from "../../ducks/monitoring/action";
import { getSensors, getSensorLogs } from "../../ducks/sensor/action";

// Reports
import { OVERVIEW_PDF_REPORT } from "../../reports/overviewPDFReport";
import { MONTHLY_PDF_REPORT } from "../../reports/monthlyPDFReport";
import { CSV_REPORT } from "../../reports/csvReport";

/* eslint-disable react/prefer-stateless-function */
class MonitoringWaren extends React.Component {
  async componentDidMount() {
    try {
      const {
        match: {
          params: { id },
        },
        client,
        getMonitoring,
      } = this.props;
      await getMonitoring(client, id);

      const { monitoring } = this.props;
      if (monitoring) {
        await this.props.getSensorLogs(client, {
          sensorId: monitoring.sensor.mac,
          monitoringId: monitoring.id,
        });
      }
    } catch (err) {
      message.error(err.message);
    }
  }

  handlerStartScheduledMonitoring = async () => {
    const {
      client,
      startScheduledMonitoring,
      match: {
        params: { id },
      },
    } = this.props;
    await startScheduledMonitoring(client, id);
    message.success("Scheduling started successfully!");
  };

  handlerEndMonitoring = async () => {
    const {
      match: {
        params: { id },
      },
      endMonitoring,
      client,
      history,
    } = this.props;
    await endMonitoring(client, id);
    history.push("/monitorings");
  };

  overpdfReport = async () => {
    OVERVIEW_PDF_REPORT(this.props.monitoring, this.props.sensorLogs);
  };

  // monthlypdfReport = async (data) => {
  //   MONTHLY_PDF_REPORT(this.props.monitoring, this.props.sensorLogs,data);
  // };


  monthlypdfReport = async (base64) => {
    // console.log("AAA Real sensorLogs:",this.props.sensorLogs)
    // console.log("AAA Real Props:",this.props)
    MONTHLY_PDF_REPORT(this.props.monitoring, this.props.sensorLogs,base64);
  };


  
  getDataUri=(base64)=>{
  // console.log(base64)
  return base64;
}



  downloadCSV = async () => {
    CSV_REPORT(this.props.monitoring, this.props.sensorLogs);
  };

  updateMonitoringHandler = async (key, value) => {
    try {
      const {
        client,
        updateMonitoringInfo,
        match: {
          params: { id },
        },
      } = this.props;
      await updateMonitoringInfo(client, id, key, value);
    } catch (err) {
      message.error(err.message);
    }
  };

  updateMonitoringDatesHandler = async (key, dates) => {
    try {
      const {
        client,
        updateMonitoringDates,
        match: {
          params: { id },
        },
      } = this.props;
      await updateMonitoringDates(client, id, key, dates);
    } catch (err) {
      message.error(err.message);
    }
  };

  reduxHandler = async (values) => {
    try {
      const {
        match: {
          params: { id },
        },
        client,
        updateMonitoringWaren,
      } = this.props;
      await updateMonitoringWaren(client, id, values);
      message.success("Monitoring Waren updated successfully!");
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    // console.log("AAA Real sensorLogs:",this.props.sensorLogs)
    return (
      <div>
        {this.props.sensorLogs && (
          <MonitoringWarenView
            {...this.props}
            reduxHandler={this.reduxHandler.bind(this)}
            handlerStartScheduledMonitoring={this.handlerStartScheduledMonitoring.bind(
              this
            )}
            handlerEndMonitoring={this.handlerEndMonitoring.bind(this)}
            overpdfReport={this.overpdfReport.bind(this)}
            monthlypdfReport={this.monthlypdfReport.bind(this)}
            downloadCSV={this.downloadCSV.bind(this)}
            updateMonitoringHandler={this.updateMonitoringHandler.bind(this)}
            updateMonitoringDatesHandler={this.updateMonitoringDatesHandler.bind(
              this
            )}
          />
        )}
      </div>
    );
  }
}

MonitoringWaren.propTypes = {};

const mapStateToProps = ({ monitoring, sensor }) => ({
  monitoring: monitoring.getMonitoring,
  sensorLogs: sensor.sensorLogs,
  logsLoading: sensor.logsLoading,
  sensors: sensor.sensors,
});

const mapDispatchToProps = (dispatch) => ({
  getMonitoring: (client, id) => dispatch(getMonitoring(client, id)),
  getSensors: (client, pageNo) => dispatch(getSensors(client, pageNo)),
  updateMonitoringWaren: (client, id, values) =>
    dispatch(updateMonitoringWaren(client, id, values)),
  endMonitoring: (client, id) => dispatch(endMonitoring(client, id)),
  startScheduledMonitoring: (client, id) =>
    dispatch(startScheduledMonitoring(client, id)),
  updateMonitoringInfo: (client, id, key, value) =>
    dispatch(updateMonitoringInfo(client, id, key, value)),
  updateMonitoringDates: (client, id, key, dates) =>
    dispatch(updateMonitoringDates(client, id, key, dates)),
  getSensorLogs: (client, data) => dispatch(getSensorLogs(client, data)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withUserHOC(withConnect(MonitoringWaren));
