/**
 *
 * DeliveryTasksView component class
 *
 */
// Packages
import React from "react";
import { Row, Col, Divider, Tooltip, Popconfirm, Icon, Table } from "antd";
import PropTypes from "prop-types";

// styles
import "./delivery-tasks-view.css";

// Utils 
import Column from "antd/lib/table/Column";

/* eslint-disable react/prefer-stateless-function */
class DeliveryTasksView extends React.Component {
  render() {
    return (
      <Row id="DeliveryTasksView">
        {this.props.deliveryTasks && this.props.deliveryTasks.length === 0 ? (
          <p className="no-assigned-text">There are no mobile tasks asigned to you yet.</p>
        ) : (
            <Table
              dataSource={this.props.deliveryTasks}
            >
              <Column
                title='Order Id'
                dataIndex='order_id'
                key='order_id'
              />
              <Column
                title='From Address'
                dataIndex='pickup_address'
                key='pickup_address'
              />
              <Column
                title='To Address'
                dataIndex='receiver_address'
                key='receiver_address'
              />
              <Column
                title='Driver Name'
                dataIndex='delivered_by'
                key='delivered_by'
              />
              <Column
                title="Actions"
                key="actions"
                render={(record) => (
                  <span>
                    <Tooltip placement="bottom" title={"Edit Task"}>
                      <Icon
                        type="edit"
                        onClick={() => this.props.editTask(record.id)}
                      />
                    </Tooltip>
                    <Divider type="vertical" />
                    <Tooltip placement="bottom" title={"Delete Task"}>
                      <Popconfirm
                        title="Are you sure delete this task?"
                        onConfirm={() => this.props.deleteTask(record.id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Icon
                          type="delete"
                        />
                      </Popconfirm>
                    </Tooltip>
                  </span>
                )}
              />
            </Table>
          )}
      </Row>
    );
  }
}

DeliveryTasksView.propTypes = {};

export default DeliveryTasksView;
