/**
 *
 * ResetPassword container class
 *
 */

// Packages
import React from "react";
import { message } from "antd"; 
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Components
import ResetPasswordView from "../../components/ResetPasswordView";

// HOCS
import withAppHOC from "../../hocs/withAppHOC";

// Actions
import { resetUserPassword } from "../../ducks/user/action";

class ResetPassword extends React.Component {

  reduxHandler = async (values) => {
    try {
      const { client, resetUserPassword, match } = this.props;
      const token = match.params.token;
      await resetUserPassword(client, token, values);
      message.success(this.props.resetPasswordResponse.message);
      this.props.history.push('/');
    } catch (err) {
      message.error(err.message);
    }
  };

  render() {
    return (
      <ResetPasswordView
        reduxHandler={this.reduxHandler}
      />
    );
  }
}

ResetPassword.propTypes = {};

const mapStateToProps = ({ user }) => ({
  resetPasswordResponse: user.resetPasswordResponse,
});

const mapDispatchToProps = dispatch => ({
  resetUserPassword: (client, token, values) =>
    dispatch(resetUserPassword(client, token, values)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withAppHOC(withConnect(ResetPassword));
