/**
 *
 * CreateDeliveryTask container class
 *
 */

// Packages
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";

// Components
import CreateDeliveryTaskView from "../../components/CreateDeliveryTaskView";

// HOCS
import withUserHOC from "../../hocs/withUserHOC";

// Actions
import { deliveryTask, editDeliveryTask, getDeliveryBoxes, deleteDeliveryBox, updateDeliveryTask } from "../../ducks/deliveryTask/action";
import { message } from "antd";

/* eslint-disable react/prefer-stateless-function */
class CreateDeliveryTask extends React.Component {

  async componentDidMount() {
    const { client, editDeliveryTask, getDeliveryBoxes } = this.props;
    const id = this.props.match.params.id;
    // await editDeliveryTask(client, id);
    // await getDeliveryBoxes(client);
  };

  deleteBox = async (id) => {
    const { client, deleteDeliveryBox } = this.props;
    await deleteDeliveryBox(client, id);
  };

  reduxHandler = async (values) => {
    try {
      const { client, deliveryTask } = this.props;
      await deliveryTask(client, values);
      message.success('Boxes and task added successfully!');
      this.props.history.push('/delivery-tasks');
    } catch (err) {
      console.log(err);
    }
  };

  updateReduxHandler = async (values, id) => {
    try {
      const { client, updateDeliveryTask } = this.props;
      const boxPrimaryIds = this.props.editTaskResponse && this.props.editTaskResponse.task.ids;
      await updateDeliveryTask(client, values, id, boxPrimaryIds);
      message.success('Task updated successfully!');
      this.props.history.push('/delivery-tasks');
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    return (
      <CreateDeliveryTaskView
        boxes={this.props.boxes && this.props.boxes}
        deleteBox={this.deleteBox.bind(this)}
        reduxHandler={this.reduxHandler.bind(this)}
        task={this.props.editTaskResponse && this.props.editTaskResponse.task}
        updateReduxHandler={this.updateReduxHandler.bind(this)}
        {...this.props}
      />
    );
  }
}

CreateDeliveryTask.propTypes = {};

const mapStateToProps = ({ taskDelivery }) => ({
  boxes: taskDelivery.deliveryBoxes,
  editTaskResponse: taskDelivery.editTaskResponse,
});

const mapDispatchToProps = dispatch => ({
  deliveryTask: (client, values) => dispatch(deliveryTask(client, values)),
  // getDeliveryBoxes: (client) => dispatch(getDeliveryBoxes(client)),
  deleteDeliveryBox: (client, id) => dispatch(deleteDeliveryBox(client, id)),
  editDeliveryTask: (client, id) => dispatch(editDeliveryTask(client, id)),
  updateDeliveryTask: (client, values, id, boxPrimaryIds) =>
   dispatch(updateDeliveryTask(client, values, id, boxPrimaryIds)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withUserHOC(withConnect(CreateDeliveryTask));
