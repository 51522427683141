/*
 *
 * firm actionTypes
 *
 */

export const ADD_COMPANY_ACTION = "ADD_COMPANY_ACTION";
export const GET_COMPANIES_ACTION = "GET_COMPANIES_ACTION";
export const GET_FILTERED_COMPANY_RESULTS_ACTION = "GET_FILTERED_COMPANY_RESULTS_ACTION";
export const REMOVE_COMPANY_ACTION = "REMOVE_COMPANY_ACTION";
export const GET_SPECIFIC_COMPANY_ACTION = "GET_SPECIFIC_COMPANY_ACTION";
export const UPDATE_COMPANY_ACTION = "UPDATE_COMPANY_ACTION";
export const ADD_USER_TO_COMPANY_ACTION = "ADD_USER_TO_COMPANY_ACTION";
export const CHANGE_USER_COMPANY_ACTION = "CHANGE_USER_COMPANY_ACTION";
export const CHANGE_GATEWAY_COMPANY_ACTION = "CHANGE_GATEWAY_COMPANY_ACTION";

