import React, { PureComponent } from "react";
import { withRouter } from 'react-router-dom';
import { withApollo } from "react-apollo";
import { Layout } from 'antd';

// components
import MyHeader from '../components/MyHeader';
import MyFooter from '../components/MyFooter';

// styles
import './hoc.css';

const { Content } = Layout;

export default (WrappedComponent, props = {}) => {
  class WithAppHOC extends PureComponent {

    render() {
      return (
        <Layout className="layout">
          <MyHeader   
            isCurrentGateway={localStorage.getItem('userId') ? true : false}
            history={this.props.history}
          />
          <Content className="app-content">
            <WrappedComponent
              {...this.props}
            />
          </Content>
          <MyFooter />
        </Layout>
      )
    }
  }

  return withRouter(withApollo(WithAppHOC));
}
