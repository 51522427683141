/**
 *
 * DeliveryTasks container class
 *
 */

// Packages
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Components
import DeliveryTasksView from "../../components/DeliveryTasksView";

// HOCS
import withAppHOC from "../../hocs/withAppHOC";

// Actions
import { getDeliveryTasks, deleteDeliveryTask, editDeliveryTask } from "../../ducks/deliveryTask/action";

/* eslint-disable react/prefer-stateless-function */
class DeliveryTasks extends React.Component {

  async componentDidMount() {
    try {
      const { client, getDeliveryTasks } = this.props;
      // await getDeliveryTasks(client);
    } catch (err) {
      console.log(err);
    }
  };

  deleteTask = async (id) => {
    const { client, deleteDeliveryTask } = this.props;
    await deleteDeliveryTask(client, id);
  };

  editTask = async (id) => {
    const { client, editDeliveryTask } = this.props;
    await editDeliveryTask(client, id);
    this.props.history.push(`/monitorings/${id}/edit`);
  };

  render() {
    return (
      <DeliveryTasksView
        deliveryTasks={this.props.deliveryTasks && this.props.deliveryTasks}
        deleteTask={this.deleteTask.bind(this)}
        editTask={this.editTask.bind(this)}
      />
    );
  }
}

DeliveryTasks.propTypes = {};

const mapStateToProps = ({ taskDelivery }) => ({
  deliveryTasks: taskDelivery.deliveryTasks,
  editTaskResponse: taskDelivery.editTaskResponse,
});

const mapDispatchToProps = dispatch => ({
  getDeliveryTasks: (client) => dispatch(getDeliveryTasks(client)),
  deleteDeliveryTask: (client, id) => dispatch(deleteDeliveryTask(client, id)),
  editDeliveryTask: (client, id) => dispatch(editDeliveryTask(client, id)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withAppHOC(withConnect(DeliveryTasks));
