/**
 *
 * Customer container class
 *
 */

// Packages
import React from "react";
import { message } from "antd";
import { connect } from "react-redux";

// Components
import CustomerView from "../../components/CustomerView";

// HOCS
import withUserHOC from "../../hocs/withUserHOC";

// Actions
import { getCompanies } from "../../ducks/firm/action";
import { getCustomers, deleteCustomer } from "../../ducks/customer/action";

/* eslint-disable react/prefer-stateless-function */
class Customer extends React.Component {

  async componentDidMount() {
    try {
      const { client, getCustomers, getCompanies } = this.props;
      await getCustomers(client, localStorage.userId);
      await getCompanies(client, { userId: localStorage.userId });
    } catch (err) {
      message.error(err.message);
    }
  };

  deleteCustomerHandler = async (customerId) => {
    try {
      const { client, deleteCustomer } = this.props;
      await deleteCustomer(client, { customerId, currentUserId: localStorage.userId });
    } catch (err) {
      message.error(err.message);
    }
  };

  editCustomerHandler = (customerId) => {
    this.props.history.push(`/customers/${customerId}/edit`);
  };

  render() {
    return (
      <CustomerView
        {...this.props}
        deleteCustomerHandler={this.deleteCustomerHandler.bind(this)}
        editCustomerHandler={this.editCustomerHandler.bind(this)}
      />
    );
  }
}

Customer.propTypes = {};

const mapStateToProps = ({ user, firm, customer }) => ({
  users: user.users,
  currentUser: user.currentUser,
  companies: firm.companies,
  customers: customer.customers
});

const mapDispatchToProps = dispatch => ({
  getCustomers: (client, userId) => dispatch(getCustomers(client, userId)),
  getCompanies: (client, data) => dispatch(getCompanies(client, data)),
  deleteCustomer: (client, data) =>
    dispatch(deleteCustomer(client, data)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withUserHOC(withConnect(Customer));
