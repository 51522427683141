export const PHONE_NUMBER_REGEX = {
  regex: /^[+]\d{1}[0-9]{3}[0-9]{3}[0-9]{4}$/,
  regexMessage: "Invalid Phone Number (+123456789101)"
};

export const VERIFICATION_CODE_REGEX = {
  regex: /^[0-9]{5}$/,
  regexMessage: "Verification code must be of 5 digits!"
};

export const EMAIL_REGEX = {
  regex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  regexMessage: "Invalid email address!"
};

export const NAME_REGEX = {
  regex: /^[a-zA-Z]{1,10} [a-zA-Z]{1,10}$/,
  regexMessage: "Invalid Name (format: Pilar Zarate)"
};

export const POSTAL_CODE_REGEX = {
   regex: /^[0-9]{3,6}$/,
  regexMessage: "Postal code must be numeric and of 3-6 length!"
};

export const COMMENTS_REGEX = {
  regex: /^.{0,150}$/,
  regexMessage: "Comment should not be more than 150 characters",
};

export const SENSOR_DESCRIPTION_REGEX = {
  regex: /^.{0,150}$/,
  regexMessage: "Sensor description should not be more than 150 characters",
};

export const NUMBER_REGEX = {  
  regex: /^^(0|[1-9][0-9]*)$/,
  regexMessage: "Phone number should be numeric!"
}

export const ALPHA_NUMERIC_REGIX = {
  regex: /^[_A-z0-9]*((-|\s)*[_A-z0-9])*$/,
  regexMessage: "Special characters are not allowed in filtering!"
}

