/**
 *
 * UserView component class
 *
 */
// Packages
import React from "react";
import { Row, Col, Button, Select, Input, Icon, Tooltip, Popconfirm, Divider, message } from "antd";
import { Table } from 'reactstrap';
import _ from "lodash";

// Componemts
import EmptyData from "../EmptyData";

// Utils -> Roles
import { ROLES } from "../../utils/roles";

// styles
import "./customer-view.css";

const { Option } = Select;

/* eslint-disable react/prefer-stateless-function */
class CustomerView extends React.Component {

  state = {
    columnHeadings: ['Customer Name', 'Email', 'Company', 'Monitoring Type', 'Min Temp', 'Max Temp', 'Actions'],
    columnKeys: ['name', 'email', 'company', 'monitoring_type', 'min_temp', 'max_temp'],
  };

  render() {
  const { companies, customers, currentUser } = this.props;
  const { columnHeadings, columnKeys } = this.state;

  return (
    <Row id="CustomerView">
      <Row className="users-container">
        <Col lg={20} md={16} xs={12}>
          <h2>Customers</h2>
        </Col>
        <Col lg={4} md={8} xs={12}>
          {currentUser && (currentUser.role === ROLES.SUPER_ADMIN ||
            currentUser.role === ROLES.ADMIN) && (
              <Tooltip placement="bottom" title={'Add Customer'}>
                <Button
                  className="add-company-button"
                  onClick={() => this.props.history.push('/customers/add')}
                >
                  <Icon type="plus-circle" />
                </Button>
              </Tooltip>
            )}
        </Col>
      </Row>
      <Divider />
      <Row className="users-table">
        {customers && customers.length === 0 ? (
          <EmptyData />
        ) : (
            <Table responsive>
              <thead>
                <tr>
                  {_.map(columnHeadings, (heading, i) => (
                    <th key={i}>{heading}</th>
                  ))
                  }
                </tr>
              </thead>
              {customers &&
                <tbody>
                  {customers.length > 0 && customers.map(record => (
                    <tr key={record.id}>
                      {
                        columnKeys.map(key => {
                          const comp = companies && companies.find(c => c.id && c.id === record.company_id ? c : null);
                          return (
                            <td>{
                              key === "company" ? (
                                record.company.name
                              )
                                :

                                key === "min_temp" || key === "max_temp" ?
                                  `${record[key]} °C`
                                  :
                                    record[key]
                            }</td>
                          )
                        })
                      }
                      <td>
                        <Tooltip placement="bottom" title={"Edit Customer"}>
                          <Icon
                            type="edit"
                            onClick={() => this.props.editCustomerHandler(record.id)}
                          />
                        </Tooltip>
                        <Divider type="vertical" />
                        <Tooltip placement="bottom" title={"Delete Customer"}>
                          <Popconfirm
                            title="Are you sure delete this customer?"
                            onConfirm={() => this.props.deleteCustomerHandler(record.id)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Icon
                              type="delete"
                            />
                          </Popconfirm>
                        </Tooltip>
                      </td>
                    </tr>
                  ))}
                </tbody>
              }
            </Table>
          )}
      </Row>
    </Row>
  );
}
}

CustomerView.propTypes = {};

export default CustomerView;
