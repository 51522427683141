/**
 *
 * SensorInfo container class
 *
 */
// Packages
import React from "react";
import { message } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Components
import SensorInfoView from "../../components/SensoInfoView";

// HOCS
import withUserHOC from "../../hocs/withUserHOC";

// Actions
import { getSensor, updateSensorInfo, getSensorLogs, changeSensorCompany } from "../../ducks/sensor/action";
import { getCompanies } from '../../ducks/firm/action';

/* eslint-disable react/prefer-stateless-function */
class SensorInfo extends React.Component {
  async componentDidMount() {
    try {
      const { match: { params: { id } }, getSensor, client, getCompanies } = this.props;
      await getSensor(client, id);
      await getCompanies(client, { userId: localStorage.userId });
    } catch (err) {

    }
  }

  sensorUpdateHandler = async (key, value) => {
    try {
      const { client, updateSensorInfo, match: { params: { id } } } = this.props;
      await updateSensorInfo(client, id, key, value);
    } catch (err) {
      message.error(err.message);
    }
  };

  render() {
    return (
      <SensorInfoView
        {...this.props}
        sensorUpdateHandler={this.sensorUpdateHandler.bind(this)}
      />
    );
  }
}

SensorInfo.propTypes = {};

const mapStateToProps = ({ sensor, firm }) => ({
  sensor: sensor.sensor,
  sensorLogs: sensor.sensorLogs,
  logsLoading: sensor.logsLoading,
  isReserved: sensor.isReserved,
  monitoringId: sensor.monitoringId,
  monitoringName: sensor.monitoringName,
  companies: firm.companies
});

const mapDispatchToProps = dispatch => ({
  getSensor: (client, id) => dispatch(getSensor(client, id)),
  updateSensorInfo: (client, sensorId, key, value) => 
    dispatch(updateSensorInfo(client, sensorId, key, value)),
  getSensorLogs: (client, data) => dispatch(getSensorLogs(client, data)),
  getCompanies: (client, data) => dispatch(getCompanies(client, data)),
  changeSensorCompany: (client, data) => dispatch(changeSensorCompany(client, data))
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withUserHOC(withConnect(SensorInfo));
