/**
 *
 * GatewayView component class
 *
 */

// Packages
import React from "react";
import { Col, Row, Modal, Input, Switch, Button, Form, Popover, Checkbox, Tooltip, Popconfirm, Icon, Divider } from "antd";
import _ from 'lodash';
import { Table } from 'reactstrap';
import moment from 'moment-timezone';
import capitalize from 'capitalize';
import { FaCircle } from "react-icons/fa";

// Components
import EmptyData from "../EmptyData";

// Utils -> Data
import { GATEWAYS_COLUMNS } from "../../utils/columns";

// styles
import "./gateway-view.css";

/* eslint-disable react/prefer-stateless-function */
class GatewayView extends React.Component {

  state = {
    visible: false,
    checked: true,
    current: 1,
    firmId: '',
    currentGatewayId: '',
    modal: false,
    columnKeys: ['is_active', 'mac', 'company', 'last_battery', 'location', 'last_location', 'updatedAt'],
    fieldsOrderArr: ['asc', 'asc', 'asc', 'asc', 'asc', 'asc'],
  };

  showModal = (gatewayId) => {
    this.setState({
      currentGatewayId: gatewayId,
      modal: true,
    });
  };

  handleSelect = (rowId, companyId) => {
    this.setState({ [`${rowId}-1`]: companyId });
    this.props.changeGatewayCompanyHandler(rowId, companyId);
  };

  handleCancel = (e) => {
    this.setState({
      modal: false,
    });
  }

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  };

  handleCheckbox = (title, key, index) => {
    const { headings, keys } = this.props;

    if (headings.includes(title)) {
      const index = headings.indexOf(title);
      const keyIndex = keys.indexOf(key);
      headings.splice(index, 1);
      keys.splice(keyIndex, 1);
      this.props.saveColumnsHandler(headings, keys);
    } else {
      headings.splice(index, 0, title);
      keys.splice(index, 0, key);
      this.props.saveColumnsHandler(headings, keys);
    }

    this.setState({
      visible: !this.state.visible
    });
  };

  onChange = (page) => {
    this.setState({ current: page });
    this.props.reduxPageHandler(page);
  };

  headingHandler = (key) => {
    const { columnKeys, fieldsOrderArr } = this.state;
    const { gatewaysResponse } = this.props;
    let index = null, order = null, sortedArr = [];
    index = columnKeys.indexOf(key);
    order = fieldsOrderArr[index];
    order === 'asc' ? fieldsOrderArr[index] = 'desc' : fieldsOrderArr[index] = 'asc';
    sortedArr = _.orderBy(gatewaysResponse.gateways, [key], [order === 'asc' ? 'desc' : 'asc']);
    if (key === 'company') {
      sortedArr = _.orderBy(gatewaysResponse.gateways, ['company.name'], [order === 'asc' ? 'desc' : 'asc']);
    }
    this.props.columnSortingHandler(sortedArr);
  };

  handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      this.props.searchHandler(e.target.value);
    }
    if (e.target.value.length === 0) {
      this.props.searchHandler(e.target.value);
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.changeGatewayPasswordHandler(this.state.currentGatewayId, values);
        this.props.form.resetFields();
        this.setState({
          modal: false
        });
      }
    });
  };

  render() {
    const { gatewaysResponse, headings, keys } = this.props;
    const times = ['updatedAt'];

    return (
      <Row id="GatewayView">
        <Row className="gateway-name" gutter={25}>
          <Col lg={8} md={20} sm={16} xs={24} className=" field-holder">
            <Input
              className="gateway-input"
              addonBefore={'MAC'}
              onKeyUp={(e) => this.handleKeyUp(e, 'mac_name')}
            />
          </Col>
          <Col lg={16} md={4} sm={8} xs={24} className="col-btn-holder">
            <Popover
              content={<ColumnPopover handleCheckbox={this.handleCheckbox} columns={headings} />}
              trigger="click"
              visible={this.state.visible}
              onVisibleChange={this.handleVisibleChange}
            >
              <Button className="column-button">Columns</Button>
            </Popover>
          </Col>
        </Row>
        <Row>
          {gatewaysResponse && gatewaysResponse.gateways && gatewaysResponse.gateways.length === 0 ? (
            <EmptyData />
          ) : (
              <Table responsive>
                <thead>
                  <tr>
                    {_.map(headings, (heading, index) => (
                      <th key={index} onClick={() => this.headingHandler(keys[index])}>
                        <Row>
                          {heading}
                          {heading !== 'Actions' && (
                            <img
                              src={require('../../assets/images/sort-solid.svg')}
                              className="sorting-icon"
                            />
                          )}
                        </Row>
                      </th>
                    ))}
                  </tr>
                </thead>
                {gatewaysResponse && gatewaysResponse.gateways &&
                  <tbody>
                    {_.map(gatewaysResponse.gateways, (record) => (
                      <tr
                        key={record.id}
                      >
                        {keys.map((key, i) => {
                          if (key === 'is_active') {
                            if (record.is_active) {
                              return (
                                <Tooltip key={i} placement="bottom" title={'Active'}>
                                  <td
                                   onClick={() => this.props.history.push(`/gateway/${record.id}`)}
                                  >
                                    <FaCircle fill={"#78b829"} style={{ fontSize: 24 }} />
                                  </td>
                                </Tooltip>
                              )
                            } else if (!record.is_active) {
                              return (
                                <Tooltip key={i} placement="bottom" title={'Non-Active'}>
                                  <td
                                    onClick={() => this.props.history.push(`/gateway/${record.id}`)}
                                  >
                                    <FaCircle className="circle-scheduled" fill={"#fff"} style={{ fontSize: 24 }} />
                                  </td>
                                </Tooltip>
                              )
                            }
                          } else if (key === 'company') {
                            return (
                              <td 
                                key={i}
                                onClick={() => this.props.history.push(`/gateway/${record.id}`)}
                              >
                                {record[key] && record[key].name ? capitalize(record[key].name) : null}
                              </td>
                            )
                          } else if (key === 'location') {
                            return (
                              <td 
                                key={i}
                                onClick={() => this.props.history.push(`/gateway/${record.id}`)}
                                >
                                {record[key] && record[key].location_id ? record[key].location_id : null}
                              </td>
                            )
                          } else if (times.includes(key)) {
                            return <td key={i} onClick={() => this.props.history.push(`/gateway/${record.id}`)}>{record[key] ? moment(record[key]).tz("Europe/Berlin").format("DD-MM-YY HH:mm") : null}</td>
                          } else {
                            return <td key={i} onClick={() => this.props.history.push(`/gateway/${record.id}`)}>{record[key]}</td>
                          }
                        })}
                        <td>
                          <Tooltip placement="bottom" title={"Reset Gateway Password"}>
                            <Icon
                              type="lock"
                              onClick={() => this.showModal(record.id)}
                            />
                          </Tooltip>
                          <Divider type="vertical" />
                          <Tooltip placement="bottom" title={"Delete Gateway"}>
                            <Popconfirm
                              title="Are you sure delete this gateway?"
                              onConfirm={() => this.props.deleteGatewayHandler(record.id)}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Icon type="delete" />
                            </Popconfirm>
                          </Tooltip>
                          <Divider type="vertical" />
                          <Tooltip placement="bottom" title={`${record.is_muted === true ? 'Unmute Gateway' : 'Mute Gateway'}`}>
                            <Switch checked={record && record.is_muted ? record.is_muted : false} onChange={(checked) => this.props.muteGatewayHandler(record.id, checked)} />
                          </Tooltip>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                }
              </Table>
            )}
        </Row>
        <Modal
          title="Reset Gateway Password"
          visible={this.state.modal}
          onCancel={this.handleCancel}
          footer={[<div />, <div />]}
        >
          <Form layout="inline" onSubmit={this.handleSubmit}>
            <Form.Item>
              {this.props.form.getFieldDecorator("password", {
                rules: [{ required: true, message: "Password is required" }]
              })(<Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Password" />)}
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </Row>
    );
  }
}

GatewayView.propTypes = {};

export default Form.create()(GatewayView);

const ColumnPopover = ({ handleCheckbox, columns }) => (
  <div>
    {GATEWAYS_COLUMNS.map((column, i) => (
      <Row key={i}>
        <Checkbox
          checked={columns.includes(column.title) ? true : false}
          onChange={() => handleCheckbox(column.title, column.dataIndex, i)}
        >
          {column.title}
        </Checkbox>
      </Row>
    ))}
  </div>
);  
