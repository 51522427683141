/**
 *
 * AddUserCard component class
 *
 */
// Packages
import React from "react";
import { Row, Divider, Form, Icon, Card, Input, Button, Select } from "antd";
import PropTypes from "prop-types";

// styles
import "./add-user-card.css";
import { compose } from "react-apollo";

// Utils -> Data
import { ROLES } from "../../utils/roles";

const { Option } = Select;
const { TextArea } = Input;

/* eslint-disable react/prefer-stateless-function */
class AddUserCard extends React.Component {
  state = {
    role: '',
    company: '',
  };

  componentDidMount() {
    const { user, match: { params } } = this.props;
    if (params.id) {
      this.setValues(user);
    }
  };

  componentWillReceiveProps(props) {
    this.setUser(props);
  };

  setUser = (props) => {
    const { user, match: { params } } = props;
    if (params.id && props.user !== this.props.user) {
      this.setValues(user);
    }
  }

  setValues = (user) => {
    const { form } = this.props;
    const filteredUser = {};
    if (user) {
      filteredUser.email = user.email;
      filteredUser.role = user.role;
      filteredUser.companyName = user.company.name;
    }
    form.setFieldsValue(filteredUser);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (this.props.match.params.id) {
          const userId = this.props.match.params.id;
          this.props.updateReduxHandler(values, this.state, userId);
        } else {
          this.props.reduxHandler(values);
        }
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { companies, currentUser } = this.props;

    return (
      <Row id="AddUserCard">
        <Card>
          <h2>Send Invitation</h2>
          <Divider />
          <Form onSubmit={this.handleSubmit} className="login-form">
            <Form.Item>
              {getFieldDecorator('email', {
                rules: [{ required: true, message: 'Email is required!' }],
              })(
                <Input prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />} type="email" placeholder="Email" />
              )}
            </Form.Item>
            <div className="roll-holder">
              <Icon className="roll-icon" type="user" />
              <Form.Item>
                {getFieldDecorator('role', {
                  rules: [{
                    required: true, message: 'Role is required!',
                  }]
                })(
                  <Select placeholder="Please select role" className="role-dropdown">
                    <Option value="Admin">Admin</Option>
                    <Option value="Driver">Driver</Option>
                    <Option value="User">User</Option>
                  </Select>
                )}
              </Form.Item>
            </div>
            {currentUser && currentUser.role === ROLES.SUPER_ADMIN && (
              <div className="roll-holder">
                <Icon className="roll-icon" type="bank" />
                <Form.Item>
                  {getFieldDecorator('companyName', {
                    rules: [{
                      required: true, message: 'company Name is required!',
                    }]
                  })(
                    <Select placeholder="Select Company" className="company-dropdown">
                      {companies && companies.map((company) => (
                        <Option value={company.id}>{company.name}</Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </div>
            )}
            {currentUser && currentUser.role === ROLES.ADMIN && (
              <div className="roll-holder">
                <Icon className="roll-icon" type="bank" />
                <Form.Item>
                  {getFieldDecorator('companyName', {
                    rules: [{
                      required: true, message: 'company Name is required!',
                    }]
                  })(
                    <Select placeholder="Select Company" className="company-dropdown">
                      <Option value={currentUser.company_id && currentUser.company_id}>{currentUser.company && currentUser.company.name}</Option>
                    </Select>
                  )}
                </Form.Item>
              </div>
            )}
            <Form.Item>
              <Button type="primary" htmlType="submit" className="add-form-button">
                {this.props.match.params.id ? 'Update User' : 'Send Invitation'}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Row>
    );
  }
}

AddUserCard.propTypes = {};

export default Form.create()(AddUserCard);
