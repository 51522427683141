/*
 *
 * user actionTypes
 *
 */

export const LOGIN_USER_ACTION = "LOGIN_USER_ACTION";
export const GET_USERS_ACTION = "GET_USERS_ACTION";
export const GET_USERS_FILTERED_RESULT_ACTION = "GET_USERS_FILTERED_RESULT_ACTION";
export const DELETE_USER_ACTION = "DELETE_USER_ACTION";
export const GET_SPECIFIC_USER_ACTION = "GET_SPECIFIC_USER_ACTION";
export const UPDATE_USER_ACTION = "UPDATE_USER_ACTION";
export const FORGOT_USER_PASSWORD_ACTION = "FORGOT_USER_PASSWORD_ACTION";
export const RESET_USER_PASSWORD_ACTION = "RESET_USER_PASSWORD_ACTION";
export const CHANGE_USER_PASSWORD_ACTION = "CHANGE_USER_PASSWORD_ACTION";
export const CHANGE_USER_ROLE_ACTION = "CHANGE_USER_ROLE_ACTION";
export const LOGOUT_USER = "LOGOUT_USER";
