/**
 *
 * AddUser container class
 *
 */
// Packages
import React from "react";
import { message } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Components
import AddUserCard from "../../components/AddUserCard";

// HOCS
import withUserHOC from "../../hocs/withUserHOC";

// Actions
import { getCompanies, addUserToCompany } from "../../ducks/firm/action";
import { getSpecificUser, updateUser } from "../../ducks/user/action";

/* eslint-disable react/prefer-stateless-function */
class AddUser extends React.Component {

  async componentDidMount() {
    try {
      const { client, getCompanies, match: { params }, getSpecificUser } = this.props;
      await getCompanies(client);
      if (params.id) {
        await getSpecificUser(client, params.id);
      }
    } catch (err) {
      message.error(err.message);
    }
  };

  reduxHandler = async (values) => {
    try {
      const { client, addUserToCompany } = this.props;
      await addUserToCompany(client, values);
      message.success('User added to company successfully!');
      this.props.history.push('/users');
    } catch (err) {
      message.error(err.message);
    }
  };

  updateReduxHandler = async (values) => {
    try {
      const { client, match: { params }, updateUser, history } = this.props;
      await updateUser(client, params.id, values, this.props.user.company_id);
      message.success("User updated successfully!");
      history.push('/users');
    } catch (err) {
      console.log(err);
    }
  };


  render() {
    return (
      <AddUserCard
        {...this.props}
        reduxHandler={this.reduxHandler.bind(this)}
        updateReduxHandler={this.updateReduxHandler.bind(this)}
      />
    );
  }
}

AddUser.propTypes = {};

const mapStateToProps = ({ user, firm }) => ({
  companies: firm.companies,
  user: user.user,
  currentUser: user.currentUser,
  // company: gateway.company,
});

const mapDispatchToProps = dispatch => ({
  getCompanies: (client) => dispatch(getCompanies(client)),
  addUserToCompany: (client, values) =>
    dispatch(addUserToCompany(client, values)),
  getSpecificUser: (client, userId) =>
    dispatch(getSpecificUser(client, userId)),
  updateUser: (client, userId, values, companyId) =>
    dispatch(updateUser(client, userId, values, companyId)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withUserHOC(withConnect(AddUser));
