/*
 *
 * gateway reducer
 *
 */

import {
  GET_GAYEWAYS_ACTION,
  DELETE_GATEWAY_ACTION,
  GET_FILTERED_GATEWAYS_ACTION,
  RESET_GATWAY_PASSWORD_ACTION,
  GET_SPECIFIC_GATEWAY_ACTION,
  UPDATE_GATEWAY_ACTION,
  CHANGE_GATEWAY_ROLE_ACTION,
  GET_GATEWAYS_FILTERED_ACTION,
  MUTE_GATEWAY_ACTION,
  COLUMN_BASED_GATEWAY_SORTING_ACTION
} from "./actionTypes";

export const initialState = {
  gateways: [],
  getGateways: {},
};

const gatewayReducer = (state = initialState, { type, payload }) => {
  switch (type) {

    case COLUMN_BASED_GATEWAY_SORTING_ACTION:
      return { ...state, getGateways: payload };   

    case `${GET_GAYEWAYS_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${GET_GAYEWAYS_ACTION}_FULFILLED`:
      return { loading: false, ...state, getGateways: payload };
    case `${GET_GAYEWAYS_ACTION}_REJECTED`:
      return { loading: false, ...state, getGateways: payload };

    case `${DELETE_GATEWAY_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${DELETE_GATEWAY_ACTION}_FULFILLED`:
      return { loading: false, ...state, getGateways: payload };
    case `${DELETE_GATEWAY_ACTION}_REJECTED`:
      return { loading: false, ...state, getGateways: payload };

    case `${GET_FILTERED_GATEWAYS_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${GET_FILTERED_GATEWAYS_ACTION}_FULFILLED`:
      return { loading: false, ...state, getGateways: payload };
    case `${GET_FILTERED_GATEWAYS_ACTION}_REJECTED`:
      return { loading: false, ...state, getGateways: payload };

    case `${RESET_GATWAY_PASSWORD_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${RESET_GATWAY_PASSWORD_ACTION}_FULFILLED`:
      return { loading: false, ...state, resetPasswordResponse: payload };
    case `${RESET_GATWAY_PASSWORD_ACTION}_REJECTED`:
      return { loading: false, ...state, resetPasswordResponse: payload };

    case `${GET_SPECIFIC_GATEWAY_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${GET_SPECIFIC_GATEWAY_ACTION}_FULFILLED`:
      return { loading: false, ...state, ...payload };
    case `${GET_SPECIFIC_GATEWAY_ACTION}_REJECTED`:
      return { loading: false, ...state, ...payload };

    case `${UPDATE_GATEWAY_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${UPDATE_GATEWAY_ACTION}_FULFILLED`:
      return { loading: false, ...state, updateGatewayResponse: payload };
    case `${UPDATE_GATEWAY_ACTION}_REJECTED`:
      return { loading: false, ...state, updateGatewayResponse: payload };

    case `${CHANGE_GATEWAY_ROLE_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${CHANGE_GATEWAY_ROLE_ACTION}_FULFILLED`:
      return { loading: false, ...state, users: payload };
    case `${CHANGE_GATEWAY_ROLE_ACTION}_REJECTED`:
      return { loading: false, ...state, users: payload };

    case `${GET_GATEWAYS_FILTERED_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${GET_GATEWAYS_FILTERED_ACTION}_FULFILLED`:
      return { loading: false, ...state, users: payload };
    case `${GET_GATEWAYS_FILTERED_ACTION}_REJECTED`:
      return { loading: false, ...state, users: payload };

    case `${MUTE_GATEWAY_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${MUTE_GATEWAY_ACTION}_FULFILLED`:
      return { loading: false, ...state, getGateways: payload };
    case `${MUTE_GATEWAY_ACTION}_REJECTED`:
      return { loading: false, ...state, getGateways: payload };

    default:
      return state;
  }
};

export default gatewayReducer;
