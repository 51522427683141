/**
 *
 * MyForm stateless component
 *
 */

// Packages
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, Form, Input, Row, Icon, Button, Col } from "antd";
import { NavLink } from "react-router-dom";

// Components
import ForgotPassword from "../ForgotPassword";

// styles
import "./my-form.css";

const FormItem = Form.Item;

class MyForm extends Component {

  state = {
    visible: false,
    isForgotPassword: undefined,
  }

  showModal = () => {
    this.setState({
      visible: true,
      isForgotPassword: true,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      isForgotPassword: undefined,
    });
  };

  closeModal = () => {
    this.setState({
      visible: false,
      isForgotPassword: undefined,
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { reduxHandler, state } = this.props;

    if (!this.state.isForgotPassword) {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          if (state) {
            values["state"] = state;
          }
          reduxHandler(values);
          this.props.form.resetFields();
        }
      });
    }
  };

  render() {
    const {
      headerText,
      fields,
      form,
      inputLabel,
      btn,
      footerUrl,
      FooterText,
      text,
      state,
      setPage,
      forgotPassword,
      isRecoverPassword
    } = this.props;

    return (
      <Row id="MyForm">
        <Row type="flex" justify="center" align="middle">
          {headerText && <p className="header-text">{headerText}</p>}
          <Card id="FormCard">
            <Col lg={12} className="left-sec-bg">
              <span className="overlay"></span>
              <div className="left-side-cap">
                <h2>Monitoring Solutions</h2>
              </div>
            </Col>
            <Col lg={12} className="form-right-bg">
              {!isRecoverPassword ?
                <h1 className="welcome-text">Welcome to tsenso</h1>
                :
                <h1 className="welcome-text">Password Recovery</h1>
              }
              <p className="cap-center">Please use your credentials to login.</p>
              {text && <p className="form-heading">{text}</p>}
              <Form className="login-form" onSubmit={this.handleSubmit.bind(this)}>
                {inputLabel && <p className="input-label"> {inputLabel} </p>}
                {fields.map((field, i) => {
                  const rules = [
                    { required: true, message: `${field.placeholder} is required!` }
                  ];
                  if (field.regex) {
                    rules.push({
                      pattern: field.regex,
                      message: field.regexMessage
                    });
                  }
                  return (
                    <FormItem key={i}>
                      {form.getFieldDecorator(field.key, { rules })(
                        <Input
                          type={field.type}
                          placeholder={field.placeholder}
                          prefix={
                            field.icon && (
                              <Icon
                                type={field.icon}
                                style={{ color: "rgba(0,0,0,.25)" }}
                              />
                            )
                          }
                        />
                      )}
                    </FormItem>
                  );
                })}
                {forgotPassword && (
                  <div>
                    <a onClick={this.showModal} className="forgot-password">Forgot Password?</a>
                    <ForgotPassword
                      forgotPasswordHandler={this.props.forgotPasswordHandler}
                      visible={this.state.visible}
                      handleCancel={this.handleCancel}
                      handleSubmit={this.handleSubmit}
                      closeModal={this.closeModal}
                    />
                  </div>
                )}

                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  disabled={hasErrors(form.getFieldsError())}
                >
                  {btn.label}
                </Button>
                <Row className="footer-link">
                  {footerUrl && footerUrl.path && (
                    <NavLink to={footerUrl.path}>{footerUrl.label}</NavLink>
                  )}

                  {footerUrl && !footerUrl.path && setPage && (
                    <p onClick={() => setPage(state)}>{footerUrl.label}</p>
                  )}
                </Row>
                <Row className="footer-text">{FooterText}</Row>
              </Form>
            </Col>
          </Card>
        </Row>
      </Row>
    );
  }
}

MyForm.propTypes = {
  headerText: PropTypes.string,
  inputLabel: PropTypes.string,
  fields: PropTypes.array.isRequired,
  btn: PropTypes.shape({
    label: PropTypes.string.isRequired
  }).isRequired,
  footerUrl: PropTypes.shape({
    label: PropTypes.string.isRequired,
    path: PropTypes.string,
    callback: PropTypes.func
  }),
  reduxHandler: PropTypes.func.isRequired,
  FooterText: PropTypes.element
};

const hasErrors = fieldsError => {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
};

export default Form.create()(MyForm);
