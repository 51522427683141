/*
 *
 * sensor reducer
 *
 */

import {
  GET_SENSORS_COUNT_ACTION,
  GET_SENSORS_ACTION,
  GET_SENSOR_ACTION,
  GET_FILTERED_SENSORS_ACTION,
  UPDATE_SENSOR_INFO_ACTION,
  GET_ACTIVE_SENSORS_ACTION,
  COLUMN_BASED_SENSOR_SORTING_ACTION,
  GET_SENSOR_LOGS_ACTION,
  CHANGE_SENSOR_COMPANY_ACTION
} from "./actionTypes";

export const initialState = {
  sensors: [],
  sensor: {},
  sensorsCount: 0,
  activeSensors: [],
  sensorLogs: undefined,
  logsLoading: false
};

const sensorReducer = (state = initialState, { type, payload }) => {
  switch (type) {

    case COLUMN_BASED_SENSOR_SORTING_ACTION:
      return { ...state, sensors: payload };

    case `${GET_SENSORS_COUNT_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${GET_SENSORS_COUNT_ACTION}_FULFILLED`:
      return { loading: false, ...state, sensorsCount: payload.count };
    case `${GET_SENSORS_COUNT_ACTION}_REJECTED`:
      return { loading: false, ...state, sensorsCount: payload.count };

    case `${GET_ACTIVE_SENSORS_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${GET_ACTIVE_SENSORS_ACTION}_FULFILLED`:
      return { loading: false, ...state, activeSensors: payload };
    case `${GET_ACTIVE_SENSORS_ACTION}_REJECTED`:
      return { loading: false, ...state, activeSensors: payload };

    case `${GET_SENSOR_LOGS_ACTION}_PENDING`:
      return { loading: true, ...state, logsLoading: true };
    case `${GET_SENSOR_LOGS_ACTION}_FULFILLED`:
      return { loading: false, ...state, logsLoading: false, sensorLogs: payload };
    case `${GET_SENSOR_LOGS_ACTION}_REJECTED`:
      return { loading: false, ...state, logsLoading: false, sensorLogs: payload };

    case `${GET_SENSORS_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${GET_SENSORS_ACTION}_FULFILLED`:
      return { loading: false, ...state, sensors: payload };
    case `${GET_SENSORS_ACTION}_REJECTED`:
      return { loading: false, ...state, sensors: payload };
  
    case `${GET_SENSOR_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${GET_SENSOR_ACTION}_FULFILLED`:
      return { loading: false, ...state, sensor: payload.sensor, isReserved: payload.isReserved, monitoringId: payload.monitoringId, monitoringName: payload.monitoringName };
    case `${GET_SENSOR_ACTION}_REJECTED`:
      return { loading: false, ...state, sensor: payload.sensor, isReserved: payload.isReserved, monitoringId: payload.monitoringId };

    case `${CHANGE_SENSOR_COMPANY_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${CHANGE_SENSOR_COMPANY_ACTION}_FULFILLED`:
      return { loading: false, ...state, sensor: payload };
    case `${CHANGE_SENSOR_COMPANY_ACTION}_REJECTED`:
      return { loading: false, ...state, sensor: payload };
  
    case `${UPDATE_SENSOR_INFO_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${UPDATE_SENSOR_INFO_ACTION}_FULFILLED`:
      return { loading: false, ...state, sensor: payload };
    case `${UPDATE_SENSOR_INFO_ACTION}_REJECTED`:
      return { loading: false, ...state, sensor: payload };

    case `${GET_FILTERED_SENSORS_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${GET_FILTERED_SENSORS_ACTION}_FULFILLED`:
      return { loading: false, ...state, sensors: payload, sensorsCount: payload.length };
    case `${GET_FILTERED_SENSORS_ACTION}_REJECTED`:
      return { loading: false, ...state, sensors: payload, sensorsCount: payload.length };

    default:
      return state;
  }
};

export default sensorReducer;
