/**
 *
 * GatewayShowPageView component class
 *
 */

// Packages
import React from "react";
import $ from "jquery";
import { Row, Col, Card, Form, Select, Input, Button, Icon, Spin } from "antd";

// styles
import "./gateway-show-page-view.css";

const { Option } = Select;

/* eslint-disable react/prefer-stateless-function */
class GatewayShowPageView extends React.Component {

  state = {
    file: null,
    mac: '',
    company: '',
    location_id: '',
    location_details: '',
    positon_details: '',
    position_photo: false,
  };

  componentDidMount() {
    const { gateway, match: { params } } = this.props;
    if (params.id) {
      this.setValues(gateway);
    }
  };

  componentWillReceiveProps(props) {
    this.setGateway(props);
  }

  setGateway = (props) => {
    const { gateway, match: { params } } = props;
    if (params.id && props.gateway !== this.props.gateway) {
      this.setValues(gateway);
    }
  }

  setValues = (gateway) => {
    const { form } = this.props;
    const filteredGateway = {};

    if (gateway) {
      filteredGateway.company = gateway.company.name;
      filteredGateway.location = gateway.location && gateway.location.location_id ? gateway.location.location_id : null;
      filteredGateway.location_details = gateway.location_details ? gateway.location_details : null;
      filteredGateway.positon_details = gateway.positon_details ? gateway.positon_details : null;
      filteredGateway.position_photo = gateway.position_photo ? gateway.position_photo : null;
    }
    form.setFieldsValue(filteredGateway);
  };

  handleChange = (e, key) => {
    this.setState({ [key]: e.target.value });
  };

  handleLocationChange = (value) => {
    this.setState({ location_id: value });
    this.props.updateGatewayHandler('location_id', value);

  };

  handleSubmit = (e, key) => {
    this.props.updateGatewayHandler(key, this.state[key]);
  };

  handleFileUpload = () => {
    this.setState({ position_photo: true });
    const file = document.getElementById('image').files[0];
    if (file) {
      this.setState({ file: file.name });
      this.props.imageUploader(file);
    }
  };

  handleCompanyChange = (value) => {
    this.setState({ company: value });
    this.props.changeCompany(value);
    this.props.updateGatewayHandler('company_id', value);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.reduxHandler(values);
      }
    });
  };

  render() {
    const { companies, locations, gateway, uploading, uploadedFile, currentUser } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <Row id="GatewayShowPageView">
        <Card title={`Gateway: ${gateway && gateway.mac && gateway.mac}`}>
          <Form onSubmit={this.handleSubmit}>
            <Row>
              <Col lg={3} className="label">
                <label> Company </label>
              </Col>
              <Col lg={21}>
                {currentUser && currentUser.role === "Super Admin" ?
                  <Form.Item>
                    {getFieldDecorator('company', {
                      rules: [{ required: true, message: 'Company is required!' }],
                    })(
                      <Select
                        placeholder="Select Company"
                        className="company-dropdown"
                        onChange={this.handleCompanyChange}
                      >
                        {companies && companies.map((company) => (
                          <Option value={company.id}>{company.name}</Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                  :
                  <div>{gateway && gateway.company ? gateway.company.name : null}</div>
                }
              </Col>
            </Row>
            <Row>
              <Col lg={3} className="label">
                <label> Location </label>
              </Col>
              <Col lg={21}>
                <Form.Item>
                  {getFieldDecorator('location')(
                    <Select
                      placeholder="Select Location ID"
                      className="location-dropdown"
                    >
                      {locations && locations.map((location) => (
                        <Option value={location.id}>{location.location_id}</Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col lg={3} className="label">
                <label> Location Details </label>
              </Col>
              <Col lg={21}>
                <Form.Item>
                  {getFieldDecorator('location_details')(
                    <Input />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col lg={3} className="label">
                <label> Position Details </label>
              </Col>
              <Col lg={21}>
                <Form.Item>
                  {getFieldDecorator('positon_details')(
                    <Input />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row className="upload-btn-holder">
              <Button className="photo-btn" onClick={() => $('#image').click()}>
                <Input
                  id="image"
                  type="file"
                  onChange={() => this.handleFileUpload()}
                  hidden
                  accept="image/png, image/jpeg"
                />
                <Icon type="upload" /> Click to Upload
            </Button>
            </Row>
            <Row className="uploading-image" type="flex" justify="center" align="middle">
              {uploading && (
                <Spin className="file-loader" />
              )}
            </Row>
            {this.state.position_photo && (
              <Row type="flex" justify="center" align="middle">
              {!uploading && uploadedFile && uploadedFile.url &&
                <img className="gateway-photo" src={uploadedFile.url} />
              }
            </Row>
            )}
            {!this.state.position_photo && (
               <Row type="flex" justify="center" align="middle">
                {gateway && gateway.position_photo &&
                  <img className="gateway-photo" src={gateway.position_photo} />
                }
              </Row>
            )}
            <Row type="flex" justify="center" align="middle">
              <Button
                htmlType="submit"
                className="submit-btn"
              >
                Save
              </Button>
            </Row>
          </Form>
        </Card>
      </Row>
    );
  }
}

GatewayShowPageView.propTypes = {};

export default Form.create()(GatewayShowPageView);
