import React from 'react';
import ReactDOM from 'react-dom';
import {
  ApolloProvider,
} from 'react-apollo';
import { ApolloClient, HttpLink, InMemoryCache } from 'apollo-client-preset';
import { setContext } from 'apollo-link-context';
import { Provider } from 'react-redux';

import moment from 'moment-timezone';

import store from './store';
import App from './App';

import 'bootstrap/dist/css/bootstrap.css';
import 'antd/dist/antd.css';
import './index.css';

import registerServiceWorker from './registerServiceWorker';

moment.tz.setDefault("Europe/Berlin");

const publicRequests = ['login', 'register'];
const authLink = setContext((req, context) => {
  if (publicRequests.includes(req.operationName)) return context;
  return {
    headers: {
      ...context.headers,
      authorization: localStorage.token || ""
    }
  };
});

const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_URL });

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultFetchPolicy: 'network-only'
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <App />
    </Provider>
  </ApolloProvider>,
  document.getElementById('root')
);

// ToDo: enable service workers back, but we need to disable index caching before
// registerServiceWorker();
