/**
 *
 * AddLocationCard component class
 *
 */

// Packages
import React from "react";
import { Row, Card, Divider, Form, Input, Icon, Select, Button, message } from "antd";
import AutoComplete from 'react-google-autocomplete';
import PropTypes from "prop-types";

// styles
import "./add-location-card.css";

// Utils -> Regex
import {COMMENTS_REGEX } from "../../utils/regex";

const { Option } = Select;
const { TextArea } = Input;

/* eslint-disable react/prefer-stateless-function */
class AddLocationCard extends React.Component {

  state = {
    location: {},
  };

  componentDidMount() {
    const { location, match: { params } } = this.props;
    if (params.id) {
      this.setValues(location);
    }
  };

  componentWillReceiveProps(props) {
    this.setUser(props);
  };

  setUser = (props) => {
    const { location, match: { params } } = props;
    if (params.id && props.location !== this.props.location) {
      this.setValues(location);
    }
  }

  setValues = (location) => {

    const { form } = this.props;
    const filteredLocation = {};

    if(location) {
      filteredLocation.location_id = location.location_id;
      filteredLocation.company = location.company.name;
      filteredLocation.address = location.address;
      filteredLocation.details = location.details;
    }
    form.setFieldsValue(filteredLocation);
  };

  handlePlaceChange = (place) => {
    if (place) {
      this.setState({
        location: {
          address: place.formatted_address,
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng()
        }
      });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.location = this.state.location;
        if (this.props.match.params.id) {
          this.props.updateReduxHandler(values);
        } else {
          this.props.reduxHandler(values);
        }
        this.props.form.resetFields();
      }
    });
  };

  render() {

    const { getFieldDecorator } = this.props.form;
    const { companies } = this.props;
    return (
      <Row id="AddLocationCard">
        <Card>
        <h2>{this.props.match.params.id ? "Edit Location" : "Add Location"}</h2>
          <Divider />
          <Form onSubmit={this.handleSubmit} className="login-form">
            <Form.Item>
              {getFieldDecorator('location_id', {
                rules: [{ required: true, message: 'Location Id is required!' }],
              })(
                <Input prefix={<Icon type="table" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Location ID" />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('company', {
                rules: [{ required: true, message: 'Company is required!' }],
              })(
                <Select placeholder="Select Company" className="company-dropdown">
                  {companies && companies.map((company) => (
                    <Option value={company.id}>{company.name}</Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item className="address-field">
            {getFieldDecorator('address', {
                rules: [{ required: true, message: 'Address is required!' }],
              })(
                <Input prefix={<Icon type="environment" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Address" />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('details', {
                 rules: [
                  { pattern: new RegExp(COMMENTS_REGEX.regex), message: COMMENTS_REGEX.regexMessage }
                ],
              })(
                <TextArea id="details" placeholder="Location Details" rows={10}></TextArea>
              )}
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="add-form-button">
                {this.props.match.params.id ? 'Update Location' : 'Add Location'}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Row>
    )
  }
}

AddLocationCard.propTypes = {};

export default Form.create()(AddLocationCard);
