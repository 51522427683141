/*
 *
 * monitoring actionTypes
 *
 */

export const GET_MONITORINGS_ACTION = "GET_MONITORINGS_ACTION";
export const GET_MONITORINGS_COUNT_ACTION = "GET_MONITORINGS_COUNT_ACTION";
export const START_MONITORING_ACTION = "START_MONITORING_ACTION";
export const GET_MONITORING_ACTION = "GET_MONITORING_ACTION";
export const START_SCHEDULED_MONITORING_ACTION = "START_SCHEDULED_MONITORING_ACTION";
export const SET_FIRST_STEPPER_VALUES_ACTION = "SET_FIRST_STEPPER_VALUES_ACTION";
export const END_MONITORING_ACTION = "END_MONITORING_ACTION";
export const UPDATE_MONITORING_WAREN_ACTION = "UPDATE_MONITORING_WAREN_ACTION";
export const GET_FILTERED_MONITORINGS_ACTION = "GET_FILTERED_MONITORINGS_ACTION";
export const GET_MONITORINGS_FILTERED_RESULTS_ACTION = "GET_MONITORINGS_FILTERED_RESULTS_ACTION";
export const GET_SELECT_MONITORINGS_FILTERED_RESULTS_ACTION = "GET_SELECT_MONITORINGS_FILTERED_RESULTS_ACTION";
export const UPDATE_MONITORING_INFO_ACTION = "UPDATE_MONITORING_INFO_ACTION";
export const UPDATE_MONITORING_DATES_ACTION = "UPDATE_MONITORING_DATES_ACTION";
export const COLUMN_BASED_MONITORING_SORTING_ACTION = "COLUMN_BASED_MONITORING_SORTING_ACTION";
