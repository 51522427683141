// components
import Login from './containers/Login';
import DashboardMonitorings from './containers/DashboardMonitorings';
import DeliveryTasks from './containers/DeliveryTasks';
import ResetPassword from './containers/ResetPassword';
import Products from './containers/Products';
import CreateDeliveryTask from './containers/CreateDeliveryTask';
import User from './containers/User';
import Customer from './containers/Customer';
import AddUser from './containers/AddUser';
import AddCustomer from './containers/AddCustomer';
import Company from './containers/Company';
import AddCompany from './containers/AddCompany';
import Devices from './containers/Devices';
import SensorInfo from './containers/SensorInfo';
import MonitoringWaren from './containers/MonitoringWaren';
import Sensors from './containers/Sensors';
import MonitoringWizard from './containers/MonitoringWizard';
import Gateway from './containers/Gateway';
import Location from './containers/Location';
import AddLocation from './containers/AddLocation';
import GatewayShowPage from './containers/GatewayShowPage';
import About from './containers/About';

export default [
  {
    path: '/',
    component: Login,
    exact: true,
  },
  {
    path: '/monitorings',
    component: DashboardMonitorings,
    exact: true,
  },
  {
    path: '/sensors',
    component: Sensors,
    exact: true,
  },
  {
    path: '/delivery-tasks',
    component: DeliveryTasks,
    exact: true,
  },
  {
    path: '/products',
    component: Products,
    exact: true,
  },
  {
    path: '/delivery-task/create',
    component: CreateDeliveryTask,
    exact: true,
  },
  {
    path: '/monitorings/:id/edit',
    component: CreateDeliveryTask,
    exact: true,
  },
  {
    path: '/monitoring/:id',
    component: MonitoringWaren,
    exact: true,
  },
  {
    path: '/users',
    component: User,
    exact: true,
  },
  {
    path: '/users/add-user',
    component: AddUser,
    exact: true,
  },
  {
    path: '/customers',
    component: Customer,
    exact: true,
  },
  {
    path: '/customers/add',
    component: AddCustomer,
    exact: true,
  },
  {
    path: '/customers/:id/edit',
    component: AddCustomer,
    exact: true,
  },
  {
    path: '/companies',
    component: Company,
    exact: true,
  },
   {
    path: '/gateways',
    component: Gateway,
    exact: true,
  },
  {
    path: '/companies/add-company',
    component: AddCompany,
    exact: true,
  },
  {
    path: '/companies/:id/edit',
    component: AddCompany,
    exact: true,
  },
  {
    path: '/users/:id/edit',
    component: AddUser,
    exact: true,
  },
  {
    path: '/devices',
    component: Devices,
    exact: true,
  },
  {
    path: '/sensor/:id',
    component: SensorInfo,
    exact: true,
  },
  {
    path: '/reset-password/:token',
    component: ResetPassword,
    exact: true,
  },
  {
    path: '/start-monitoring',
    component: MonitoringWizard,
    exact: true,
  },
  {
    path: '/start-monitoring-wizard/:id',
    component: MonitoringWizard,
    exact: true,
  },
  {
    path: '/locations',
    component: Location,
    exact: true,
  },
  {
    path: '/location/add-location',
    component: AddLocation,
    exact: true,
  },
  {
    path: '/locations/:id/edit',
    component: AddLocation,
    exact: true,
  },
  {
    path: '/Gateway/:id',
    component: GatewayShowPage,
    exact: true,
  },
  {
    path: '/about',
    component: About,
    exact: true,
  },
];
