/**
 *
 * About container class
 *
 */

 // Packages
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Components
import AboutCard from "../../components/AboutCard";

// HOCS
import withUserHOC from "../../hocs/withUserHOC";

/* eslint-disable react/prefer-stateless-function */
class About extends React.Component {
  render() {
    return (
      <AboutCard
        {...this.props}
      />
    );
  }
}

About.propTypes = {};

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser
});

const mapDispatchToProps = dispatch => ({});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withUserHOC(withConnect(About));
