/**
 *
 * DashboardDevices container class
 *
 */

// Packages
import React from "react";
import { message } from "antd";
import { connect } from "react-redux";

// Components
import SensorsView from "../../components/SensorsView";

// HOCS 
import withUserHOC from "../../hocs/withUserHOC";

// Actions
import { getSensors, getSensorsCount, getFilteredSensorsResults, columnBasedSensorSorting } from "../../ducks/sensor/action";
import { saveColumns } from "../../ducks/filter/action";

/* eslint-disable react/prefer-stateless-function */
class Sensors extends React.Component {

  async componentDidMount() {
    try {
      const { client, getSensors, getSensorsCount } = this.props;
      await getSensorsCount(client, { userId: localStorage.userId });
      await getSensors(client, { userId: localStorage.userId, pageNo: 1 });
    } catch (err) {
      message.error(err.message);
    }
  };

  saveColumnsHandler = (columnsHeadings, columnsKeys) => {
    const { saveColumns } = this.props;
    saveColumns('sensors', columnsHeadings, columnsKeys);
  };

  columnSortingHandler = (sortedArr) => {
    const { columnBasedSensorSorting } = this.props;
    columnBasedSensorSorting(sortedArr);
  };

  searchHandler = async (key, keyword, mode) => {
    try {
      const { client, getFilteredSensorsResults } = this.props;
      await getFilteredSensorsResults(client, key, keyword, mode, localStorage.userId);
    } catch (err) {
      message.error(err);
    }
  };

  reduxPageHandler = async (pageNo) => { 
    try {
      await this.props.getSensors(this.props.client, { userId: localStorage.userId, pageNo });
    } catch(err) {
      message.error(err);
    }
  };

  render() {
    return (
      <SensorsView
        {...this.props}
        reduxPageHandler={this.reduxPageHandler.bind(this)}
        searchHandler={this.searchHandler.bind(this)}
        saveColumnsHandler={this.saveColumnsHandler.bind(this)}
        columnSortingHandler={this.columnSortingHandler.bind(this)}
      />
    );
  }
}

Sensors.propTypes = {};

const mapStateToProps = ({ sensor, filter }) => ({
  sensors: sensor.sensors,
  sensorsCount: sensor.sensorsCount,
  headings: filter.sensorHeadings,
  keys: filter.sensorKeys
});

const mapDispatchToProps = dispatch => ({
  getSensorsCount: (client, data) => dispatch(getSensorsCount(client, data)),
  getSensors: (client, data) => dispatch(getSensors(client, data)),
  getFilteredSensorsResults: (client, key, keyword, mode, userId) =>
    dispatch(getFilteredSensorsResults(client, key, keyword, mode, userId)),
  saveColumns: (key, columnsHeadings, columnsKeys) => 
    dispatch(saveColumns(key, columnsHeadings, columnsKeys)),
  columnBasedSensorSorting: (sortedArr) => dispatch(columnBasedSensorSorting(sortedArr)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withUserHOC(withConnect(Sensors));
