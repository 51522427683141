/*
*
* monitoring actions
*
*/
// Action Types
import {
  GET_MONITORINGS_ACTION,
  START_MONITORING_ACTION,
  GET_MONITORING_ACTION,
  GET_MONITORINGS_COUNT_ACTION,
  START_SCHEDULED_MONITORING_ACTION,
  SET_FIRST_STEPPER_VALUES_ACTION,
  END_MONITORING_ACTION,
  UPDATE_MONITORING_WAREN_ACTION,
  GET_FILTERED_MONITORINGS_ACTION,
  GET_MONITORINGS_FILTERED_RESULTS_ACTION,
  GET_SELECT_MONITORINGS_FILTERED_RESULTS_ACTION,
  UPDATE_MONITORING_INFO_ACTION,
  UPDATE_MONITORING_DATES_ACTION,
  COLUMN_BASED_MONITORING_SORTING_ACTION
} from "./actionTypes";

// Queries

import {
  GET_MONITORINGS,
  START_MONITORING,
  GET_MONITORING,
  GET_MONITORINGS_COUNT,
  START_SCHEDULED_MONITORING,
  UPDATE_MONITORING_WAREN,
  GET_FILTERED_MONITORINGS,
  END_MONITORING,
  GET_MONITORINGS_FILTERED_RESULTS,
  GET_SELECT_MONITORINGS_FILTERED_RESULTS,
  UPDATE_MONITORING_INFO,
  UPDATE_MONITORING_DATES
} from "../../queries/monitoring";


export const setFirstStepValues = (values) => ({
  type: SET_FIRST_STEPPER_VALUES_ACTION,
  payload: values,
});

export const columnBasedMonitoringSorting = (sortedArr) => ({
  type: COLUMN_BASED_MONITORING_SORTING_ACTION,
  payload: sortedArr,
});

export const getMonitoringsCount = (client, data) => ({
  type: GET_MONITORINGS_COUNT_ACTION,
  async payload() {
    try {

      const result = await client.query({
        query: GET_MONITORINGS_COUNT,
        variables: data,
        fetchPolicy: "no-cache",
      });

      return result.data.getMonitoringsCount;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const getMonitorings = (client, data) => ({
  type: GET_MONITORINGS_ACTION,
  async payload() {
    try {
      const result = await client.query({
        query: GET_MONITORINGS,
        variables: data,
        fetchPolicy: "network-only",
      });

      return result.data.getMonitorings;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const getFilteredMonitoringsResults = (client, keyword, isActive, isCritical, assetType, userId) => ({
  type: GET_FILTERED_MONITORINGS_ACTION,
  async payload() {
    try {

      const result = await client.query({
        query: GET_FILTERED_MONITORINGS,
        variables: {
          keyword: keyword ? keyword : null,
          isActive: isActive,
          isCritical: isCritical,
          assetType: assetType,
          userId
        },
        fetchPolicy: "no-cache",
      });

      return result.data.getFilteredMonitoringsResults;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const getMonitoring = (client, id) => ({
  type: GET_MONITORING_ACTION,
  async payload() {
    try {

      const result = await client.query({
        query: GET_MONITORING,
        variables: {
          id: id
        },
        fetchPolicy: "no-cache",
      });

      return result.data.getMonitoring;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const startMonitoring = (client, data) => ({
  type: START_MONITORING_ACTION,
  async payload() {
    try {

      const result = await client.mutate({
        mutation: START_MONITORING,
        variables: data,
        fetchPolicy: "no-cache",
      });

      const res = result.data.startMonitoring;
      return res.success ? res : Promise.reject(res);

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const endMonitoring = (client, id) => ({
  type: END_MONITORING_ACTION,
  async payload() {
    try {

      const result = await client.mutate({
        mutation: END_MONITORING,
        variables: {
          id: id
        },
        fetchPolicy: "no-cache",
      });

      return result.data.endMonitoring;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});


export const updateMonitoringWaren = (client, id, values) => ({
  type: UPDATE_MONITORING_WAREN_ACTION,
  async payload() {
    try {

      const result = await client.mutate({
        mutation: UPDATE_MONITORING_WAREN,
        variables: {
          id: id,
          min: values.min,
          max: values.max,
          email: values.email,
          phone_number: values.phone_number,
        },
        fetchPolicy: "no-cache",
      });

      return result.data.updateMonitoringWaren;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const startScheduledMonitoring = (client, id) => ({
  type: START_SCHEDULED_MONITORING_ACTION,
  async payload() {
    try {

      const result = await client.mutate({
        mutation: START_SCHEDULED_MONITORING,
        variables: {
          id
        },
        fetchPolicy: "no-cache",
      });

      return result.data.startScheduledMonitoring;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const getFilteredMonitoringResults = (client, key, value) => ({
  type: GET_MONITORINGS_FILTERED_RESULTS_ACTION,
  async payload() {
    try {

      const result = await client.query({
        query: GET_MONITORINGS_FILTERED_RESULTS,
        variables: {
          key: key,
          value: value,
        },
        fetchPolicy: "no-cache",
      });

      return result.data.getFilteredMonitoringResults;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const getSelectFilteredMonitoringResults = (client, leftSelectKey, centerSelectKey, rightSelectKey) => ({
  type: GET_SELECT_MONITORINGS_FILTERED_RESULTS_ACTION,
  async payload() {
    try {

      const result = await client.query({
        query: GET_SELECT_MONITORINGS_FILTERED_RESULTS,
        variables: {
          leftKey: leftSelectKey,
          centerKey: centerSelectKey,
          rightKey: rightSelectKey, 
        },
        fetchPolicy: "no-cache",
      });

      return result.data.getSelectFilteredMonitoringResults;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const updateMonitoringInfo = (client, id, key, value) => ({
  type: UPDATE_MONITORING_INFO_ACTION,
  async payload() {
    try {

      const result = await client.mutate({
        mutation: UPDATE_MONITORING_INFO,
        variables: {
          id: id,
          key: key,
          value: value
        },
        fetchPolicy: "no-cache",
      });

      return result.data.updateMonitoringInfo;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const updateMonitoringDates = (client, id, key, dates) => ({
  type: UPDATE_MONITORING_DATES_ACTION,
  async payload() {
    try {

      const result = await client.mutate({
        mutation: UPDATE_MONITORING_DATES,
        variables: {
          id: id,
          key: key,
          dates: dates
        },
        fetchPolicy: "no-cache",
      });

      return result.data.updateMonitoringDates;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

