/**
 *
 * AddCustomer container class
 *
 */
// Packages
import React from "react";
import { message } from "antd";
import { connect } from "react-redux";

// Components
import AddCustomerCard from "../../components/AddCustomerCard";

// HOCS
import withUserHOC from "../../hocs/withUserHOC";

// Actions
import { getCompanies } from "../../ducks/firm/action";
import { getSpecificCustomer, createOrUpdateCustomer } from "../../ducks/customer/action";

/* eslint-disable react/prefer-stateless-function */
class AddCustomer extends React.Component {

  async componentDidMount() {
    try {
      const { client, getCompanies, match: { params }, getSpecificCustomer } = this.props;
      await getCompanies(client, { userId: localStorage.userId });
      if (params.id) {
        await getSpecificCustomer(client, parseInt(params.id));
      }
    } catch (err) {
      message.error(err.message);
    }
  };

  reduxHandler = async (values) => {
    try {
      const { client, match: { params }, createOrUpdateCustomer, history } = this.props;

      if (params.id) {
        await createOrUpdateCustomer(client, { customerId: params.id, ...values });
        message.success("Customer updated successfully!");
      } else {
        await createOrUpdateCustomer(client, values);
        message.success("Customer added successfully!");
      }

      history.push('/customers');
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    return (
      <AddCustomerCard
        {...this.props}
        reduxHandler={this.reduxHandler.bind(this)}
      />
    );
  }
}

AddCustomer.propTypes = {};

const mapStateToProps = ({ user, customer, firm }) => ({
  companies: firm.companies,
  customer: customer.customer,
  currentUser: user.currentUser,
});

const mapDispatchToProps = dispatch => ({
  getCompanies: (client, data) => dispatch(getCompanies(client, data)),
  getSpecificCustomer: (client, data) =>
    dispatch(getSpecificCustomer(client, data)),
    createOrUpdateCustomer: (client, data) =>
    dispatch(createOrUpdateCustomer(client, data)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withUserHOC(withConnect(AddCustomer));
