/**
 *
 * Login container class
 *
 */

// Packages
import React from "react";
import { message } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Components
import MyForm from "../../components/MyForm/index";

// HOCS
import withUserHOC from "../../hocs/withUserHOC";

// Actions
import { userLogin, forgotUserPassword } from "../../ducks/user/action";

// Utils -> Regex
import { EMAIL_REGEX } from "../../utils/regex";

/* eslint-disable react/prefer-stateless-function */
class Login extends React.Component {

  componentDidMount() {
    const userId = localStorage.getItem('userId');
    if (userId) {
      this.props.history.push('/monitorings');
    }
  };

  forgotPasswordHandler = async (values) => {
    try {
      const { client, forgotUserPassword } = this.props;
      await forgotUserPassword(client, values);
      message.success('Check your email to recover password.');
    } catch (err) {
      message.error(err.message);
    }
  };

  reduxHandler = async (values) => {
    try {
      const { history, client, userLogin } = this.props;
      await userLogin(client, values);
      localStorage.setItem('userId', this.props.currentUser.id);
      localStorage.setItem('userEmail', this.props.currentUser.email);
      localStorage.setItem('userRole', this.props.currentUser.role);
      history.push('/monitorings');
    } catch (err) {
      message.error(err.message);
    }
  };

  render() {

    return (
      <MyForm
        fields={[
          {
            type: "email",
            key: "email",
            placeholder: "Email",
            icon: "mail",
            ...EMAIL_REGEX
          },
          {
            type: "password",
            key: "password",
            placeholder: "Password",
            icon: "lock",

          }
        ]}
        forgotPassword={true}
        btn={{ label: "Login" }}
        reduxHandler={this.reduxHandler.bind(this)}
        forgotPasswordHandler={this.forgotPasswordHandler.bind(this)}
      />
    );
  }
}

Login.propTypes = {};

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
});

const mapDispatchToProps = dispatch => ({
  userLogin: (client, values) => dispatch(userLogin(client, values)),
  forgotUserPassword: (client, values) => dispatch(forgotUserPassword(client, values)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withUserHOC(withConnect(Login));
