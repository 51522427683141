/*
 *
 * deliveryTask reducer
 *
 */

import {
  CREATE_DELIVERY_TASK_ACTION,
  GET_DELIVERY_BOXES_ACTION,
  GET_DELIVERY_TASKS_ACTION,
  DELETE_DELIVERY_BOX_ACTION,
  DELETE_DELIVERY_TASK_ACTION,
  EDIT_DELIVERY_TASK_ACTION,
  UPDATE_DELIVERY_TASK_ACTION
} from "./actionTypes";

export const initialState = {
  deliveryBoxes: [],
  deliveryTasks: []
};

const deliveryTaskReducer = (state = initialState, { type, payload }) => {
  switch (type) {

    case `${CREATE_DELIVERY_TASK_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${CREATE_DELIVERY_TASK_ACTION}_FULFILLED`:
      return { loading: false, ...state, deliveryBoxes: payload };
    case `${CREATE_DELIVERY_TASK_ACTION}_REJECTED`:
      return { loading: false, ...state, deliveryBoxes: payload };

    case `${GET_DELIVERY_BOXES_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${GET_DELIVERY_BOXES_ACTION}_FULFILLED`:
      return { loading: false, ...state, deliveryBoxes: payload };
    case `${GET_DELIVERY_BOXES_ACTION}_REJECTED`:
      return { loading: false, ...state, deliveryBoxes: payload };

    case `${GET_DELIVERY_TASKS_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${GET_DELIVERY_TASKS_ACTION}_FULFILLED`:
      return { loading: false, ...state, deliveryTasks: payload };
    case `${GET_DELIVERY_TASKS_ACTION}_REJECTED`:
      return { loading: false, ...state, deliveryTasks: payload };

    case `${DELETE_DELIVERY_BOX_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${DELETE_DELIVERY_BOX_ACTION}_FULFILLED`:
      return { loading: false, ...state, deliveryBoxes: payload };
    case `${DELETE_DELIVERY_BOX_ACTION}_REJECTED`:
      return { loading: false, ...state, deliveryBoxes: payload };

    case `${DELETE_DELIVERY_TASK_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${DELETE_DELIVERY_TASK_ACTION}_FULFILLED`:
      return { loading: false, ...state, deliveryTasks: payload };
    case `${DELETE_DELIVERY_TASK_ACTION}_REJECTED`:
      return { loading: false, ...state, deliveryTasks: payload };

    case `${EDIT_DELIVERY_TASK_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${EDIT_DELIVERY_TASK_ACTION}_FULFILLED`:
      return { loading: false, ...state, editTaskResponse: payload };
    case `${EDIT_DELIVERY_TASK_ACTION}_REJECTED`:
      return { loading: false, ...state, editTaskResponse: payload };

    case `${UPDATE_DELIVERY_TASK_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${UPDATE_DELIVERY_TASK_ACTION}_FULFILLED`:
      return { loading: false, ...state, updateTaskResponse: payload };
    case `${UPDATE_DELIVERY_TASK_ACTION}_REJECTED`:
      return { loading: false, ...state, updateTaskResponse: payload };


    default:
      return state;
  }
};

export default deliveryTaskReducer;
