/**
 *
 * DevicesView component class
 *
 */
// Packages
import React from "react";
import { Row, Col, Button, Input, Icon, Table, Select } from "antd";

// Components
import EmptyData from "../EmptyData";

// Utils -> Data
import Column from "antd/lib/table/Column";

// styles
import "./devices-view.css";

const { Option } = Select;
const { TextArea } = Input;

/* eslint-disable react/prefer-stateless-function */
class DevicesView extends React.Component {

  state = {
    textarea: '',
    devices: [],
  };

  handleChange = (e) => {
    this.setState({ textarea: e.target.value });
  };

  handleTextarea = (deviceId) => {
    this.props.reduxTextareaHandler(deviceId, this.state.textarea);
  };

  render() {
    const { users } = this.props;
    const { devices } = this.state;
    return (
      <Row id="DevicesView">
        <h2>Devices Management</h2>
        <Row className="dev-holder" gutter={25}>
          <Col lg={8} md={20} sm={16} xs={24} >
            <Input
              className="device-input"
              addonBefore={<Icon type="filter" />}
              placeholder="Filter"
            />
          </Col>
          <Col lg={16} md={4} sm={8} xs={24} className="col-btn-holder">
            <Button
              className="column-button"
            >
              Columns
            </Button>
          </Col>
        </Row>
        <Row className="devices-table-container">

          {devices && devices.length === 0 ? (
            <EmptyData />
          ) : (
              <Table dataSource={devices} scroll={{ y: 440 }}>
                <Column
                  title='Sensor'
                  key='name'
                  render={(record) => (
                    <a href={`/device/${record.id}`}>{record.name}</a>
                  )}
                />
                <Column
                  title='MAC'
                  dataIndex='mac'
                  key='mac'
                />
                <Column
                  title='Owner Group'
                  dataIndex='owner_group'
                  key='owner_group'
                />

                <Column
                  title='Battery'
                  dataIndex='battery'
                  key='battery'
                />
                <Column
                  title='Last Update'
                  dataIndex='last_update'
                  key='last_update'
                />
                <Column
                  title='Monitoring'
                  key='monitoring'
                  render={(record) => {
                    if (record.monitoring === true) {
                      return (<Icon type="check" />)
                    } else {
                      return (<Icon type="stop" />)
                    }
                  }}
                />
                <Column
                  title='Change User'
                  key='users'
                  render={() => (
                    <Select
                      style={{ width: 200 }}
                      showSearch={true}
                      placeholder="Select user"
                    >
                      {users && users.map((user, key) => (
                        <Option key={key} value={user.id} title={user.username}>{user.username}</Option>
                      ))}
                    </Select>
                  )}
                />
                <Column
                  title='Note'
                  key='note'
                  render={(record) => (
                    <span>
                      <TextArea id="textarea" onChange={this.handleChange} rows={6}>{record.note}</TextArea>
                      <Row type="flex" justify="center">
                        <Button
                          className="save-button"
                          onClick={() => this.handleTextarea(record.id)}
                        >
                          Save
                  </Button>
                      </Row>
                    </span>
                  )}
                />
              </Table>
            )}
        </Row>
      </Row>
    );
  }
}

DevicesView.propTypes = {};

export default DevicesView;
