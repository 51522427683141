/**
 *
 * MyFooter stateless component
 *
 */

import React from "react";
import moment from 'moment-timezone';
import PropTypes from "prop-types";
import { Layout } from 'antd';

// styles
import "./my-footer.css";

const { Footer } = Layout;

const MyFooter = props => (
  <Footer id="MyFooter">
    <p>Copyright ©{new Date().getFullYear()}. All Rights Reserved.</p>
  </Footer>
);

MyFooter.propTypes = {};

export default MyFooter;
