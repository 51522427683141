/*
 *
 * location reducer
 *
 */

import { 
  ADD_LOCATION_ACTION,
  GET_LOCATIONS_ACTION,
  DELETE_LOCATION_ACTION,
  GET_SPECIFIC_LOCATION_ACTION,
  UPDATE_LOCATION_ACTION,
  GET_LOCATIONS_AGAINT_SPECIFIC_COMPANY_ACTION
} from "./actionTypes";

export const initialState = {
  addLocationResponse: {},
  getLocations: {},
  updateLocationResponse: {},
  locations: [],
};

const locationReducer = (state = initialState, { type, payload }) => {
  switch (type) {

    case `${ADD_LOCATION_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${ADD_LOCATION_ACTION}_FULFILLED`:
      return { loading: false, ...state, addLocationResponse: payload };
    case `${ADD_LOCATION_ACTION}_REJECTED`:
      return { loading: false, ...state, addLocationResponse: payload };

    case `${GET_LOCATIONS_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${GET_LOCATIONS_ACTION}_FULFILLED`:
      return { loading: false, ...state, getLocations: payload };
    case `${GET_LOCATIONS_ACTION}_REJECTED`:
      return { loading: false, ...state, getLocations: payload };

    case `${DELETE_LOCATION_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${DELETE_LOCATION_ACTION}_FULFILLED`:
      return { loading: false, ...state, getLocations: payload };
    case `${DELETE_LOCATION_ACTION}_REJECTED`:
      return { loading: false, ...state, getLocations: payload }

    case `${GET_SPECIFIC_LOCATION_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${GET_SPECIFIC_LOCATION_ACTION}_FULFILLED`:
      return { loading: false, ...state, ...payload };
    case `${GET_SPECIFIC_LOCATION_ACTION}_REJECTED`:
      return { loading: false, ...state, ...payload }

    case `${UPDATE_LOCATION_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${UPDATE_LOCATION_ACTION}_FULFILLED`:
      return { loading: false, ...state, updateLocationResponse: payload };
    case `${UPDATE_LOCATION_ACTION}_REJECTED`:
      return { loading: false, ...state, updateLocationResponse: payload }

    case `${GET_LOCATIONS_AGAINT_SPECIFIC_COMPANY_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${GET_LOCATIONS_AGAINT_SPECIFIC_COMPANY_ACTION}_FULFILLED`:
      return { loading: false, ...state, locations: payload };
    case `${GET_LOCATIONS_AGAINT_SPECIFIC_COMPANY_ACTION}_REJECTED`:
      return { loading: false, ...state, locations: payload }

    default:
      return state;
  }
};

export default locationReducer;
