import gql from "graphql-tag";

const firm = `
  name
`;

const user = `
  id
  email
  role
  name
  company_id
  last_login
  company {
    ${firm}
  }
`;

export const LOGIN_USER = gql`
  query userLogin(
    $email: String!
    $password: String!    
  ) {
    userLogin(
      email: $email
      password: $password
    ) {
      success
      message
      user {
        ${user}
      }
    }
  }  
`;

export const GET_USERS = gql`
  query getUsers ($userId: Int, $role: String) {
    getUsers (userId: $userId, role: $role) {
      ${user}
    }
  } 
`;

export const FORGOT_USER_PASSWORD = gql`
  query forgotUserPassword(
    $email: String!    
  ) {
    forgotUserPassword(
      email: $email
    ) {
      success
      message
    } 
  }
`;

export const RESET_USER_PASSWORD = gql`
  mutation resetUserPassword(
    $token: String!
    $password: String!   
  ) {
    resetUserPassword(
      token: $token
      password: $password
    ) {
      success
      message
    } 
  }
`;

export const CHANGE_USER_PASSWORD = gql`
  mutation changeUserPassword(
    $userId: Int!
    $currentPassword: String!   
    $newPassword: String! 
  ) {
    changeUserPassword(
      userId: $userId
      currentPassword: $currentPassword
      newPassword: $newPassword
    ) {
      success
      message
    } 
  }
`;

export const CHANGE_USER_ROLE = gql`
  mutation changeUserRole(
    $userId: Int!
    $role: String!  
    $currentUserId: Int 
    $crrUserRole: String
  ) {
    changeUserRole(
      userId: $userId
      role: $role
      currentUserId: $currentUserId
      crrUserRole: $crrUserRole
    ) {
      ${user}
    } 
  }
`;

export const GET_USERS_FILTERED_RESULT = gql`
  query getFilteredUsers(
    $userId: Int
    $firmId: String
    $role: String
    $keyword: String
    $crrUserRole: String
  ) {
    getFilteredUsers(
      userId: $userId
      firmId: $firmId
      role: $role
      keyword: $keyword
      crrUserRole: $crrUserRole
    ) {
      ${user}
    }
  } 
`;

export const DELETE_USER = gql`
  mutation deleteUser(
    $userId: Int!
    $currentUserId: Int
    $crrUserRole: String
  ) {
    deleteUser(
      userId: $userId
      currentUserId: $currentUserId
      crrUserRole: $crrUserRole
    ) {
      ${user}
    } 
  }
`;

export const GET_SPECIFIC_USER = gql`
  query getSpecificUser(
    $userId: Int!
  ) {
    getSpecificUser(
      userId: $userId
    ) {
      success
      message
      user {
        ${user}
      } 
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser(
    $userId: Int!
    $email: String
    $role: String
    $companyId: Int
  ) {
    updateUser(
      userId: $userId
      email: $email
      role: $role
      companyId: $companyId
    ) {
      success
      message
    }
  }
`;

