/**
 *
 * DashboardMonitoringsView component class
 *
 */

// Packages
import React from "react";
import { Col, Row, Input, Button, Popover, Checkbox, Select, Tooltip, Icon } from "antd";
import { Table } from 'reactstrap';
import _ from 'lodash';
import moment from 'moment-timezone';
import { FaCircle } from "react-icons/fa";

// Components
import EmptyData from "../EmptyData";

// styles
import "./dashboard-monitorings-view.css";

// Utils -> Data
import { DASHBOARD_MONITORING_COLUMNS } from "../../utils/columns";
import { ASSET_TYPES } from "../../utils/assetTypes";

const { Option } = Select;

class DashboardMonitoringsView extends React.Component {

  state = {
    isActive: 'null',
    isCritical: 'null',
    assetType: '',
    keyword: '',
    current: 1,
    visible: false,
    checked: true,
    columnHeadings: ['', 'Monitoring ID', 'Monitoring Type', 'Monitoring Details', 'Min Temp (℃)', 'Max Temp (℃)', 'Sensor', 'Sensor Position', 'Date Started', 'Date Ended'],
    columnKeys: ['is_active', 'monitoring_id', 'monitoring_type', 'comment', 'min', 'max', 'sensor', 'sensor_position', 'started', 'ended'],
    fieldsOrderArr: ['desc', 'desc', 'desc', 'desc', 'desc', 'desc', 'desc', 'desc', 'desc', 'desc'],
  };

  componentDidMount() {
    const { savedFilters } = this.props;
    if (savedFilters && savedFilters.values) {
      this.setState({
        keyword: savedFilters.values && savedFilters.values.keyword ? savedFilters.values.keyword : '',
        isActive: savedFilters.values && savedFilters.values.isActive ? savedFilters.values.isActive : 'null',
        isCritical: savedFilters.values && savedFilters.values.isCritical ? savedFilters.values.isCritical : 'null',
        assetType: savedFilters.values && savedFilters.values.assetType ? savedFilters.values.assetType : 'All',
      });
    }
  };

  handleLeftModeChange = (value) => {
    const isActive = value;
    this.setState({ isActive });
    const { keyword, isCritical, assetType } = this.state;
    this.props.searchHandler(keyword, isActive, isCritical, assetType, localStorage.userId);
  };

  handleCenterModeChange = (value) => {
    const isCritical = value;
    this.setState({ isCritical });
    const { keyword, isActive, assetType } = this.state;
    this.props.searchHandler(keyword, isActive, isCritical, assetType, localStorage.userId);
  };

  handleAsset = (value) => {
    this.setState({ assetType: value });
    const assetType = value;
    const { keyword, isActive, isCritical } = this.state;
    this.props.searchHandler(keyword, isActive, isCritical, assetType, localStorage.userId);
  };

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  };

  handleCheckbox = (title, key, index) => {
    const { headings, keys } = this.props;

    if (headings.includes(title)) {
      const index = headings.indexOf(title);
      const keyIndex = keys.indexOf(key);
      headings.splice(index, 1);
      keys.splice(keyIndex, 1);
      this.props.saveColumnsHandler(headings, keys);
    } else {
      headings.splice(index, 0, title);
      keys.splice(index, 0, key);
      this.props.saveColumnsHandler(headings, keys);
    }

    this.setState({
      visible: !this.state.visible
    });
  };

  onChange = (page) => {
    this.setState({ current: page });
    this.props.reduxPageHandler(page);
  };

  headingHandler = (key) => {
    const { columnKeys, fieldsOrderArr } = this.state;
    const { monitorings } = this.props;
    let index = null, order = null, sortedArr = [];
    index = columnKeys.indexOf(key);
    order = fieldsOrderArr[index];
    order === 'asc' ? fieldsOrderArr[index] = 'desc' : fieldsOrderArr[index] = 'asc';
    sortedArr = _.orderBy(monitorings, [key], [order === 'asc' ? 'desc' : 'asc']);
    if(key === 'sensor') {
      sortedArr = _.orderBy(monitorings, ['sensor.name'], [order === 'asc' ? 'desc' : 'asc']);
    }
    this.props.columnSortingHandler(sortedArr);
  };

  handleChange = (e) => {
    this.setState({ keyword: e.target.value });
  };

  handleKeyUp = (e) => {
    const { keyword, isActive, isCritical, assetType } = this.state;
    if (e.keyCode === 13) {
      this.props.searchHandler(keyword, isActive, isCritical, assetType, parseInt(localStorage.userId));
    }
  };

  render() {
    const { isActive, isCritical } = this.state;
    const { monitoringsCount, savedFilters, headings, keys } = this.props;
    const times = ['started', 'ended'];

    return (
      <Row id="DashboardMonitoringsView">
        <Row className="select-holder" gutter={20}>
          <Col xs={24} md={28} lg={8}>
            <Select
              showSearch={true}
              onChange={this.handleLeftModeChange}
              value={savedFilters && savedFilters.values && savedFilters.values.isActive ? savedFilters.values.isActive : isActive}
            >
              <Option value="null">All - Planned, Active & Done</Option>
              <Option value="active">Active</Option>
              <Option value="planned">Planned</Option>
              <Option value="done">Done</Option>
            </Select>
          </Col>
          <Col xs={24} md={8} lg={8} >
            <Select 
              showSearch={true}
              onChange={this.handleCenterModeChange}
              value={savedFilters && savedFilters.values && savedFilters.values.isCritical ? savedFilters.values.isCritical : isCritical}
            >
              <Option value="null">All - OK & Critical</Option>
              <Option value="critical">Critical</Option>
              <Option value="ok">Ok</Option>
            </Select>
          </Col>
          <Col className="select-asset-holder" xs={24} md={24} lg={8} >
            <Select
              value={savedFilters && savedFilters.values && savedFilters.values.assetType ? savedFilters.values.assetType : 'All'}
              showSearch={true}
              className="asset-type-dropdown"
              onChange={this.handleAsset}
            >
              <Option value="All">All Types</Option>
              {ASSET_TYPES && ASSET_TYPES.map((type, i) => (
                <Option key={i} value={type}>{type}</Option>
              ))}
            </Select>
          </Col>
        </Row>

        <Row className="monitoring-name" gutter={25}>
          <Col lg={8} md={18} sm={16} xs={24} className=" field-holder">
            <Input
              className="monitoring-input"
              addonBefore={'Monitoring ID'}
              value={this.state.keyword}
              onChange={(e) => this.handleChange(e)}
              onKeyUp={(e) => this.handleKeyUp(e)}
            />
          </Col>
          <Col lg={16} md={6} sm={8} xs={24} className="col-btn-holder">
            <Tooltip placement="bottom" title={'Add start monitoring'}>
              <Button
                className="add-monitoring-button"
                onClick={() => this.props.history.push('/start-monitoring')}
              >
                <Icon type="plus-circle" />
              </Button>
            </Tooltip>
            <Popover
              content={<ColumnPopover handleCheckbox={this.handleCheckbox} columns={this.props.headings} />}
              trigger="click"
              visible={this.state.visible}
              onVisibleChange={this.handleVisibleChange}
            >
              <Button className="column-button">Columns</Button>
            </Popover>
          </Col>
        </Row>

        <Row>
          {this.props.monitorings && this.props.monitorings.length === 0 ? (
            <EmptyData />
          ) : (
              <Table responsive>
                <thead>
                  <tr>
                    {_.map(headings, (heading, index) => (
                      <th key={index} onClick={() => this.headingHandler(keys[index])}>
                        <Row>
                          {heading}
                          <img
                           type="filter"
                          src={require('../../assets/images/sort-solid.svg')}
                          className="sorting-icon"
                          />
                        </Row>
                      </th>
                    ))
                    }
                  </tr>
                </thead>
                {this.props.monitorings &&
                  <tbody>
                    {_.map(this.props.monitorings, (record) => (
                      <tr
                        key={record.id}
                        className={record.is_crtical === true ? "critical" : null}
                        onClick={() => this.props.history.push(`/monitoring/${record.id}`)}
                      >
                        {keys.map((key, i) => {
                          if (key === 'is_active') {
                            if (!record.is_active && !record.started && !record.ended && record.is_scheduled) {
                              return (
                                <Tooltip key={i} placement="bottom" title={'Scheduled'}>
                                  <td><FaCircle className="circle-scheduled" fill={"#fff"} /></td>
                                </Tooltip>
                              )
                            } else if (record.is_active) {
                              return (
                                <Tooltip key={i} placement="bottom" title={'Active'}>
                                  <td><FaCircle fill={"#78b829"} style={{ fontSize: 24 }} /></td>
                                </Tooltip>
                              )
                            } else if (!record.is_active && record.ended && moment(record.ended).valueOf() < moment().valueOf()) {
                              return (
                                <Tooltip key={i} placement="bottom" title={'Finished'}>
                                  <td><FaCircle fill={"#000"} style={{ fontSize: 24 }} /></td>
                                </Tooltip>
                              )
                            }
                          }
                          if (key === "sensor") {
                            return <td key={i}>{record[key].name}</td>
                          }

                          if (times.includes(key)) {
                            return <td key={i}>{record[key] ? moment(record[key]).tz("Europe/Berlin").format("DD-MM-YY HH:mm") : null}</td>
                          }

                          return <td key={i}>{record[key] && record[key]}</td>
                        })}
                      </tr>
                    ))}
                  </tbody>
                }
              </Table>
            )}
        </Row>
      </Row>
    );
  }
}

DashboardMonitoringsView.propTypes = {};

export default DashboardMonitoringsView;

const ColumnPopover = ({ handleCheckbox, columns }) => {
  return (
  <div>
    {DASHBOARD_MONITORING_COLUMNS.map((column, i) => (
      <Row key={i}>
        <Checkbox
          checked={columns.includes(column.title) ? true : false}
          onChange={() => handleCheckbox(column.title, column.dataIndex, i)}
        >
          {column.title}
        </Checkbox>
      </Row>
    ))}
  </div>
)};