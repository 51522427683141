/*
 *
 * customer reducer
 *
 */

import {
  GET_CUSTOMERS_ACTION,
  DELETE_CUSTOMER_ACTION,
  GET_SPECIFIC_CUSTOMER_ACTION,
  CREATE_OR_UPDATE_CUSTOMER_ACTION
} from "./actionTypes";

export const initialState = {
  customers: [],
};

const customerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case `${GET_CUSTOMERS_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${GET_CUSTOMERS_ACTION}_FULFILLED`:
      return { loading: false, ...state, customers: payload };
    case `${GET_CUSTOMERS_ACTION}_REJECTED`:
      return { loading: false, ...state, customers: payload };

    case `${GET_SPECIFIC_CUSTOMER_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${GET_SPECIFIC_CUSTOMER_ACTION}_FULFILLED`:
      return { loading: false, ...state, ...payload };
    case `${GET_SPECIFIC_CUSTOMER_ACTION}_REJECTED`:
      return { loading: false, ...state, ...payload };

    case `${DELETE_CUSTOMER_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${DELETE_CUSTOMER_ACTION}_FULFILLED`:
      return { loading: false, ...state, customers: payload };
    case `${DELETE_CUSTOMER_ACTION}_REJECTED`:
      return { loading: false, ...state, customers: payload };

    case `${CREATE_OR_UPDATE_CUSTOMER_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${CREATE_OR_UPDATE_CUSTOMER_ACTION}_FULFILLED`:
      return { loading: false, ...state, customerResponse: payload };
    case `${CREATE_OR_UPDATE_CUSTOMER_ACTION}_REJECTED`:
      return { loading: false, ...state, customerResponse: payload };

    default:
      return state;
  }
};

export default customerReducer;
