/*
 *
 * device actionTypes
 *
 */

export const GET_DEVICES_ACTION = "GET_DEVICES_ACTION";
export const UPDATE_DEVICE_INFO_ACTION = "UPDATE_DEVICE_INFO_ACTION";
export const GET_DEVICES_INFO_ACTION = "GET_DEVICES_INFO_ACTION";
export const GET_DEVICE_FILTERED_RESULTS_ACTION = "GET_DEVICE_FILTERED_RESULTS_ACTION";
export const GET_SELECT_DEVICES_FILTERED_RESULTS_ACTION = "GET_SELECT_DEVICES_FILTERED_RESULTS_ACTION";

