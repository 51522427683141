/*
 *
 * location actions
 *
 */

 // Action Types
import { 
  ADD_LOCATION_ACTION,
  GET_LOCATIONS_ACTION,
  DELETE_LOCATION_ACTION,
  GET_SPECIFIC_LOCATION_ACTION,
  UPDATE_LOCATION_ACTION,
  GET_LOCATIONS_AGAINT_SPECIFIC_COMPANY_ACTION
} from "./actionTypes";

// Queries
import {
  ADD_LOCATION,
  GET_LOCATIONS,
  DELETE_LOCATION,
  GET_SPECIFIC_LOCATION,
  UPDATE_LOCATION,
  GET_LOCATIONS_AGAINT_SPECIFIC_COMPANY
} from "../../queries/location";

export const addLocation = (client, values) => ({
  type: ADD_LOCATION_ACTION,
  async payload() {
    try {
      const result = await client.mutate({
        mutation: ADD_LOCATION,
        variables: {
          location_id: values.location_id,
          company_id: values.company,
          address: values.address,
          details: values.details,
        },
        fetchPolicy: "no-cache",
      });

      const res = result.data.addLocation;
      return res.success ? res : Promise.reject(res);

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const getLocations = (client, data) => ({
  type: GET_LOCATIONS_ACTION,
  async payload() {
    try {
      const result = await client.query({
        query: GET_LOCATIONS,
        variables: data,
        fetchPolicy: "no-cache",
      });

      return result.data.getLocations;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const deleteLocation = (client, locationId, pageNo) => ({
  type: DELETE_LOCATION_ACTION,
  async payload() {
    try {
      const result = await client.mutate({
        mutation: DELETE_LOCATION,
        variables: {
          locationId: locationId,
          pageNo: pageNo,
        },
        fetchPolicy: "no-cache",
      });

      return result.data.deleteLocation;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const getSpecificLocation = (client, locationId) => ({
  type: GET_SPECIFIC_LOCATION_ACTION,
  async payload() {
    try {
      const result = await client.query({
        query: GET_SPECIFIC_LOCATION,
        variables: {
          locationId: locationId,
        },
        fetchPolicy: "no-cache",
      });

      return result.data.getSpecificLocation;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const updateLocation = (client,locationId, values, companyId) => ({
  type: UPDATE_LOCATION_ACTION,
  async payload() {
    try {
      const result = await client.mutate({
        mutation: UPDATE_LOCATION,
        variables: {
          locationId: locationId,
          location_id: values.location_id,
          company_id: typeof(values.company) === 'string' ? companyId : values.company,
          address: values.location.address,
          details: values.details,
          latitude: values.location.latitude ? values.location.latitude : null,
          longitude: values.location.longitude ? values.location.longitude : null,
        },
        fetchPolicy: "no-cache",
      });

      const res = result.data.updateLocation;
      return res.success ? res : Promise.reject(res);

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const getLocationsAgainstCompany = (client, companyId) => ({
  type: GET_LOCATIONS_AGAINT_SPECIFIC_COMPANY_ACTION,
  async payload() {
    try {
      const result = await client.query({
        query: GET_LOCATIONS_AGAINT_SPECIFIC_COMPANY,
        variables: {
          companyId: companyId,
        },
        fetchPolicy: "no-cache",
      });

      return result.data.getLocationsAgainstCompany;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});
