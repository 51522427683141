/**
 *
 * ChangePassword component class
 *
 */
// Packages
import React from "react";
import PropTypes from "prop-types";
import { Row, Icon, Modal, Button, Form, Input } from 'antd';

// styles
import "./change-password.css";

/* eslint-disable react/prefer-stateless-function */
class ChangePassword extends React.Component {

  state = {
    confirmDirty: false,
  };

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('newPassword')) {
      callback('Confirm password does not match with new password yet!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirmPassword'], { force: true });
    }
    callback();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.changePasswordHandler(values);
        this.props.handleModal();
        this.props.form.resetFields();
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        className="change-password-modal"
        title="Change Password"
        visible={this.props.visible}
        onCancel={this.props.handleModal}
        footer={null}
      >
        <Form id="ChangePassword" onSubmit={this.handleSubmit} className="login-form">
          <Form.Item>
            {getFieldDecorator('currentPassword', {
              rules: [{ required: true, message: 'Current password is required!' }],
            })(
              <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Current password" />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('newPassword', {
              rules: [{ required: true, message: 'Password is required!' }, { validator: this.validateToNextPassword }],
            })(
              <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="New password" />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('confirmPassword', {
              rules: [{ required: true, message: 'Confirm password is required!' }, { validator: this.compareToFirstPassword }],
            })(
              <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Confirm new password" onBlur={this.handleConfirmBlur} />
            )}
          </Form.Item>
          <Row className="submit-button-row">
            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button">
                Submit
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Modal>
    )
  }
}

ChangePassword.propTypes = {};

export default Form.create()(ChangePassword);
