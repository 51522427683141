
import gql from "graphql-tag";

const device = `
  id
  mac
  sensor_id
  owner_group
  battery
  last_update
  monitoring
  note
`;

const deviceAndSensor = `
  id
  mac
  sensor_id
  owner_group
  battery
  last_update
  monitoring
  note
  name
`;

const deviceAndSensorAndGateway = `
  id
  mac
  device_name
  sensor_id
  sender_id
  owner_group
  battery
  last_update
  temperature
  monitoring
  note
  name
`;

const gateway = `
  id
  username
  email
  roles
`;

export const GET_DEVICES = gql`
  query getDevices {
    getDevices {
      deviceAndSensor {
        ${deviceAndSensor}
      }
      users {
        ${gateway}
      }
    }
  } 
`;

export const UPDATE_DEVICE_INFO = gql`
  mutation updateDeviceInfo (
    $deviceId: Int
    $textarea: String
  ) {
    updateDeviceInfo (
      deviceId: $deviceId
      textarea: $textarea
    ) {
      deviceAndSensor {
        ${deviceAndSensor}
      }
      users {
        ${gateway}
      }
    }
  } 
`;

export const GET_DEVICES_INFO = gql`
  query getDevicesInfo {
    getDevicesInfo {
      ${deviceAndSensorAndGateway}
    }
  } 
`;

export const GET_DEVICE_FILTERED_RESULTS = gql`
  query getFilteredDevicesResults(
    $key: String
    $value: String
  ) {
    getFilteredDevicesResults(
      key: $key
      value: $value
    ) {
      ${deviceAndSensorAndGateway}
    }
  } 
`;

export const GET_SELECT_DEVICES_FILTERED_RESULTS = gql`
  query getSelectFilteredDevicesResults(
    $key: String
  ) {
    getSelectFilteredDevicesResults(
      key: $key
    ) {
      ${deviceAndSensorAndGateway}
    }
  } 
`;