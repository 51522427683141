import { createStore, applyMiddleware, compose } from 'redux';
import promise from 'redux-promise-middleware';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { combineReducers } from 'redux';

// reducers
import UserReducer from './ducks/user/reducer';
import CustomerReducer from './ducks/customer/reducer';
import GatewayReducer from './ducks/gateway/reducer';
import TaskDeliveryReducer from './ducks/deliveryTask/reducer';
import FirmReducer from './ducks/firm/reducer';
import DeviceReducer from './ducks/device/reducer';
import MonitoringReducer from './ducks/monitoring/reducer';
import SensorReducer from './ducks/sensor/reducer';
import LocationReducer from './ducks/location/reducer';
import FilterReducer from './ducks/filter/reducer';

const middleware = [ thunk, promise() ];
let composeEnhancers;
if (process.env.NODE_ENV !== 'production') {
  middleware.push(createLogger());
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
} else {
  composeEnhancers = compose;
}

// combined reducers 
const myReducer = combineReducers({
  user: UserReducer,
  customer: CustomerReducer,
  gateway: GatewayReducer,
  taskDelivery: TaskDeliveryReducer,
  firm: FirmReducer,
  device: DeviceReducer,
  monitoring: MonitoringReducer,
  sensor: SensorReducer,
  location: LocationReducer,
  filter: FilterReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined
  }
  
  return myReducer(state, action)
};

const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(...middleware)
  )
);

export default store;
