export const PDF_FIELDS = {
  monitoring_id: 'Monitoring ID',
  monitoring_type: 'Monitoring Type',
  container_name: 'Container Name',
  container_id: 'Container ID',
  carrier: 'Carrier',
  delivery_note_no: 'Delivery Note No',
  delivery_tour: 'Delivery Tour',
  delivery_id: 'Delivery ID',
  delivery_content: 'Delivery Content',
  delivery_comment: 'Delivery Comment',
  comment: 'Comment',
  sensor_position: 'Sensor Position',
  sensor: 'Sensor',
  sensor_pos_url: 'Sensor Image',
  min: 'Minimum Temperature',
  max: 'Maximum Temperature',
  started: 'Started',
  ended: 'Ended',
};
