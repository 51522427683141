/*
 *
 * device reducer
 *
 */

import {
  GET_DEVICES_ACTION,
  UPDATE_DEVICE_INFO_ACTION,
  GET_DEVICES_INFO_ACTION,
  GET_DEVICE_FILTERED_RESULTS_ACTION,
  GET_SELECT_DEVICES_FILTERED_RESULTS_ACTION
} from "./actionTypes";

export const initialState = {
  getDevicesResponse: {},
  getDevicesInfoResponse: [],
};

const deviceReducer = (state = initialState, { type, payload }) => {
  switch (type) {

    case `${GET_DEVICES_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${GET_DEVICES_ACTION}_FULFILLED`:
      return { loading: false, ...state, getDevicesResponse: payload };
    case `${GET_DEVICES_ACTION}_REJECTED`:
      return { loading: false, ...state, getDevicesResponse: payload };

    case `${UPDATE_DEVICE_INFO_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${UPDATE_DEVICE_INFO_ACTION}_FULFILLED`:
      return { loading: false, ...state, getDevicesResponse: payload };
    case `${UPDATE_DEVICE_INFO_ACTION}_REJECTED`:
      return { loading: false, ...state, getDevicesResponse: payload };

    case `${GET_DEVICES_INFO_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${GET_DEVICES_INFO_ACTION}_FULFILLED`:
      return { loading: false, ...state, getDevicesInfoResponse: payload };
    case `${GET_DEVICES_INFO_ACTION}_REJECTED`:
      return { loading: false, ...state, getDevicesInfoResponse: payload };

    case `${GET_DEVICE_FILTERED_RESULTS_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${GET_DEVICE_FILTERED_RESULTS_ACTION}_FULFILLED`:
      return { loading: false, ...state, getDevicesInfoResponse: payload };
    case `${GET_DEVICE_FILTERED_RESULTS_ACTION}_REJECTED`:
      return { loading: false, ...state, getDevicesInfoResponse: payload };

      case `${GET_SELECT_DEVICES_FILTERED_RESULTS_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${GET_SELECT_DEVICES_FILTERED_RESULTS_ACTION}_FULFILLED`:
      return { loading: false, ...state, getDevicesInfoResponse: payload };
    case `${GET_SELECT_DEVICES_FILTERED_RESULTS_ACTION}_REJECTED`:
      return { loading: false, ...state, getDevicesInfoResponse: payload };


    default:
      return state;
  }
};

export default deviceReducer;
