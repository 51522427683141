/*
 *
 * filter reducer
 *
 */

import { SAVE_FILTER_ACTION, SAVE_COLUMNS_ACTION } from "./actionTypes";

export const initialState = {
  savedFilters: {},
  monitoringHeadings: ['', 'Monitoring ID', 'Monitoring Type', 'Monitoring Details', 'Min Temp (℃)', 'Max Temp (℃)', 'Sensor', 'Sensor Position', 'Date Started', 'Date Ended'],
  monitoringKeys: ['is_active', 'monitoring_id', 'monitoring_type', 'comment', 'min', 'max', 'sensor', 'sensor_position', 'started', 'ended'],
  sensorHeadings: ['Sensor', 'Company', 'Last Temperature', 'Last Update', 'Last Gateway', 'Signal Strength', 'Log Interval', 'Log Memory', 'Power on Date', 'Last Battery [mV]', 'Mac'],
  sensorKeys: ['name', 'company_name', 'last_temperature', 'last_update', 'last_gateway_id', 'signal_strength', 'log_interval', 'log_memory', 'power_on_date', 'last_battery', 'mac'],
  gatewayHeadings: ['Is Active', 'Hardware ID', 'Company Name', 'Last Battery', 'Location Id', 'MAC' , 'Last Updated', 'Actions'],
  gatewayKeys: ['is_active', 'hardware_id', 'company', 'last_battery', 'location', 'mac', 'updatedAt'],
};

const filterReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SAVE_FILTER_ACTION:
      return { ...state,  savedFilters: { ...payload } };
    case SAVE_COLUMNS_ACTION: 
      return { ...state, ...payload };
    default:
      return state;
  }
};

export default filterReducer;
