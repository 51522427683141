export const DASHBOARD_MONITORING_DATA = [
  {
    active: 'finished',
    critical: 'critical',
    usage: 'storage',
    monitoringName: 'Lu17 office',
    centrigrades: '-18.000',
    monitoringDetails: 'Setup Demo 14.10.16, 10:33th',
    sensorLocation: 'Office San Diego',
    dateStarted: '21/11/2016',
    dateEnded: '16/01/2017',
  },
  {
    active: 'finished',
    critical: 'critical',
    usage: 'storage',
    monitoringName: 'Lu17 office',
    centrigrades: '-18.000',
    monitoringDetails: 'Setup Demo 14.10.16, 10:33th',
    sensorLocation: 'Office San Diego',
    dateStarted: '21/11/2016',
    dateEnded: '16/01/2017',
  },
  {
    active: 'finished',
    critical: 'critical',
    usage: 'storage',
    monitoringName: 'Lu17 office',
    centrigrades: '-18.000',
    monitoringDetails: 'Setup Demo 14.10.16, 10:33th',
    sensorLocation: 'Office San Diego',
    dateStarted: '21/11/2016',
    dateEnded: '16/01/2017',
  },
  {
    active: 'finished',
    critical: 'critical',
    usage: 'storage',
    monitoringName: 'Lu17 office',
    centrigrades: '-18.000',
    monitoringDetails: 'Setup Demo 14.10.16, 10:33th',
    sensorLocation: 'Office San Diego',
    dateStarted: '21/11/2016',
    dateEnded: '16/01/2017',
  }
];

export const DASHBOARD_DEVICES_DATA = [
  {
    sensor: '00:94',
    centrigrades: '-18.000',
    monitoring: '--',
    lastUpdate: '16/05/2017 17:35',
    sender: 'admin@tsenso.com',
    battery: '3275',
  },
  {
    sensor: '00:94',
    centrigrades: '-18.000',
    monitoring: '--',
    lastUpdate: '16/05/2017 17:35',
    sender: 'admin@tsenso.com',
    battery: '3275',
  },
  {
    sensor: '00:94',
    centrigrades: '-18.000',
    monitoring: '--',
    lastUpdate: '16/05/2017 17:35',
    sender: 'admin@tsenso.com',
    battery: '3275',
  },
  {
    sensor: '00:94',
    centrigrades: '-18.000',
    monitoring: '--',
    lastUpdate: '16/05/2017 17:35',
    sender: 'admin@tsenso.com',
    battery: '3275',
  },
];

// Products table dummy data
export const PRODUCT_COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Produced In',
    dataIndex: 'producedIn',
  },
  {
    title: 'Monitoring',
    dataIndex: 'monitoring',
  },

  {
    title: 'Created At',
    dataIndex: 'createdAt',
  },
  {
    title: 'Best Before',
    dataIndex: 'bestBefore',
  },
  {
    title: 'QR code',
    dataIndex: 'code',
  },
  {
    title: 'Manufacturer',
    dataIndex: 'manufacturer',
  },
];


export const PRODUCT_DATA = [
  {
    id: '12772323D42S5DSSF241',
    producedIn: 'USA',
    monitoring: 'Setup Demo 14.10.16, 10:33th',
    createdAt: '12/11/2011',
    bestBefore: '12/11/2017',
    code: '86283236172128212',
    manufacturer: 'Klein Products of Texas'
  },
  {
    id: '12772323D4saasasaSF241',
    producedIn: 'USA',
    monitoring: 'Setup Demo 14.10.16, 10:33th',
    createdAt: '12/11/2011',
    bestBefore: '12/11/2017',
    code: '097287362323323',
    manufacturer: 'Klein Products of Texas'
  },
  {
    id: '12772323D42S5DSSF241',
    producedIn: 'USA',
    monitoring: 'Setup Demo 14.10.16, 10:33th',
    createdAt: '12/11/2011',
    bestBefore: '12/11/2017',
    code: '86283236172128212',
    manufacturer: 'Klein Products of Texas'
  },
  {
    id: '12772323D4saasasaSF241',
    producedIn: 'USA',
    monitoring: 'Setup Demo 14.10.16, 10:33th',
    createdAt: '12/11/2011',
    bestBefore: '12/11/2017',
    code: '097287362323323',
    manufacturer: 'Klein Products of Texas'
  },
  {
    id: '12772323D42S5DSSF241',
    producedIn: 'USA',
    monitoring: 'Setup Demo 14.10.16, 10:33th',
    createdAt: '12/11/2011',
    bestBefore: '12/11/2017',
    code: '86283236172128212',
    manufacturer: 'Klein Products of Texas'
  },
  {
    id: '12772323D4saasasaSF241',
    producedIn: 'USA',
    monitoring: 'Setup Demo 14.10.16, 10:33th',
    createdAt: '12/11/2011',
    bestBefore: '12/11/2017',
    code: '097287362323323',
    manufacturer: 'Klein Products of Texas'
  },
];

// Delivery boxes 
export const DELIVERY_BOXES_COLUMNS = [
  {
    title: 'Box ID',
    dataIndex: 'box_id'
  },
  {
    title: 'Box Description',
    dataIndex: 'box_description'
  },
  {
    title: 'Monitor Item',
    dataIndex: 'monitorbox_status'
  },
  {
    title: 'Limit Min',
    dataIndex: 'limit_min'
  },
  {
    title: 'Limit Max',
    dataIndex: 'limit_max'
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions'
  },
];

// Tasks Delivery

export const TASKS_DELIVERY_COLUMNS = [
  {
    title: 'Order Id',
    dataIndex: 'order_id',
  },
  {
    title: 'From Addresss',
    dataIndex: 'pickup_address',
  },
  {
    title: 'To Address',
    dataIndex: 'receiver_address',
  },

  {
    title: 'Driver Name',
    dataIndex: 'delivered_by',
  },
];

// Users

export const USERS_COLUMNS = [
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Company',
    dataIndex: 'company',
  },
  {
    title: 'Roles',
    dataIndex: 'roles',
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
  },
];

export const USERS_COLUMNS_DATA = [
  {
    email: 'mohsin.kirmani@mobylogix.com',
    company: 'Mobylogix',
    roles: 'Admin',
    phone: '00 92 321 4781221'
  },
  {
    email: 'mohsin.kirmani@mobylogix.com',
    company: 'Mobylogix',
    roles: 'Admin',
    phone: '00 92 321 4781221'
  },
  {
    email: 'mohsin.kirmani@mobylogix.com',
    company: 'Mobylogix',
    roles: 'Admin',
    phone: '00 92 321 4781221'
  },
];


// Companies

export const COMPANIES_COLUMNS = [
  {
    title: 'Company Name',
    dataIndex: 'firmName',
  },
  {
    title: 'Contact',
    dataIndex: 'contact',
  },
  {
    title: 'Address',
    dataIndex: 'address',
  },
];

export const COMPANIES_DATA = [
  {
    firmName: 'Mobylogix',
    contact: '123 (456) 7890',
    address: 'Vilnius, Lithuania'
  },
  {
    firmName: 'Mobylogix',
    contact: '123 (456) 7890',
    address: 'Vilnius, Lithuania'
  },
  {
    firmName: 'Mobylogix',
    contact: '123 (456) 7890',
    address: 'Vilnius, Lithuania'
  },
  {
    firmName: 'Mobylogix',
    contact: '123 (456) 7890',
    address: 'Vilnius, Lithuania'
  },
  {
    firmName: 'Mobylogix',
    contact: '123 (456) 7890',
    address: 'Vilnius, Lithuania'
  },
];

// Devices

export const DEVICES_COLUMNS = [
  {
    title: 'Sensor',
    dataIndex: 'sensor',
  },
  {
    title: 'MAC',
    dataIndex: 'mac',
  },
  {
    title: 'Owner Group',
    dataIndex: 'ownerGroup',
  },
  {
    title: 'Battery (mAH)',
    dataIndex: 'battery',
  },
  {
    title: 'Last Update',
    dataIndex: 'lastUpdate',
  },
  {
    title: 'Monitoring',
    dataIndex: 'monitoring',
  },
  {
    title: 'Change User',
    dataIndex: 'changeUser',
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
  },
];

export const DEVICES_DATA = [
  {
    sensor: 'Proximity Sensor',
    mac: 'MAC232323:2323AST:22323',
    ownerGroup: 'Arslan Nasir',
    battery: '3000',
    lastUpdate: '14/01/2019',
    monitoring: 'USA',
    changeUser: 'Admin',
    notes: 'Good going'
  },
  {
    sensor: 'Proximity Sensor',
    mac: 'MAC232323:2323AST:22323',
    ownerGroup: 'Arslan Nasir',
    battery: '3000',
    lastUpdate: '14/01/2019',
    monitoring: 'USA',
    changeUser: 'Admin',
    notes: 'Good going'
  },
  {
    sensor: 'Proximity Sensor',
    mac: 'MAC232323:2323AST:22323',
    ownerGroup: 'Arslan Nasir',
    battery: '3000',
    lastUpdate: '14/01/2019',
    monitoring: 'USA',
    changeUser: 'Admin',
    notes: 'Good going'
  },
  {
    sensor: 'Proximity Sensor',
    mac: 'MAC232323:2323AST:22323',
    ownerGroup: 'Arslan Nasir',
    battery: '3000',
    lastUpdate: '14/01/2019',
    monitoring: 'USA',
    changeUser: 'Admin',
    notes: 'Good going'
  }
];