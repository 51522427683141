/**
 *
 * Devices container class
 *
 */
// Packages
import React from "react";
import { message } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Components
import DevicesView from "../../components/DevicesView";

// HOCS
import withUserHOC from "../../hocs/withUserHOC";

// Actions
import { getDevices, updateDeviceInfo } from "../../ducks/device/action";

/* eslint-disable react/prefer-stateless-function */
class Devices extends React.Component {

  async componentDidMount() {
    try {
      const { client, getDevices } = this.props;
      // await getDevices(client);
    } catch (err) {
      message.error(err.message);
    }
  };

  reduxTextareaHandler = async (deviceId, textarea) => {
    try {
      const { client, updateDeviceInfo, history } = this.props;
      await updateDeviceInfo(client, deviceId, textarea);
      message.success('Device Information updated successfully!');
      // history.push('/devices');
    } catch (err) {
      message.error(err.message);
    }

  };

  render() {
    return (
      <DevicesView
        devices={this.props.devices}
        users={this.props.users}
        reduxTextareaHandler={this.reduxTextareaHandler.bind(this)}
      />
    );
  }
}

Devices.propTypes = {};

const mapStateToProps = ({ device }) => ({
  devices: device.getDevicesResponse.deviceAndSensor,
  users: device.getDevicesResponse.users,
});

const mapDispatchToProps = dispatch => ({
  getDevices: (client) => dispatch(getDevices(client)),
  updateDeviceInfo: (client, deviceId, textarea) =>
    dispatch(updateDeviceInfo(client, deviceId, textarea)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withUserHOC(withConnect(Devices));
