/*
 *
 * customer actionTypes
 *
 */

export const GET_CUSTOMERS_ACTION = "GET_CUSTOMERS_ACTION";
export const DELETE_CUSTOMER_ACTION = "DELETE_CUSTOMER_ACTION";
export const GET_SPECIFIC_CUSTOMER_ACTION = "GET_SPECIFIC_CUSTOMER_ACTION";
export const CREATE_OR_UPDATE_CUSTOMER_ACTION = "CREATE_OR_UPDATE_CUSTOMER_ACTION";
