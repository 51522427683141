/**
 *
 * MonitoringWarenView component class
 *
 */

import React from "react";
import {
  Row,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  DatePicker,
  Button,
  Select,
} from "antd";
import moment from 'moment-timezone';
// import { Chart } from "react-charts";

import { GET_SENSOR_LOGS } from "../../queries/sensor";
import ApexCharts from "apexcharts";
import "moment-timezone";
import ReactApexChart from "react-apexcharts";
// styles
import "./monitoring-waren-view.css";
import { methodSendGraph } from "../../reports/monthlyPDFReport";
const { RangePicker } = DatePicker;
const { Option } = Select;

// const f1 = () => {
//   console.log("F1");
// };
// const f2 = () => {
//   console.log("F2");
// };

/* eslint-disable react/prefer-stateless-function */
class MonitoringWarenView extends React.Component {
  state = {
    monitoringDates: [],
    min: "",
    max: "",
    email: "",
    comment: "",
    phone_number: "",
    started: "",
    ended: "",

    series: [
      {
        name: 'Temperatures',
        // data: [
        //   [1327359600000, 30.95],
        //   [1327359600000, 30.95],
        //   [1327359600000, 30.95],
        // ],
        data:[]
      },
    ],
    options: {
      colors: ["#78b829"],
      chart: {
        id: "area-datetime",
        type: "area",
        height: 450,
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: true,
            reset: true
          },
      },
        zoom: {
          enabled: true,
          type: 'x',  
          autoScaleYaxis: false,
        },
      },
      annotations: {
        yaxis: [
          {
            y: 30,
            borderColor: "#999",
            label: {
              show: true,
              text: "Support",
              style: {
                color: "#fff",
                background: "#00E396",
              },
            },
          },
        ],
        xaxis: [
          {
            x: new Date("14 Nov 2017").getTime(),
            borderColor: "#999",
            yAxisIndex: 0,
            label: {
              show: true,
              text: "Date",
              style: {
                color: "#fff",
                background: "#775DD0",
              },
            },
          },
        ],
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      markers: {
        size: 4,
        style: "hollow",
        colors: ["#70A624", "#E91E63", "#9C27B0"],
      },
      xaxis: {
        type: "datetime",
        min: new Date("14 Nov 2017").getTime(),
        tickAmount: 6,
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: true,
          minHeight: 100,
          maxHeight: 180,
          datetimeUTC: false,
          datetimeFormatter: {
            year: "yyyy",
            month: "MMM 'yy",
            day: "dd MMM",
            hour: "HH:mm",
          },
        },
      },
      tooltip: {
        x: {
          format: "HH:mm:ss dd MMM yyyy",
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 0,
          opacityFrom: 0.0,
          opacityTo: 0.0,
          stops: [0, 0, 0]
        }
      },
    },

    selection: "all",
  };

  updateData(timeline) {
    let duration_ms = undefined;
    switch (timeline) {
      case 'one_week':
        duration_ms = moment.duration(1, 'week').asMilliseconds()
        break;
    
      case 'one_month':
        duration_ms = moment.duration(1, 'month').asMilliseconds()
        break;

      case 'six_months':
        duration_ms = moment.duration(6, 'months').asMilliseconds()
        break;

      case 'one_year':
        duration_ms = moment.duration(1, 'year').asMilliseconds()
        break;

      case 'all':
      default:
        duration_ms = undefined;
        break;
    }

    this.setState({
      selection: timeline,
      options: {
        xaxis: {
          range: duration_ms,
        },
      },
    });
  }

  componentDidMount() {
    this.graphData();
  //   // this.saveToArchive("area-datetime");
  //   // this.getDataUri();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.sensorLogs!==this.props.sensorLogs){
      this.graphData();
    }

  }
  // componentWillReceiveProps(props) {
  //   if (this.props.monitoring !== props.monitoring) {
  //     this.setState({
  //       min: props.monitoring.min,
  //       max: props.monitoring.max,
  //       notification_to: props.monitoring.notification_to,
  //       comment: props.monitoring.comment,
  //       report_to: props.monitoring.report_to,
  //       started: props.monitoring.started,
  //       ended: props.monitoring.ended,
  //       data: {},
  //     });
  //   }
  // }

  componentWillReceiveProps(props) {
    if (this.props.monitoring !== props.monitoring) {
      this.setState({
        min: props.monitoring.min,
        max: props.monitoring.max,
        notification_to: props.monitoring.notification_to,
        comment: props.monitoring.comment,
        report_to: props.monitoring.report_to,
        started: props.monitoring.started,
        ended: props.monitoring.ended,
        data: {},
      });
    }
  }


  handleChange = (e, key) => {
    this.setState({ [key]: e.target.value });
  };

  handleInputNumberChange = (value, key) => {
    this.setState({ [key]: value });
  };

  handleSubmit = (e, key) => {
    this.props.updateMonitoringHandler(key, this.state[key]);
  };

  datesChangeHandler = (date, dateString) => {
    if (dateString.length === 1) {
      this.setState({ ended: dateString[0] });
    } else if (dateString.length === 2) {
      this.setState({
        started: dateString[0],
        ended: dateString[1],
      });
    }
  };

  handleClick = () => {
    this.props.handlerEndMonitoring();
  };

  onOk = (value) => {
    this.state.monitoringDates.push(value[0]._d);
    this.state.monitoringDates.push(value[1]._d);
    this.props.updateMonitoringDatesHandler(
      "dates",
      this.state.monitoringDates
    );
  };

  onOkHandler = (value) => {
    this.state.monitoringDates.push(value._d);
    this.props.updateMonitoringDatesHandler(
      "dates",
      this.state.monitoringDates
    );
  };

  startNowMonitoring = () => {
    this.props.handlerStartScheduledMonitoring();
  };
  graphdataCSVreal = () => {
    this.props.graphdataCSV();
  };

  graphData() {
    // setTimeout(()=>{
    const sensorLogs = this.props.sensorLogs;
    const temperature = sensorLogs.map((logs) => {
      return logs.temperature;
    });
    // console.log("AAA Props inside:",this.props)
    // console.log("AAA SensorLogs", this.props.sensorLogs);
    let obj = [];
    const measurement_timestamp = sensorLogs.map((logs, i) => {
      const date = new Date(parseInt(logs.measurement_timestamp));
      const returnDate = moment(date)
        .utcOffset("+02:00")
        .format("DD/MM/YYYY HH:mm [CET]");
      // console.log("Return date",returnDate)
      const ts = moment(returnDate.toString(), "DD/MM/YYYY HH:mm [CET]")
        .tz("Europe/Berlin")
        .valueOf();
      // moment(value).tz("Europe/Berlin").format("DD.MM.YY HH:mm [CET]")
      // console.log("ts",ts)
      obj.push([ts, temperature[i]]);
      return returnDate;
    });
    let minDate = 0;

    if (obj.length != 0) {
      minDate = obj[0][0];
      this.setState({
        series: [
          {
            data: obj,
          },
        ],
        options: {
          xaxis: {
            min: new Date(minDate).getTime(),
          },
        },
      });
    }
    // console.log("Min Date:",minDate)
  // }
    // ,1800);
  }

  sendData = async () => {
    const value64 = await this.getDataUri();
    // const value64 =  this.getDataUri();
    // console.log("pic", value64);
    await this.props.monthlypdfReport(value64);

    // this.getDataUri();
    // f1();
    // f2();
  };

  // getDataUri = () => {
  //   const henry = ApexCharts.exec("area-datetime", "dataURI").then(
  //     ({ imgURI }) => {
  //       // console.log("Photo",imgURI);
  //       return imgURI;
  //     }
  //   );
  //   return henry;
  // };

  getDataUri = async () => {
    return await ApexCharts.exec("area-datetime", "dataURI");
  };

  render() {
    const { monitoring } = this.props;
    const { min, max, notification_to, comment, report_to } = this.state;

    return (
      <Row id="MonitoringWarenView">
        <h1>Monitoring: {monitoring && monitoring.monitoring_id}</h1>
        <Row>
          <Card
            className="mb-holder"
            title={`Monitoring - ${
              monitoring && monitoring.monitoring_id
                ? monitoring.monitoring_id
                : "N/A"
            } | Started: ${
              monitoring && monitoring.started
                ? moment(monitoring.started)
                    .tz("Europe/Berlin")
                    .format("DD.MM.YY HH:mm:ss [CET]")
                : "N/A"
            } | Finished: ${
              monitoring && monitoring.ended
                ? moment(monitoring.ended)
                    .tz("Europe/Berlin")
                    .format("DD.MM.YY HH:mm:ss [CET]")
                : "N/A"
            }`}
          >
            {monitoring && monitoring.started && !monitoring.ended && (
              <Row type="flex" align="middle" justify="center">
                <Button
                  className="end-monitoring-button"
                  onClick={this.handleClick}
                >
                  Stop Monitoring
                </Button>
              </Row>
            )}
            {monitoring && !monitoring.started && !monitoring.ended && (
              <Row type="flex" align="middle" justify="center">
                <Button
                  className="start-button"
                  onClick={this.startNowMonitoring}
                >
                  Start Monitoring
                </Button>
              </Row>
            )}

            <br />
            <Row gutter={25} className="mb-card" type="flex">
              <Col lg={12}>
                <Card>
                  <Row>
                    <Col lg={4} className="label">
                      <label> Min ℃ </label>
                    </Col>
                    <Col lg={20}>
                      <InputNumber
                        value={min}
                        disabled={
                          monitoring.ended &&
                          new Date(monitoring.ended && monitoring.ended) <
                            new Date()
                            ? true
                            : false
                        }
                        className="input-number"
                        onChange={(value) =>
                          this.handleInputNumberChange(value, "min")
                        }
                        onKeyUp={(e) =>
                          e.keyCode === 13 ? this.handleSubmit(e, "min") : null
                        }
                        onBlur={(e) => this.handleSubmit(e, "min")}
                      />
                    </Col>
                  </Row>
                  <Row className="row-container">
                    <Col lg={4} className="label">
                      <label> Max ℃ </label>
                    </Col>
                    <Col lg={20}>
                      <InputNumber
                        value={max}
                        disabled={
                          monitoring.ended &&
                          new Date(monitoring.ended && monitoring.ended) <
                            new Date()
                            ? true
                            : false
                        }
                        className="input-number"
                        onChange={(value) =>
                          this.handleInputNumberChange(value, "max")
                        }
                        onKeyUp={(e) =>
                          e.keyCode === 13 ? this.handleSubmit(e, "max") : null
                        }
                        onBlur={(e) => this.handleSubmit(e, "max")}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col lg={12}>
                <Card>
                  <Row>
                    <Col lg={4} className="label">
                      <label>Customer</label>
                    </Col>
                    <Col lg={20}>
                      <Form.Item>
                        <Input
                          value={
                            monitoring &&
                            monitoring.customer &&
                            monitoring.customer.name
                          }
                          disabled={true}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4} className="label">
                      <label>Description</label>
                    </Col>
                    <Col lg={20}>
                      <Form.Item>
                        <Input
                          value={comment}
                          disabled={
                            monitoring.ended &&
                            new Date(monitoring.ended && monitoring.ended) <
                              new Date()
                              ? true
                              : false
                          }
                          onChange={(e) => this.handleChange(e, "comment")}
                          onKeyUp={(e) =>
                            e.keyCode === 13
                              ? this.handleSubmit(e, "comment")
                              : null
                          }
                          onBlur={(e) => this.handleSubmit(e, "comment")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row gutter={25} className="mb-card" type="flex">
              <Col lg={12}>
                <Card className="sen-loc-fields-card">
                  <Row>
                    <Col lg={4} className="label">
                      <label>Sensor</label>
                    </Col>
                    <Col lg={20}>
                      <Form.Item>
                        <Input
                          value={
                            monitoring &&
                            monitoring.sensor &&
                            monitoring.sensor.name
                          }
                          disabled={true}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4} className="label">
                      <label>Position</label>
                    </Col>
                    <Col lg={20}>
                      <Form.Item>
                        <Input
                          disabled={true}
                          value={monitoring.sensor_position}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col lg={12}>
                <Card className="status-card">
                  <Row>
                    <Col lg={24}>
                      <Row className="started-ended-date-container">
                        <Col lg={4}>
                          <label className="text-label">Started</label>
                        </Col>
                        <Col lg={20}>
                          <Input
                            value={
                              monitoring &&
                              monitoring.started &&
                              monitoring.started
                            }
                            disabled={true}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <label className="text-label">Ended</label>
                        </Col>
                        <Col lg={20}>
                          <Input
                            value={
                              monitoring && monitoring.ended && monitoring.ended
                            }
                            disabled={true}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="row-label">
                    <Col lg={4} className="label">
                      <label>Status</label>
                    </Col>
                    <Col lg={20} className="label">
                      <label className="label-color">
                        {monitoring && monitoring.is_crtical === true ? (
                          <p className="critical">Critical</p>
                        ) : (
                          <p className="safe">OK</p>
                        )}
                      </label>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row gutter={25} className="mb-card" type="flex">
              <Col lg={12}>
                <Card className="email-sms-fields-row">
                  <Row>
                    <Col lg={5} className="label">
                      <label>Notification To</label>
                    </Col>
                    <Col lg={19}>
                      <Input
                        value={notification_to}
                        disabled={
                          monitoring.ended &&
                          new Date(monitoring.ended && monitoring.ended) <
                            new Date()
                            ? true
                            : false
                        }
                        onChange={(e) =>
                          this.handleChange(e, "notification_to")
                        }
                        onKeyUp={(e) =>
                          e.keyCode === 13
                            ? this.handleSubmit(e, "notification_to")
                            : null
                        }
                        onBlur={(e) => this.handleSubmit(e, "notification_to")}
                      />
                    </Col>
                  </Row>
                  <Row className="row-container">
                    <Col lg={5} className="label">
                      <label>Report To</label>
                    </Col>
                    <Col lg={19}>
                      <Input
                        value={report_to}
                        disabled={
                          monitoring.ended &&
                          new Date(monitoring.ended && monitoring.ended) <
                            new Date()
                            ? true
                            : false
                        }
                        onChange={(e) => this.handleChange(e, "report_to")}
                        onKeyUp={(e) =>
                          e.keyCode === 13
                            ? this.handleSubmit(e, "report_to")
                            : null
                        }
                        onBlur={(e) => this.handleSubmit(e, "report_to")}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col lg={12}>
                <Card className="temperature-card">
                  <Row>
                    <Col lg={4} className="label">
                      <label>Last Update</label>
                    </Col>
                    <Col lg={20} className="label">
                      <label className="ant-input ant-input-disabled">
                        {monitoring && monitoring.updatedAt
                          ? moment(monitoring.updatedAt)
                              .tz("Europe/Berlin")
                              .format("DD.MM.YY HH:mm [CET]")
                          : ""}
                      </label>
                    </Col>
                  </Row>
                  <Row className="row-label">
                    <Col lg={4} className="label">
                      <label>Temperature</label>
                    </Col>
                    <Col lg={20} className="label">
                      <label className="ant-input ant-input-disabled">
                        {monitoring && monitoring.sensor
                          ? `${monitoring.sensor.last_temperature} °C`
                          : ""}
                      </label>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            {monitoring && (monitoring.comment || monitoring.sensor_pos_url) && (
              <Row>
                <Card>
                  {monitoring && monitoring.comment && (
                    <Col lg={24}>
                      <label>
                        <b>Comment</b>
                      </label>
                      <p>{monitoring.comment}</p>
                    </Col>
                  )}

                  {monitoring && monitoring.sensor_pos_url && (
                    <Col lg={24}>
                      <img
                        src={monitoring.sensor_pos_url}
                        className="sensor_img"
                      />
                    </Col>
                  )}
                </Card>
              </Row>
            )}
          </Card>
        </Row>
        <Row className="monitoring-report-container">
          <Card title={"Monitoring Report"}>
            <Row>
              <div id="chart">
                { <div class="toolbar">
                    <button
                      id="one_week"
                      onClick={() => this.updateData("one_week")}
                      className={this.state.selection === "ytd" ? "active" : ""}
                    >
                      1 week
                    </button>
                    &nbsp;
                    <button
                      id="one_month"
                      onClick={() => this.updateData("one_month")}
                      className={
                        this.state.selection === "one_month" ? "active" : ""
                      }
                    >
                      1 month
                    </button>
                    &nbsp;
                    <button
                      id="six_months"
                      onClick={() => this.updateData("six_months")}
                      className={
                        this.state.selection === "six_months" ? "active" : ""
                      }
                    >
                      6 months
                    </button>
                    &nbsp;
                    <button
                      id="one_year"
                      onClick={() => this.updateData("one_year")}
                      className={
                        this.state.selection === "one_year" ? "active" : ""
                      }
                    >
                      1 year
                    </button>
                    &nbsp;
                    <button
                      id="all"
                      onClick={() => this.updateData("all")}
                      className={this.state.selection === "all" ? "active" : ""}
                    >
                      All
                    </button>
                  </div> }

                <div id="chart-timeline">
                  <ReactApexChart
                    id="11a"
                    options={this.state.options}
                    series={this.state.series}
                    type="area"
                    height={480}
                    // width={900}
                  />
                </div>
              </div>
              {/* <button onClick={this.getDataUri}>Get DATAURI</button> */}
            </Row>
            <Row className="display-flex report-buttons-container" gutter={25}>
              {monitoring && monitoring.ended && (
                <Col lg={8}>
                  <Button
                    className="report-button"
                    onClick={() => this.sendData()}
                    // onClick={this.props.monthlypdfReport}
                    // graphDataUri={this.getDataUri}
                  >
                    Download PDF Report
                  </Button>
                </Col>
              )}
              <Col lg={monitoring && monitoring.ended ? 8 : 16}>
                <Button
                  className="report-button"
                  onClick={this.props.downloadCSV}
                >
                  {"Download data as CSV file"}
                </Button>
              </Col>
            </Row>
          </Card>
        </Row>
      </Row>
    );
  }
}

MonitoringWarenView.propTypes = {};

export default Form.create()(MonitoringWarenView);
