/*
 *
 * user reducer
 *
 */

import {
  LOGIN_USER_ACTION,
  GET_USERS_ACTION,
  FORGOT_USER_PASSWORD_ACTION,
  RESET_USER_PASSWORD_ACTION,
  CHANGE_USER_PASSWORD_ACTION,
  CHANGE_USER_ROLE_ACTION,
  GET_USERS_FILTERED_RESULT_ACTION,
  DELETE_USER_ACTION,
  GET_SPECIFIC_USER_ACTION,
  UPDATE_USER_ACTION,
  LOGOUT_USER
} from "./actionTypes";

export const initialState = {
  users: [],
};

const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case `${LOGIN_USER_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${LOGIN_USER_ACTION}_FULFILLED`:
      return { loading: false, ...state, currentUser: payload.user };
    case `${LOGIN_USER_ACTION}_REJECTED`:
      return { loading: false, ...state, currentUser: payload };

    case `${GET_USERS_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${GET_USERS_ACTION}_FULFILLED`:
      return { loading: false, ...state, users: payload };
    case `${GET_USERS_ACTION}_REJECTED`:
      return { loading: false, ...state, users: payload };

    case `${GET_SPECIFIC_USER_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${GET_SPECIFIC_USER_ACTION}_FULFILLED`:
      return { loading: false, ...state, ...payload };
    case `${GET_SPECIFIC_USER_ACTION}_REJECTED`:
      return { loading: false, ...state, ...payload };

    case `${GET_USERS_FILTERED_RESULT_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${GET_USERS_FILTERED_RESULT_ACTION}_FULFILLED`:
      return { loading: false, ...state, users: payload };
    case `${GET_USERS_FILTERED_RESULT_ACTION}_REJECTED`:
      return { loading: false, ...state, users: payload };

    case `${DELETE_USER_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${DELETE_USER_ACTION}_FULFILLED`:
      return { loading: false, ...state, users: payload };
    case `${DELETE_USER_ACTION}_REJECTED`:
      return { loading: false, ...state, users: payload };

    case `${UPDATE_USER_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${UPDATE_USER_ACTION}_FULFILLED`:
      return { loading: false, ...state, updateUserResponse: payload };
    case `${UPDATE_USER_ACTION}_REJECTED`:
      return { loading: false, ...state, updateUserResponse: payload };

    case `${FORGOT_USER_PASSWORD_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${FORGOT_USER_PASSWORD_ACTION}_FULFILLED`:
      return { loading: false, ...state, forgotPasswordResponse: payload };
    case `${FORGOT_USER_PASSWORD_ACTION}_REJECTED`:
      return { loading: false, ...state, forgotPasswordResponse: payload };

    case `${RESET_USER_PASSWORD_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${RESET_USER_PASSWORD_ACTION}_FULFILLED`:
      return { loading: false, ...state, resetPasswordResponse: payload };
    case `${RESET_USER_PASSWORD_ACTION}_REJECTED`:
      return { loading: false, ...state, resetPasswordResponse: payload };

    case `${CHANGE_USER_PASSWORD_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${CHANGE_USER_PASSWORD_ACTION}_FULFILLED`:
      return { loading: false, ...state, changePasswordResponse: payload };
    case `${CHANGE_USER_PASSWORD_ACTION}_REJECTED`:
      return { loading: false, ...state, changePasswordResponse: payload };

    case `${CHANGE_USER_ROLE_ACTION}_PENDING`:
      return { loading: true, ...state };
    case `${CHANGE_USER_ROLE_ACTION}_FULFILLED`:
      return { loading: false, ...state, users: payload };
    case `${CHANGE_USER_ROLE_ACTION}_REJECTED`:
      return { loading: false, ...state, users: payload };

    case LOGOUT_USER:
      return initialState;
  
    default:
      return state;
  }
};

export default userReducer;
