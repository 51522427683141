/**
 *
 * Gateway container class
 *
 */

import React from "react";
import { message } from "antd";
import { connect } from "react-redux";

// Components
import GatewayView from "../../components/GatewayView";

// HOCS
import withUserHOC from "../../hocs/withUserHOC";

// Actions

import { getCompanies, changeGatewayCompany } from "../../ducks/firm/action";
import { getGateways, deleteGateway, getFilteredGateways, resetGatewayPassword, muteGateway, columnBasedGatewaySorting } from "../../ducks/gateway/action";
import { saveColumns } from "../../ducks/filter/action";
/* eslint-disable react/prefer-stateless-function */
class Gateway extends React.Component {

  state = {
    pageNo: 1,
  };

  async componentDidMount() {
    try {
      const { client, getGateways, getCompanies } = this.props;
      await getGateways(client, {userId: localStorage.userId, pageNo: 1});
      await getCompanies(client);
    } catch (err) {
      message.error(err.message);
    }
  };

  reduxPageHandler = async (pageNo) => { 
    try {
      this.setState({ pageNo: pageNo });
      await this.props.getGateways(this.props.client, {userId: localStorage.userId, pageNo});
    } catch(err) {
      message.error(err);
    }
  };

  deleteGatewayHandler = async (gatewayId) => {
    try {
      const { client, deleteGateway } = this.props;
      await deleteGateway(client, gatewayId, this.state.pageNo);
    } catch (err) {
      message.error(err.message);
    }
  };

  searchHandler = async (keyword) => {
    try {
      const { client, getFilteredGateways } = this.props;
      await getFilteredGateways(client, keyword, localStorage.userId);
    } catch (err) {
      message.error(err);
    }
  };

  changeGatewayPasswordHandler = async (gatewayId, values) => {
    try {
      const { client, resetGatewayPassword  } = this.props;
      await resetGatewayPassword(client, gatewayId, values);
      message.success('Reset password successfully!');
    } catch (err) {
      message.error(err);
    }
  };

  changeGatewayCompanyHandler = async (gatewayId, companyId) => {
    const { client, changeGatewayCompany } = this.props;
    await changeGatewayCompany(client, gatewayId, companyId);
    message.success('Gateway company updated successfully!');
  };

  saveColumnsHandler = (columnsHeadings, columnsKeys) => {
    const { saveColumns } = this.props;
    saveColumns('gateways', columnsHeadings, columnsKeys);
  };

  columnSortingHandler = (sortedArr) => {
    const { columnBasedGatewaySorting } = this.props;
    columnBasedGatewaySorting(sortedArr);
  };

  muteGatewayHandler = async (gatewayId, isMuted) => {
    const { client, muteGateway } = this.props;
    await muteGateway(client, gatewayId, isMuted, this.state.pageNo);
  };

  render() {
    return (
      <GatewayView
        {...this.props}
        reduxPageHandler={this.reduxPageHandler.bind(this)}
        deleteGatewayHandler={this.deleteGatewayHandler.bind(this)}
        searchHandler={this.searchHandler.bind(this)}
        changeGatewayPasswordHandler={this.changeGatewayPasswordHandler.bind(this)}
        changeGatewayCompanyHandler={this.changeGatewayCompanyHandler.bind(this)}
        muteGatewayHandler={this.muteGatewayHandler.bind(this)}
        saveColumnsHandler={this.saveColumnsHandler.bind(this)}
        columnSortingHandler={this.columnSortingHandler.bind(this)}
      />
    );
  }
}

Gateway.propTypes = {};

const mapStateToProps = ({ gateway, firm, filter }) => ({
  gatewaysResponse: gateway.getGateways,
  companies: firm.companies,
  headings: filter.gatewayHeadings,
  keys: filter.gatewayKeys
});

const mapDispatchToProps = dispatch => ({
  getGateways: (client, data) => dispatch(getGateways(client, data)),
  deleteGateway: (client, gatewayId, pageNo) => dispatch(deleteGateway(client, gatewayId, pageNo)),
  getFilteredGateways: (client, keyword, userId) => dispatch(getFilteredGateways(client, keyword, userId)),
  resetGatewayPassword: (client, gatewayId, values) => dispatch(resetGatewayPassword(client, gatewayId, values)),
  getCompanies: (client) => dispatch(getCompanies(client)),
  changeGatewayCompany: (client, gatewayId, companyId) =>
    dispatch(changeGatewayCompany(client, gatewayId, companyId)),
  muteGateway: (client, gatewayId, isMuted, pageNo) => 
    dispatch(muteGateway(client, gatewayId, isMuted, pageNo)),
  saveColumns: (key, columnsHeadings, columnsKeys) => 
    dispatch(saveColumns(key, columnsHeadings, columnsKeys)),
  columnBasedGatewaySorting: (sortedArr) => dispatch(columnBasedGatewaySorting(sortedArr)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withUserHOC(withConnect(Gateway));
