/**
 *
 * MonitoringWizard container class
 *
 */

// Packages
import React from "react";
import { message } from "antd";
import { connect } from "react-redux";

// Components
import MonitoringWizardView from "../../components/MonitoringWizardView";
import StartMonitoring from "../../components/StartMonitoring";

// HOCS
import withUserHOC from "../../hocs/withUserHOC";
import withFileUploader from "../../hocs/withFileUploader";

// Actions
import { startMonitoring, setFirstStepValues } from "../../ducks/monitoring/action";
import { getSensors, getActiveSensors } from "../../ducks/sensor/action";
import { getCustomers } from "../../ducks/customer/action";

/* eslint-disable react/prefer-stateless-function */
class MonitoringWizard extends React.Component {

  async componentDidMount() {
    const {client, getActiveSensors, getCustomers } = this.props;
    await getActiveSensors(client, localStorage.userId);
    await getCustomers(client, localStorage.userId);
  };

  firstStepReduxHandler = (values) => {
    const { setFirstStepValues } = this.props;
    setFirstStepValues(values);
  };

  imageUploader = (file) => {
    this.props.uploadImage(file);
  };

  reduxHandler = async (values) => {
    try {
      const { client, startMonitoring, history } = this.props;
      await startMonitoring(client, values);
      message.success("Monitorting added successfully!");
      history.push('/monitorings');
    } catch (err) {
      message.error(err.message);
    }
  };

  render() {
    return (
      // <MonitoringWizardView
      //   {...this.props}
      //   reduxHandler={this.reduxHandler.bind(this)}
      //   firstStepReduxHandler={this.firstStepReduxHandler.bind(this)}
      //   imageUploader={this.imageUploader.bind(this)}
      //   id={this.props.match.params.id}
      //   uploadFile={this.props.uploadFile}
      // />
      <StartMonitoring
        {...this.props}
        reduxHandler={this.reduxHandler.bind(this)}
        imageUploader={this.imageUploader.bind(this)}
      />
    );
  }
}

MonitoringWizard.propTypes = {};

const mapStateToProps = ({ monitoring, sensor, customer }) => ({
  firstStepperValues: monitoring.firstStepper,
  sensors: sensor.sensors,
  activeSensors: sensor.activeSensors,
  customers: customer.customers
});

const mapDispatchToProps = dispatch => ({
  startMonitoring: (client, data) => dispatch(startMonitoring(client, data)),
  getSensors: (client, pageNo) => dispatch(getSensors(client, pageNo)),
  setFirstStepValues: (values) => dispatch(setFirstStepValues(values)),
  getActiveSensors: (client, userId) => dispatch(getActiveSensors(client, userId)),
  getCustomers: (client, userId) => dispatch(getCustomers(client, userId))
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withUserHOC(withFileUploader(withConnect(MonitoringWizard)));
