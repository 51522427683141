import React, { PureComponent } from "react";
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { Layout, message } from 'antd';

// components
import MyHeader from '../components/MyHeader';
import MyFooter from '../components/MyFooter';

// Actions
import { changeUserPassword, getSpecificUser, logout } from '../ducks/user/action';

// styles
import './hoc.css';

const { Content } = Layout;

export default (WrappedComponent, props = {}) => {
  class WithUserHOC extends PureComponent {

    async componentDidMount() {
      const userId = localStorage.getItem('userId');
      if(!userId) {
        this.props.history.push('/');
      } else {
        const { client, getSpecificUser } = this.props;
        await getSpecificUser(client, userId, true);
      }
    };    
    
    changePasswordHandler = async (values) => {
      try {
        const { client, changeUserPassword } = this.props;
        const userId = localStorage.getItem('userId');
        await changeUserPassword(client, userId, values);
        message.success(this.props.changePasswordResponse.message);
      } catch (err) {
        message.error(err.message);
      }
    };

    render() {
      return (
        <Layout className="layout">
          <MyHeader
            currentUser={this.props.currentUser ? this.props.currentUser : {}}
            logout={this.props.logout}
            isCurrentGateway={localStorage.getItem('userId') ? true : false}
            history={this.props.history}
            changePasswordHandler={this.changePasswordHandler.bind(this)}
          />
          <Content className="app-content">
            <WrappedComponent
              {...this.props}
            />
          </Content>
          <MyFooter />
        </Layout>
      )
    }
  }

  const mapStateToProps = ({ user }) => ({
    changePasswordResponse: user.changePasswordResponse,
    currentUser: user.currentUser
  });
  
  const mapDispatchToProps = dispatch => ({
    changeUserPassword: (client, userId, values) => 
      dispatch(changeUserPassword(client, userId, values)),
    getSpecificUser: (client, userId, currentUser) => dispatch(getSpecificUser(client, userId, currentUser)),
    logout: () => dispatch(logout())
  });
  
  const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

  return withRouter(withConnect(withApollo(WithUserHOC)));
}
