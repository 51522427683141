/*
 *
 * filter actions
 *
 */

import { SAVE_FILTER_ACTION, SAVE_COLUMNS_ACTION } from "./actionTypes";

export const saveFilters = (key, values) => ({
  type: SAVE_FILTER_ACTION,
  payload: { key: key, values: values }
});

export const saveColumns = (key, columnsHeadings, columnsKeys) => ({
  type: SAVE_COLUMNS_ACTION,
  payload() {

    if (key === 'monitorings') {
      return { monitoringHeadings: columnsHeadings, monitoringKeys: columnsKeys };
    } else if (key === 'sensors') {
      return { sensorHeadings: columnsHeadings, sensorKeys: columnsKeys };
    } else if (key === 'gateways') {
      return { gatewayHeadings: columnsHeadings, gatewayKeys: columnsKeys };
    }
  }
});