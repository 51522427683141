/**
 *
 * AddCompany container class
 *
 */
// packages
import React from "react";
import { message } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Components
import AddCompanyCard from "../../components/AddCompanyCard";

// HOCS
import withUserHOC from "../../hocs/withUserHOC";

// Actions
import { addCompany, getSpecificCompany, updateCompany } from "../../ducks/firm/action";

/* eslint-disable react/prefer-stateless-function */
class AddCompany extends React.Component {

  async componentWillMount() {
    try {
      const { client, getSpecificCompany, match: {params} } = this.props;
      if (params.id) {
        await getSpecificCompany(client, params.id);
      }
    } catch (err) {
      message.error(err.message);
    }
  };

  reduxHandler = async (values) => {
    try {
      const { client, addCompany } = this.props;
      const userId = localStorage.getItem('userId');
      await addCompany(client, userId, values);
      message.success('Company added successfully!');
      this.props.history.push('/companies');
    } catch (err) {
      message.error(err.message);
    }
  };

  updateReduxHandler = async (values, companyId) => {
    try {
      const { client, updateCompany } = this.props;
      await updateCompany(client, values, companyId);
      message.success('Company updated successfully!');
      this.props.history.push('/companies');
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    return (
      <AddCompanyCard
        {...this.props}
        reduxHandler={this.reduxHandler.bind(this)}
        updateReduxHandler={this.updateReduxHandler.bind(this)}
        company={this.props.company && this.props.company.firm}
      />
    );
  }
}

AddCompany.propTypes = {};

const mapStateToProps = ({ firm }) => ({
  company: firm.specificCompany,
});

const mapDispatchToProps = dispatch => ({
  addCompany: (client, userId, values) => dispatch(addCompany(client, userId, values)),
  getSpecificCompany: (client, companyId) =>
    dispatch(getSpecificCompany(client, companyId)),
  updateCompany: (client, values, companyId) =>
    dispatch(updateCompany(client, values, companyId)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withUserHOC(withConnect(AddCompany));
