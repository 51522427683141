import gql from "graphql-tag";

const firm = `
  name
  address
  city
`;

const location = `
  id
  location_id
  company_id
  company {
    ${firm}
  }
  address
  details
  latitude
  longitude
  createdAt
  updatedAt
`;

export const ADD_LOCATION = gql`
  mutation addLocation(
    $location_id: String
    $company_id: Int
    $address: String
    $details: String
  ) {
    addLocation(
      location_id: $location_id
      company_id: $company_id
      address: $address
      details: $details
    ) {
      success
      message
    } 
  }
`;

export const GET_LOCATIONS = gql`
  query getLocations(
    $pageNo: Int
    $userId: Int
  ) {
    getLocations(
      pageNo: $pageNo
      userId: $userId
    ) {
      locations {
        ${location}
      }
      count
    } 
  }
`;

export const DELETE_LOCATION = gql`
  mutation deleteLocation(
    $locationId: Int
    $pageNo: Int
  ) {
    deleteLocation(
      locationId: $locationId
      pageNo: $pageNo
    ) {
      locations {
        ${location}
      }
      count
    } 
  }
`;

export const GET_SPECIFIC_LOCATION = gql`
  query getSpecificLocation(
    $locationId: Int
  ) {
    getSpecificLocation(
      locationId: $locationId
    ) {
      message
      success
      location {
        ${location}
      }
    } 
  }
`;

export const UPDATE_LOCATION = gql`
  mutation updateLocation(
    $locationId: Int
    $location_id: String
    $company_id: Int
    $address: String
    $details: String
    $latitude: Float
    $longitude: Float
  ) {
    updateLocation(
      locationId: $locationId
      location_id: $location_id
      company_id: $company_id
      address: $address
      details: $details
      latitude: $latitude
      longitude: $longitude
    ) {
      success
      message
    } 
  }
`;

export const GET_LOCATIONS_AGAINT_SPECIFIC_COMPANY = gql`
  query getLocationsAgainstCompany(
    $companyId: Int
  ) {
    getLocationsAgainstCompany(
      companyId: $companyId
    ) {
      ${location}
    } 
  }
`;
