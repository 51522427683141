/**
 *
 * ForgotPassword component class
 *
 */
// Packages
import React from "react";
import PropTypes from "prop-types";
import { Row, Icon, Modal, Button, Form, Input } from 'antd';

// styles
import "./forgot-password.css";

/* eslint-disable react/prefer-stateless-function */
class ForgotPassword extends React.Component {

  handleSubmitForgotPassword = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.forgotPasswordHandler(values);
        this.props.closeModal();
        this.props.form.resetFields();
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        title="Forgot Password"
        visible={this.props.visible}
        onCancel={this.props.handleCancel}
        footer={[<div />, <div />]}
      >
        <Form id="ForgotPassword" onSubmit={this.handleSubmitForgotPassword} className="login-form">
          <Form.Item>
            {getFieldDecorator('email', {
              rules: [{ required: true, message: 'Email is required!' }],
            })(
              <Input prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />} type="email" placeholder="Enter your email" />
            )}
          </Form.Item>
          <Row type="flex" justify="center" align="middle">
            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button">
                Submit
          </Button>
            </Form.Item>
          </Row>
        </Form>
      </Modal>
    );
  }
}

ForgotPassword.propTypes = {};

export default Form.create()(ForgotPassword);
