/**
 *
 * ProductView component class
 *
 */

import React from "react";
import { Row, Table, Button, Popover, Checkbox } from "antd";
import PropTypes from "prop-types";

// styles
import "./product-view.css";

//utils
import { PRODUCT_COLUMNS, PRODUCT_DATA } from "../../utils/data";

class ProductView extends React.Component {

  state = {
    checked: true,
    visible: false,
  };

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  };

  handleCheckbox = (key) => {
    this.setState({
      [key]: !this.state[key],
    });
  };

  render() {
    return (
      <Row id="ProductView">
        <Row>
          <Popover
            content={<ColumnPopover handleCheckbox={this.handleCheckbox} checked={this.state.checked} />}
            trigger="click"
            visible={this.state.visible}
            onVisibleChange={this.handleVisibleChange}
          >
            <Button className="column-button">Columns</Button>
          </Popover>
        </Row>
        <Row>
          <Table scroll={{ y: 440 }} columns={PRODUCT_COLUMNS} dataSource={PRODUCT_DATA} / >
        </Row>
      </Row>
    );
  }
}

ProductView.propTypes = {};

export default ProductView;

const ColumnPopover = ({ handleCheckbox }) => (
  <div>
    {PRODUCT_COLUMNS.map((column, i) => (
      <Row key={i}>
        <Checkbox
          checked={column.dataIndex}
          onChange={() => handleCheckbox(column.dataIndex)}
        >
          {column.title}
        </Checkbox>
      </Row>
    ))}
  </div>
);
