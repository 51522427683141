/**
 *
 * CompanyView component class
 *
 */
// Packages
import React from "react";
import { DataTable } from "antd-data-table";
import { Row, Divider, Button, Tooltip, Icon, Popconfirm, Input, Col } from "antd";
import PropTypes from "prop-types";
import _ from "lodash";
import { Table, Pagination, PaginationItem, PaginationLink } from 'reactstrap';

// Components
import EmptyData from "../EmptyData";

// Utils -> Data
import Column from "antd/lib/table/Column";
import { ROLES } from "../../utils/roles";

// styles
import "./company-view.css";

/* eslint-disable react/prefer-stateless-function */

class CompanyView extends React.Component {

  state = {
    columnHeadings: ['Name', 'Address', 'City', 'Zip', 'State', 'Country', 'Actions'],
    columnKeys: ['name', 'address', 'city', 'postal_code', 'state', 'country'],
  };

  handleKeyUp = (e, key) => {
    if (e.keyCode === 13) {
      this.props.searchHandler(e.target.value, key, localStorage.userId);
    }
    if (e.target.value.length === 0) {
      this.props.searchHandler(e.target.value, key, localStorage.userId);
    }
  };

  render() {
    const { currentUser } = this.props;

    return (
      <Row id="CompanyView">
        <Row>
          <Col lg={12}>
            <h2>Companies</h2>
          </Col>
          {currentUser && currentUser.role === ROLES.SUPER_ADMIN && (
            <Col lg={12}>
              <Tooltip placement="bottom" title={"Add Company"}>
                <Button
                  className="add-company-button"
                  onClick={() => this.props.history.push('/companies/add-company')}
                >
                  <Icon type="plus-circle" />
                </Button>
              </Tooltip>
            </Col>
          )}
        </Row>
        <Divider />
        <Row>
          <Col lg={8} md={20} sm={16} xs={24}>
            <Input
              className="gateway-input"
              addonBefore={'Name'}
              onKeyUp={(e) => this.handleKeyUp(e, 'name')}
            />
          </Col>
        </Row>
        <Row className="companies-table">
          {this.props.companies && this.props.companies.length === 0 ? (
            <EmptyData />
          ) : (
              <Row>
                <Table responsive>
                  <thead>
                    <tr>
                      {_.map(this.state.columnHeadings, (heading, i) => (
                        <th key={i}>{heading}</th>
                      ))
                      }
                    </tr>
                  </thead>
                  {this.props.companies &&
                    <tbody>
                      {_.map(this.props.companies, (record) => (
                        <tr key={record.id}>
                          {
                            this.state.columnKeys.map((key, i) =>
                              <td key={i}> {record[key]}  </td>
                            )
                          }
                          <td>
                            <span>
                              <Tooltip placement="bottom" title={"Edit Company"}>
                                <Icon
                                  type="edit"
                                  onClick={() => this.props.editCompany(record.id)}
                                />
                              </Tooltip>
                              <Divider type="vertical" />
                              <Tooltip placement="bottom" title={"Delete Company"}>
                                <Popconfirm
                                  title="Are you sure delete this company?"
                                  onConfirm={() => this.props.deleteCompany(record.id)}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <Icon
                                    type="delete"
                                  />
                                </Popconfirm>
                              </Tooltip>
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  }
                </Table>
              </Row>
            )}
        </Row>
      </Row>
    );
  }
}

CompanyView.propTypes = {};

export default CompanyView;
