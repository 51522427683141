/*
 *
 * deliveryTask actionTypes
 *
 */

export const CREATE_DELIVERY_TASK_ACTION = "CREATE_DELIVERY_TASK_ACTION";
export const GET_DELIVERY_BOXES_ACTION = "GET_DELIVERY_BOXES_ACTION";
export const GET_DELIVERY_TASKS_ACTION = "GET_DELIVERY_TASKS_ACTION";
export const DELETE_DELIVERY_BOX_ACTION = "DELETE_DELIVERY_BOX_ACTION";
export const DELETE_DELIVERY_TASK_ACTION = "DELETE_DELIVERY_TASK_ACTION";
export const EDIT_DELIVERY_TASK_ACTION = "EDIT_DELIVERY_TASK_ACTION";
export const UPDATE_DELIVERY_TASK_ACTION = "UPDATE_DELIVERY_TASK_ACTION";