/**
 *
 * Location container class
 *
 */

// Packages
import React from "react";
import { connect } from "react-redux";

// Components
import LocationView from "../../components/LocationView";

// HOCS
import withUserHOC from "../../hocs/withUserHOC";
import { message } from "antd";

// Actions
import { getLocations, deleteLocation} from "../../ducks/location/action";

/* eslint-disable react/prefer-stateless-function */
class Location extends React.Component {

  state = {
    pageNo: 1,
  };

  async componentDidMount() {
    try {
      const { client, getLocations } = this.props;
      await getLocations(client, { userId: parseInt(localStorage.userId), pageNo: 1 });
    } catch(err) {
      message.error(err.message);
    }
  };

  reduxPageHandler = async (pageNo) => { 
    try {
      this.setState({ pageNo: pageNo });
      await this.props.getLocations(this.props.client, { userId: localStorage.userId, pageNo });
    } catch(err) {
      message.error(err);
    }
  };

  deleteLocationHandler = async (locationId) => {
    try {
      const { client, deleteLocation } = this.props;
      await deleteLocation(client, locationId, this.state.pageNo);
    } catch (err) {
      message.error(err.message);
    }
  };

  editLocationHandler = async (locationId) => {
    this.props.history.push(`/locations/${locationId}/edit`) 
  };

  render() {
    return(
      <LocationView 
        {...this.props}
        reduxPageHandler={this.reduxPageHandler.bind(this)}
        deleteLocationHandler={this.deleteLocationHandler.bind(this)}
        editLocationHandler={this.editLocationHandler.bind(this)}
      />
    );
  }
}

Location.propTypes = {};

const mapStateToProps = ({ location }) => ({
  getLocationsResponse: location.getLocations,
});

const mapDispatchToProps = dispatch => ({
  getLocations: (client, data) => dispatch(getLocations(client, data)),
  deleteLocation: (client, locationId, pageNo) =>
   dispatch(deleteLocation(client, locationId, pageNo)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withUserHOC(withConnect(Location));
