import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import routes from './routes';

import './App.css';

class App extends Component {
  render() {
    return (
      <Router key={1} basename={new URL(process.env.PUBLIC_URL).pathname}>
        <Switch>
          {routes.map((route, i) => (
            <Route
              key={i}
              path={route.path}
              render={props => <AppRoute route={route} props={props} />}
              exact={route.exact}
            />
          ))}
        </Switch>
      </Router>
    );
  }
}

export default App;

const AppRoute = ({ route, props }) => {
  const L = route.layout;
  const C = route.component;

  if (L) {
    return <L {...props}><C {...props} /></L>;
  } else {
    return <C {...props} />;
  }
};
