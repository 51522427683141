/**
 *
 * Products container class
 *
 */

// Packages
import React from "react";
import { message } from "antd";
import { connect } from "react-redux";

// Components
import ProductView from "../../components/ProductView";

// HOCS
import withUserHOC from "../../hocs/withUserHOC";

// Actions
import { getProducts } from "../../ducks/product/action";

class Products extends React.Component {

  async componentDidMount() {
    try {
      const { client, getProducts } = this.props;
      // await getProducts(client);
    } catch (err) {
      message.error(err.message);
    }
  };

  render() {
    return (
      <ProductView />
    );
  }
}

Products.propTypes = {};

const mapStateToProps = ({ }) => ({});

const mapDispatchToProps = dispatch => ({
  // getProducts: (client) => dispatch(getProducts(client)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withUserHOC(withConnect(Products));
