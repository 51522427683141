import gql from "graphql-tag";

const firm = `
  id
  name
`;

const customer = `
  id
  name
  email
  monitoring_type
  min_temp
  max_temp
  company_id
  company {
    ${firm}
  }
`;

export const GET_CUSTOMERS = gql`
  query getCustomers($userId: Int) {
    getCustomers(userId: $userId) {
      ${customer}
    }
  } 
`;

export const DELETE_CUSTOMER = gql`
  mutation deleteCustomer(
    $customerId: Int!
    $currentUserId: Int
  ) {
    deleteCustomer(
      customerId: $customerId
      currentUserId: $currentUserId
    ) {
      ${customer}
    } 
  }
`;

export const GET_SPECIFIC_CUSTOMER = gql`
  query getSpecificCustomer(
    $customerId: Int!
  ) {
    getSpecificCustomer(
        customerId: $customerId
    ) {
      success
      message
      customer {
        ${customer}
      } 
    }
  }
`;

export const CREATE_OR_UPDATE_CUSTOMER = gql`
  mutation createOrUpdateCustomer(
    $customerId: Int
    $name: String!
    $email: String!
    $companyId: Int!
    $monitoringType: String!
    $minTemp: Float!
    $maxTemp: Float!
  ) {
    createOrUpdateCustomer(
      customerId: $customerId
      name: $name
      email: $email
      companyId: $companyId
      monitoringType: $monitoringType
      minTemp: $minTemp
      maxTemp: $maxTemp
    ) {
      success
      message
    }
  }
`;
