/*
 *
 * customer actions
 *
 */
// Action Types
import {
  GET_CUSTOMERS_ACTION,
  DELETE_CUSTOMER_ACTION,
  GET_SPECIFIC_CUSTOMER_ACTION,
  CREATE_OR_UPDATE_CUSTOMER_ACTION
} from "./actionTypes";

// Queries
import {
  GET_CUSTOMERS,
  DELETE_CUSTOMER,
  GET_SPECIFIC_CUSTOMER,
  CREATE_OR_UPDATE_CUSTOMER,
} from "../../queries/customer";

export const getCustomers = (client, userId) => ({
  type: GET_CUSTOMERS_ACTION,
  async payload() {
    try {
      const result = await client.query({
        query: GET_CUSTOMERS,
        variables: {
          userId,
        },
        fetchPolicy: "no-cache",
      });

      return result.data.getCustomers;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const deleteCustomer = (client, data) => ({
  type: DELETE_CUSTOMER_ACTION,
  async payload() {
    try {
      const result = await client.mutate({
        mutation: DELETE_CUSTOMER,
        variables: data,
        fetchPolicy: "no-cache",
      });

      return result.data.deleteCustomer;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const getSpecificCustomer = (client, customerId) => ({
  type: GET_SPECIFIC_CUSTOMER_ACTION,
  async payload() {
    try {
      const result = await client.query({
        query: GET_SPECIFIC_CUSTOMER,
        variables: {
          customerId,
        },
        fetchPolicy: "no-cache",
      });

      return result.data.getSpecificCustomer;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});

export const createOrUpdateCustomer = (client, data) => ({
  type: CREATE_OR_UPDATE_CUSTOMER_ACTION,
  async payload() {
    try {
      const result = await client.mutate({
        mutation: CREATE_OR_UPDATE_CUSTOMER,
        variables: data,
        fetchPolicy: "no-cache",
      });

      return result.data.createOrUpdateCustomer;

    } catch (err) {
      return Promise.reject(err);
    }
  }
});
